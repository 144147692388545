import {
  LIST_ANSWERS,
  LIST_ANSWERS_SUCCESS,
  LIST_ANSWERS_CLEAN,
  LIST_ANSWERS_ERROR,
} from "./actionTypes"

export const listAnswers = idQuestion => ({
  type: LIST_ANSWERS,
  payload: { idQuestion },
})

export const listAnswersSuccess = answers => {
  return {
    type: LIST_ANSWERS_SUCCESS,
    payload: { answers },
  }
}

export const listAnswersClean = () => {
  return {
    type: LIST_ANSWERS_CLEAN,
    payload: {},
  }
}

export const apiError = error => {
  return {
    type: LIST_ANSWERS_ERROR,
    payload: error,
  }
}
