import {
  GET_COURSE,
  GET_COURSE_SUCCESS,
  GET_COURSE_CLEAN,
  API_ERROR,
} from "./actionTypes"

export const getCourse = id => {
  return {
    type: GET_COURSE,
    payload: { id },
  }
}

export const getCourseSuccess = course => {
  return {
    type: GET_COURSE_SUCCESS,
    payload: { course },
  }
}

export const getCourseClean = () => {
  return {
    type: GET_COURSE_CLEAN,
    payload: {},
  }
}

export const apiError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}
