import React, { Component, Fragment, useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import MetaTags from "react-meta-tags"
import { Container, Row, Col, Card, CardBody } from "reactstrap"

import Breadcrumbs from "../../components/Common/Breadcrumb2"

import UsersListTable from "components/Users/UsersListTable"

const UsersList = props => {
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Users | CELF</title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col sm={4}>
              <h4>Gestión de usuarios</h4>
            </Col>
            <Col sm={8}>
              <Breadcrumbs
                breadcrumbItems={[
                  { key: "dashboard", title: "Dashboard", link: "/dashboard" },
                  { key: "users", title: "Gestión de usuarios" },
                ]}
              />
            </Col>
          </Row>

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <UsersListTable />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default UsersList
