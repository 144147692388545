import React, { useState, Fragment, useEffect } from "react"
import PropTypes from "prop-types"
import {
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import { Link } from "react-router-dom"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit"
import avatar1 from "../../assets/images/user_without_pic.png"
import moment from "moment"
import SweetAlert from "react-bootstrap-sweetalert"
import { getUsers, apiError } from "../../store/actions"
import {
  userForgetPassword,
  userForgetPasswordClean,
  changePassword,
  changePasswordClean,
  activateDeactivate,
  activateDeactivateClean,
} from "../../store/actions"
import "../../assets/scss/datatables.scss"
import RestorePasswordModal from "./RestorePasswordModal"
import { useDispatch, useSelector } from "react-redux"
import ActivationDeactivationModal from "./ActivationDeactivationModal"

const UsersListTable = props => {
  const dispatch = useDispatch()
  const { SearchBar } = Search

  const [dropDown, setDropDown] = useState(0)
  const [user, setUser] = useState({
    idUsuario: 0,
    email: "",
  })
  const [forgetPasswordSent, setForgetPasswordSent] = useState(false)
  const [changePasswordSent, setChangePasswordSent] = useState(false)
  const [activationType, setActivationType] = useState(undefined)
  const [page, setPage] = useState(undefined)
  const [sizePerPage, setSizePerPage] = useState(undefined)
  const [passwordView, setPasswordView] = useState(false)
  const [activationDeactivationUser, setActivationDeactivationUser] =
    useState(0)

  const {
    error,
    loading,
    done,
    users,
    forgetSuccess,
    forgetError,
    changePasswordError,
    changePasswordLoading,
    changePasswordSuccess,
    activateDeactivateLoading,
    activateDeactivateSuccess,
  } = useSelector(state => ({
    forgetSuccess: state.ForgetPassword.forgetSuccessMsg,
    forgetError: state.ForgetPassword.forgetError,
    changePasswordError: state.UsersList.changePasswordError,
    changePasswordLoading: state.UsersList.changePasswordLoading,
    changePasswordSuccess: state.UsersList.changePasswordSuccess,
    activateDeactivateLoading: state.UserEdit.activateDeactivateLoading,
    activateDeactivateSuccess: state.UserEdit.activateDeactivateSuccess,
    error: state.UsersList.error,
    loading: state.UsersList.loading,
    users: state.UsersList.users,
    done: state.UsersList.done,
  }))

  useEffect(() => {
    dispatch(getUsers())
  }, [])

  if (forgetSuccess) {
    setForgetPasswordSent(true)
    dispatch(userForgetPasswordClean())
  }

  if (changePasswordSuccess) {
    setChangePasswordSent(true)
    dispatch(changePasswordClean())
  }

  if (activateDeactivateSuccess && activationDeactivationUser !== 0) {
    setActivationDeactivationUser(0)
    dispatch(getUsers())
  }

  const getPassword = () => {
    return passwordView ? user.password : "************"
  }

  const [modalPasswordResetBackdrop, setModalPasswordResetBackdrop] =
    useState(false)

  const [
    modalActivationDeactivationBackdrop,
    setModalActivationDeactivationBackdrop,
  ] = useState(false)

  const dropDownActions = (cell, row, rowIndex, extraData) => {
    const active = extraData?.active ?? 0
    return (
      <Dropdown
        key={`dropdown-${row.idUsuario}`}
        isOpen={row.idUsuario === active}
        toggle={() => {
          setDropDown(row.idUsuario === active ? 0 : row.idUsuario)
        }}
      >
        <DropdownToggle color="primary" className="btn btn-primary btn-md">
          Acciones <i className="mdi mdi-chevron-down" />
        </DropdownToggle>
        <DropdownMenu className="white-celf-background">
          <DropdownItem onClick={() => resetPassword(row)}>
            Resetear contraseña
          </DropdownItem>
          <DropdownItem>
            <Link to={`/users/edit/${row.idUsuario}`}>Editar info</Link>
          </DropdownItem>
          <DropdownItem>Resetear cursos</DropdownItem>
          <DropdownItem
            onClick={() => {
              setActivationType(!row.idStatus)
              setActivationDeactivationUser(row.idUsuario)
              setModalActivationDeactivationBackdrop(true)
            }}
          >
            {row.idStatus ? "Desactivar" : "Activar"}
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    )
  }

  const columns = [
    {
      dataField: "idUsuario",
      text: "Id",
      sort: false,
      hidden: true,
    },
    {
      dataField: "image",
      text: "",
      sort: false,
      csvExport: false,
      // eslint-disable-next-line react/display-name
      formatter: (column, row) => {
        return (
          <img
            key={`image-${row.idUsuario}`}
            src={avatar1}
            alt="Avatar"
            className="img-responsive rounded-circle"
            width={50}
          />
        )
      },
    },
    {
      dataField: "nombre",
      text: "Nombre completo y correo",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (column, row) => {
        return (
          <div key={`nombre-${row.idUsuario}`}>
            <strong>
              {row.nombre} {row.apellido}
            </strong>
            <br />
            <span>{row.email}</span>
          </div>
        )
      },
      csvFormatter: (column, row) => `${row.nombre} ${row.apellido}`,
    },
    {
      dataField: "apellido",
      text: "Apellido",
      sort: true,
      csvExport: false,
      hidden: true,
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
      csvExport: true,
      hidden: true,
    },
    {
      dataField: "groupName",
      text: "Grupo",
      sort: true,
      CSVExport: false,
      // eslint-disable-next-line react/display-name
      formatter: (column, row) => {
        return row.idGroup === 0 ? (
          <span>Ninguno</span>
        ) : (
          <span>{row.groupName}</span>
        )
      },
      csvFormatter: (column, row) =>
        row.idGroup === 0 ? "Ninguno" : row.groupName,
    },
    {
      dataField: "lastActivity",
      text: "Último ingreso",
      csvText: "Último ingreso",
      sort: true,
      align: "center",
      CSVExport: false,
      // eslint-disable-next-line react/display-name
      formatter: (column, row) => {
        return row.aceptotyc !== null && row.aceptoPrivacidadDatos !== null ? (
          <span key={`time-${row.idUsuario}`}>
            {moment(row.lastActivity).format(
              "D [de] MMM [de] YYYY [a las] HH:mm [hrs.]"
            )}
          </span>
        ) : (
          <span>-</span>
        )
      },
      csvFormatter: (column, row) =>
        moment(row.lastActivity).format("D/M/YYYY HH:mm [hrs.]"),
    },
    {
      dataField: "idRol",
      text: "Perfil",
      sort: true,
      CSVExport: false,
      // eslint-disable-next-line react/display-name
      formatter: (column, row) => {
        return <span>N{row.idRol}</span>
      },
      csvFormatter: (column, row) => `N${row.idRol}`,
    },
    {
      dataField: "idStatus",
      text: "Estatus",
      sort: true,
      align: "center",
      CSVExport: false,
      // eslint-disable-next-line react/display-name
      formatter: (cell, row, rowIndex, extraData) => {
        return (
          <h4 style={{ marginBottom: 0 }} key={`status-${row.idUsuario}`}>
            <span
              className={`pt-2 badge bg-${
                row.idStatus ? "success" : "warning"
              }`}
            >
              {row.idStatus ? "Activo" : "Inactivo"}
            </span>
          </h4>
        )
      },
      csvFormatter: (column, row) => (row.idStatus ? "Activo" : "Inactivo"),
    },
    {
      dataField: "actions",
      text: "Acciones",
      sort: false,
      csvExport: false,
      formatter: dropDownActions,
      formatExtraData: {
        active: dropDown,
      },
    },
  ]

  const pageOptions = {
    sizePerPage: sizePerPage === undefined ? 10 : sizePerPage,
    totalSize: users?.length,
    custom: true,
    showTotal: true,
    page: page === undefined ? 1 : page,
    onPageChange: page => {
      setPage(page)
    },
    onSizePerPageChange: sizePerPage => {
      console.log(sizePerPage)
      setSizePerPage(sizePerPage)
    },
  }

  const { ExportCSVButton } = CSVExport

  const defaultSorted = [{ dataField: "idRol", order: "asc" }]

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
    clickToSelect: false,
  }

  const resetPassword = user => {
    setModalPasswordResetBackdrop(true)
    setUser(user)
    removeBodyCss()
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  return loading && users === undefined ? (
    <div className="mt-3 mb-3">
      <div className="spinner-chase">
        <div className="chase-dot" />
        <div className="chase-dot" />
        <div className="chase-dot" />
        <div className="chase-dot" />
        <div className="chase-dot" />
        <div className="chase-dot" />
      </div>
    </div>
  ) : (
    <PaginationProvider
      pagination={paginationFactory(pageOptions)}
      keyField="idUsuario"
      columns={columns}
      data={users}
    >
      {({ paginationProps, paginationTableProps }) => (
        <ToolkitProvider
          keyField="idUsuario"
          columns={columns}
          data={users}
          search
          exportCSV={{
            fileName: "Usuarios.csv",
            noAutoBOM: true,
            blobType: "text/plain;charset=iso-8859-2",
          }}
        >
          {toolkitProps => (
            <React.Fragment>
              <Row className="mb-2 pt-3">
                <Col lg={2} md={3} sm={4} xs={4}>
                  <div className="d-grid">
                    <Link
                      to="/users/add"
                      className="btn btn-primary btn-rounded"
                    >
                      <i className="fa fa-plus"></i> Agregar usuario
                    </Link>
                  </div>
                </Col>
                <Col lg={8} md={6} sm={4} xs={4} className="text-center"></Col>
                <Col lg={{ size: 2 }} md={{ size: 3 }} sm={{ size: 4 }} xs={4}>
                  <div className="search-box me-2 mb-2 d-inline-block">
                    <div className="position-relative">
                      <SearchBar
                        {...toolkitProps.searchProps}
                        placeholder="Buscar"
                      />
                      <i className="bx bx-search-alt search-icon" />
                    </div>
                    <ExportCSVButton
                      className="btn btn-link btn-rounded"
                      {...toolkitProps.csvProps}
                    >
                      <i className="fa fa-file-excel"></i> Exportar tabla
                    </ExportCSVButton>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col xl="12">
                  <Fragment>
                    <BootstrapTable
                      keyField={"idUsuario"}
                      bordered={false}
                      striped={true}
                      pagination={{ page: 2 }}
                      defaultSorted={defaultSorted}
                      classes={"table align-middle table-nowrap"}
                      headerWrapperClasses={"thead-light"}
                      {...toolkitProps.baseProps}
                      {...paginationTableProps}
                    />
                    {users?.length <= 0 && (
                      <Row>
                        <Col md={12} className="text-center">
                          -- No hay registros --
                        </Col>
                      </Row>
                    )}
                  </Fragment>
                </Col>
                <RestorePasswordModal
                  show={modalPasswordResetBackdrop}
                  error={
                    changePasswordError != ""
                      ? changePasswordError
                      : forgetError
                  }
                  onCancel={() => {
                    setModalPasswordResetBackdrop(false)
                    dispatch(userForgetPasswordClean())
                  }}
                  onConfirmWithLink={() => {
                    dispatch(userForgetPassword(user.email))
                  }}
                  onConfirmWithPassword={password => {
                    setUser({
                      ...user,
                      password: password,
                    })
                    dispatch(changePassword(user.idUsuario, password))
                  }}
                />
                <ActivationDeactivationModal
                  onCancel={() => setModalActivationDeactivationBackdrop(false)}
                  onConfirm={() => {
                    dispatch(activateDeactivate(activationDeactivationUser))
                    setModalActivationDeactivationBackdrop(false)
                  }}
                  action={activationType}
                  show={modalActivationDeactivationBackdrop}
                />
              </Row>
              {activateDeactivateSuccess && (
                <SweetAlert
                  title={`Usuario ${
                    activationType ? "activado" : "inactivado"
                  }`}
                  success
                  confirmBtnBsStyle="success"
                  onConfirm={() => {
                    dispatch(activateDeactivateClean())
                    setActivationType(undefined)
                  }}
                >
                  Se le ha enviado un correo al usuario indicándole que ha sido{" "}
                  {activationType ? "activado" : "inactivado"} en la plataforma.
                </SweetAlert>
              )}
              {forgetPasswordSent && (
                <SweetAlert
                  title="Correo enviado"
                  success
                  confirmBtnBsStyle="success"
                  onConfirm={() => {
                    setModalPasswordResetBackdrop(false)
                    setForgetPasswordSent(false)
                    setUser({})
                  }}
                >
                  Enviamos a {user.nombre} un correo con las instrucciones para
                  restaurar su contraseña.
                </SweetAlert>
              )}
              {changePasswordSent && (
                <SweetAlert
                  title="Contraseña modificada"
                  success
                  confirmBtnBsStyle="success"
                  onConfirm={() => {
                    setModalPasswordResetBackdrop(false)
                    setChangePasswordSent(false)
                    setUser({})
                  }}
                >
                  <p>
                    La contraseña de {user.nombre} ha sido modificada
                    exitosamente.
                  </p>
                  {/*<p>
                    Contraseña:
                    {getPassword()}
                    <button
                      className="btn btn-link"
                      onClick={setPasswordView(!passwordView)}
                    >
                      <i
                        className={`fa fa-${
                          passwordView ? "eye-show" : "eye-hide"
                        }`}
                      ></i>
                    </button>
                      </p>*/}
                </SweetAlert>
              )}

              <Row className="align-items-md-center mt-30">
                <Col className="inner-custom-pagination d-flex">
                  <div className="d-inline">
                    <SizePerPageDropdownStandalone {...paginationProps} />
                  </div>
                  <div className="text-md-right ms-auto">
                    <PaginationListStandalone {...paginationProps} />
                  </div>
                </Col>
              </Row>
            </React.Fragment>
          )}
        </ToolkitProvider>
      )}
    </PaginationProvider>
  )
}

UsersListTable.propTypes = {
  users: PropTypes.array,
  onUpdateStatus: PropTypes.func,
}

export default UsersListTable
