import { useState, useEffect } from "react"
import { createHash } from "crypto"

import { PropTypes } from "prop-types"
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Table,
} from "reactstrap"
import { useSelector } from "react-redux"
import SessionLogo from "components/Common/SessionLogo"

const ModalCertificate = props => {
  const [previousCoursesSelectedMulti, setPreviousCoursesSelectedMulti] =
    useState([])
  const [categoriesSelectedMulti, setCategoriesSelectedMulti] = useState([])

  const {
    course,
    knowledgeCategories,
    skills,
    levels,
    users,
    courseKnowledges,
    lessons,
    user,
    courses,
  } = useSelector(store => ({
    user: store.Profile.user,
    course: store.CourseGet.course,
    courses: store.CoursesList.courses,
    knowledgeCategories: store.KnowledgeCategoriesList.knowledgeCategories,
    skills: store.SkillsList.skills,
    levels: store.LevelsList.levels,
    courseKnowledges: store.CourseKnowledgesList.courseKnowledges,
    users: store.UsersList.users,
    lessons: store.LessonsList.lessons,
  }))

  useEffect(() => {
    const knowCat = course?.knowledgeCategories
    const prevCourses = course?.PreviousCourses

    let prevCoursesArray = []
    let knowCatArray = []

    if (prevCourses !== undefined)
      prevCourses.forEach(prevCourse => {
        prevCoursesArray.push({
          label: `${prevCourse.courseName}`,
          value: prevCourse.idPreviousCourse,
        })
      })

    if (knowCat !== undefined)
      knowCat.forEach(knowC => {
        knowCatArray.push({
          label: `${knowC.knowledgeCategory}`,
          value: knowC.idKnowledgeCategory,
        })
      })

    setPreviousCoursesSelectedMulti(prevCoursesArray)
    setCategoriesSelectedMulti(knowCatArray)
    return () => {}
  }, [props.course])

  const generateHash = key => {
    var hash = 0,
      i,
      chr
    if (key.length === 0) return hash
    for (i = 0; i < key.length; i++) {
      chr = key.charCodeAt(i)
      hash = (hash << 5) - hash + chr
      hash |= 0 // Convert to 32bit integer
    }
    return hash
  }

  const level = levels.find(level => level.idLevel === course.nivelCurso)?.level

  return (
    <Modal isOpen={props.open} backdrop={"static"} size="xl">
      <ModalBody>
        <Row>
          <Col className="text-center mb-3">
            <Row>
              <Col lg={3} md={2} sm={0}></Col>
              <Col lg={6} md={8} sm={12}>
                <SessionLogo />
              </Col>
              <Col lg={3} md={2} sm={0}></Col>
            </Row>
            <h3 className="main-celf-color">Constancia a</h3>
            <h1>
              {props.user.nombre} {props.user.apellido}
            </h1>
            <h4>Por haber aprobado el curso de</h4>
          </Col>
          <div
            className="alert alert-dark text-center mt-2 mb-2 pt-3"
            style={{ backgroundColor: "#000" }}
          >
            <div style={{ color: "#ffffff", fontSize: "20px" }}>
              {props.course.nombreCurso}
            </div>
          </div>
        </Row>
        <Row className="mt-4">
          <Col>
            <Table className="table table-striped">
              <tbody>
                <tr>
                  <td align="right">Categorías</td>
                  <td>
                    {categoriesSelectedMulti === undefined
                      ? ""
                      : categoriesSelectedMulti
                          .map(category => category.label.trim())
                          .join(", ")}
                  </td>
                </tr>
                <tr>
                  <td align="right">Clave curso</td>
                  <td>{props.course?.claveCurso}</td>
                </tr>
                <tr>
                  <td align="right">Curso previo</td>
                  <td>
                    {previousCoursesSelectedMulti === undefined
                      ? ""
                      : previousCoursesSelectedMulti.length <= 0
                      ? "Ninguno"
                      : previousCoursesSelectedMulti
                          .map(category => category.label.trim())
                          .join(", ")}
                  </td>
                </tr>
                <tr>
                  <td align="right">Tiempo estimado</td>
                  <td>{props.course?.tiempoDedicacion} minutos</td>
                </tr>
                <tr>
                  <td align="right">Nivel</td>
                  <td>{level}</td>
                </tr>
                <tr>
                  <td align="right">Mínimo aprobatorio</td>
                  <td>{props.course?.minimoAprovatorio}</td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col sm={12} className="pb-2 text-center">
            <strong>Sello digital:</strong>
          </Col>
          <hr />
          <Col sm={12} className="text-center">
            {createHash("sha256")
              .update(
                `||${props.exam?.idExam ?? 12345}|${props.course.nombreCurso}||`
              )
              .digest("hex")}
            -
            {createHash("sha256")
              .update(`||${props.user.nombre}|${props.user.apellido}||`)
              .digest("hex")}
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <div className="d-flex justify-content-end">
          <button className="btn btn-light" onClick={props.onClose}>
            Cerrar
          </button>
        </div>
      </ModalFooter>
    </Modal>
  )
}

ModalCertificate.propTypes = {
  course: PropTypes.object,
  exam: PropTypes.object,
  open: PropTypes.bool,
  user: PropTypes.object,
  onClose: PropTypes.func,
}

export default ModalCertificate
