import {
  REMOVE_QUESTION,
  REMOVE_QUESTION_CLEAN,
  REMOVE_QUESTION_SUCCESS,
  REMOVE_QUESTION_ERROR,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  done: false,
  question: undefined,
}

const QuestionRemove = (state = initialState, action) => {
  switch (action.type) {
    case REMOVE_QUESTION:
      state = {
        ...state,
        loading: true,
      }
      break
    case REMOVE_QUESTION_SUCCESS:
      state = {
        ...state,
        loading: false,
        done: true,
        question: action.payload.question,
      }
      break
    case REMOVE_QUESTION_CLEAN:
      state = initialState
      break
    case REMOVE_QUESTION_ERROR:
      state = {
        ...state,
        error: action.payload,
        loading: false,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default QuestionRemove
