/* eslint-disable no-undef */
import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { GET_POSITIONS } from "./actionTypes"
import { apiError, getPositionsSuccess } from "./actions"

//Include Both Helper File with needed methods
import { getPositions } from "../../../helpers/backend_helper"

function* positions() {
  try {
    const response = yield call(getPositions)
    yield put(getPositionsSuccess(response))
  } catch (error) {
    yield put(apiError(error))
  }
}

function* positionsListSaga() {
  yield takeEvery(GET_POSITIONS, positions)
}

export default positionsListSaga
