import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { Row, Col, Collapse } from "reactstrap"
import { Link, withRouter } from "react-router-dom"
import classname from "classnames"

//i18n
import { withTranslation } from "react-i18next"

import { connect, useSelector } from "react-redux"

const Navbar = props => {
  const [dashboard, setdashboard] = useState(false)
  const [courses, setcourses] = useState(false)
  const [groups, setgroups] = useState(false)
  const [utility, setutility] = useState(false)
  const [analytics, setanalytics] = useState(false)

  const { whoIAm } = useSelector(state => ({
    whoIAm: state.Profile.user,
  }))

  const idRol = whoIAm?.idRol ?? 0

  useEffect(() => {
    var matchingMenuItem = null
    var ul = document.getElementById("navigation")
    var items = ul !== null ? ul.getElementsByTagName("a") : []
    for (var i = 0; i < items.length; ++i) {
      if (props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem)
    }
  }, [])

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    if (parent) {
      parent.classList.add("active") // li
      const parent2 = parent.parentElement
      parent2.classList.add("active") // li
      const parent3 = parent2.parentElement
      if (parent3) {
        parent3.classList.add("active") // li
        const parent4 = parent3.parentElement
        if (parent4) {
          parent4.classList.add("active") // li
          const parent5 = parent4.parentElement
          if (parent5) {
            parent5.classList.add("active") // li
            const parent6 = parent5.parentElement
            if (parent6) {
              parent6.classList.add("active") // li
            }
          }
        }
      }
    }
    return false
  }

  return (
    <React.Fragment>
      <div className="topnav main-celf-background">
        <div className="container-fluid">
          {idRol !== 0 && (
            <nav
              className="navbar navbar-light navbar-expand-lg topnav-menu"
              id="navigation"
            >
              {idRol === 8 ? (
                <Collapse
                  isOpen={props.leftMenu}
                  className="navbar-collapse"
                  id="topnav-menu-content"
                >
                  <ul className="navbar-nav">
                    <li className="nav-item dropdown">
                      <Link
                        className="nav-link dropdown-toggle arrow-none"
                        onClick={e => {
                          setdashboard(!dashboard)
                        }}
                        to="/dashboard"
                      >
                        <i className="fa fa-home me-2"></i>
                        {props.t("Dashboard")}
                      </Link>
                    </li>
                    {/* <li className="nav-item dropdown">
                      <Link
                        className="nav-link dropdown-toggle arrow-none"
                        onClick={e => {
                          setcourses(!courses)
                        }}
                        to="/my-courses"
                      >
                        <i className="fa fa-book"></i> Cursos
                      </Link>
                    </li> */}
                    <li className="nav-item dropdown">
                      <Link
                        className="nav-link dropdown-toggle arrow-none"
                        onClick={e => {
                          setcourses(!courses)
                        }}
                        to="#"
                      >
                        <i className="fa fa-question"></i> Centro de ayuda
                      </Link>
                    </li>
                    <li className="nav-item dropdown nav-social nav-social-1">
                      <a className="nav-link dropdown-toggle arrow-none">
                        <i className="fab fa-facebook"></i>
                      </a>
                    </li>
                    <li className="nav-item dropdown nav-social nav-social-2">
                      <a className="nav-link dropdown-toggle arrow-none">
                        <i className="fab fa-linkedin"></i>
                      </a>
                    </li>
                    <li className="nav-item dropdown nav-social nav-social-3">
                      <a className="nav-link dropdown-toggle arrow-none">
                        <i className="fab fa-youtube"></i>
                      </a>
                    </li>
                  </ul>
                </Collapse>
              ) : (
                <Collapse
                  isOpen={props.leftMenu}
                  className="navbar-collapse"
                  id="topnav-menu-content"
                >
                  <ul className="navbar-nav">
                    <li className="nav-item dropdown">
                      <Link
                        className="nav-link dropdown-toggle arrow-none"
                        onClick={e => {
                          setdashboard(!dashboard)
                        }}
                        to="/dashboard"
                      >
                        <i className="fa fa-home me-2"></i>
                        {props.t("Dashboard")}
                      </Link>
                    </li>
                    <li className="nav-item dropdown">
                      <Link
                        className="nav-link dropdown-toggle arrow-none"
                        onClick={e => {
                          setcourses(!courses)
                        }}
                        to="/courses"
                      >
                        <i className="fa fa-book me-2"></i>
                        Cursos
                      </Link>
                    </li>
                    <li className="nav-item dropdown">
                      <Link
                        className="nav-link dropdown-toggle arrow-none"
                        onClick={e => {
                          setgroups(!groups)
                        }}
                        to="/groups"
                      >
                        <i className="fa fa-vector-square me-2"></i>
                        Grupos
                      </Link>
                    </li>
                    {/* <li className="nav-item dropdown">
                      <Link
                        className="nav-link dropdown-toggle arrow-none"
                        onClick={e => {
                          setanalytics(!analytics)
                        }}
                        to="/analytics"
                      >
                        <i className="fa fa-chart-line me-2"></i>
                        Analítica
                      </Link>
                    </li> */}
                    <li className="nav-item dropdown">
                      <Link
                        className="nav-link dropdown-toggle arrow-none"
                        to="#"
                        onClick={e => {
                          setutility(!utility)
                        }}
                      >
                        <i className="fa fa-cogs me-2"></i>
                        Configurar <div className="arrow-down"></div>
                      </Link>
                      <div
                        className={classname("dropdown-menu", {
                          show: utility,
                        })}
                      >
                        <Link to="/users" className="dropdown-item">
                          <i className="fa fa-users"></i> Usuarios
                        </Link>
                        <Link to="/catalog" className="dropdown-item">
                          <i className="fa fa-th-list"></i> Catálogos
                        </Link>
                        <Link to="#" className="dropdown-item">
                          <i className="fa fa-question"></i> Centro de ayuda
                        </Link>
                        <Link to="#" className="dropdown-item">
                          <i className="fa fa-clipboard-list"></i> Bitácora
                        </Link>
                      </div>
                    </li>
                    <li className="nav-item dropdown nav-social nav-social-1">
                      <a
                        className="nav-link dropdown-toggle arrow-none"
                        href="https://www.facebook.com/Fester-M%C3%A9xico-112708833734290/"
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        <i className="fab fa-facebook"></i>
                      </a>
                    </li>
                    <li className="nav-item dropdown nav-social nav-social-2">
                      <a
                        className="nav-link dropdown-toggle arrow-none"
                        href="https://www.linkedin.com/company/fester-mexico/"
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        <i className="fab fa-linkedin"></i>
                      </a>
                    </li>
                    <li className="nav-item dropdown nav-social nav-social-3">
                      <a
                        className="nav-link dropdown-toggle arrow-none"
                        href="https://www.youtube.com/channel/UCPEl4QnEIKRBvSgEgYqftVg"
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        <i className="fab fa-youtube"></i>
                      </a>
                    </li>
                  </ul>
                </Collapse>
              )}
            </nav>
          )}
        </div>
      </div>
      <style jsx="true">{`
        .nav-social {
          position: absolute;
          right: 0;
        }
        .nav-social i {
          font-size: 20px !important;
        }
        .nav-social-1 {
          right: 0 !important;
        }
        .nav-social-2 {
          right: 36px !important;
        }
        .nav-social-3 {
          right: 72px !important;
        }
      `}</style>
    </React.Fragment>
  )
}

Navbar.propTypes = {
  leftMenu: PropTypes.any,
  location: PropTypes.any,
  menuOpen: PropTypes.any,
  t: PropTypes.any,
}

const mapStatetoProps = state => {
  const { leftMenu } = state.Layout
  return { leftMenu }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(Navbar))
)
