import PropTypes from "prop-types"
import React, { useEffect, useState, Fragment } from "react"
import MetaTags from "react-meta-tags"
import { useSelector, useDispatch } from "react-redux"
import classnames from "classnames"
import {
  Container,
  Row,
  Col,
  Card,
  CardTitle,
  CardText,
  CardImg,
  CardBody,
  Input,
  Nav,
  NavItem,
  NavLink,
  TabPane,
  TabContent,
  Progress,
  CardFooter,
} from "reactstrap"
import { Link, withRouter } from "react-router-dom"

import classNames from "classnames"

//import Charts
import StackedColumnChart from "../../pages/Dashboard/StackedColumnChart"

//import action
import { getChartsData as onGetChartsData } from "../../store/actions"

import festerImpermeabilizante from "../../assets/images/fester-impermeabilizante.jpg"
import festerConcreto from "../../assets/images/fester-concreto.jpeg"
import festerPisos from "../../assets/images/fester-pisos.jpeg"

// Pages Components
import WelcomeComp from "../../pages/Dashboard/WelcomeComp"
import MonthlyEarning from "../../pages/Dashboard/MonthlyEarning"
import SocialSource from "../../pages/Dashboard/SocialSource"
import ActivityComp from "../../pages/Dashboard/ActivityComp"
import TopCities from "../../pages/Dashboard/TopCities"
import LatestTranaction from "../../pages/Dashboard/LatestTranaction"

const N1 = props => {
  const [activeTab, setActiveTab] = useState("1")
  const dispatch = useDispatch()

  const [periodData, setPeriodData] = useState([])
  const [periodType, setPeriodType] = useState("yearly")

  const onChangeChartPeriod = pType => {
    setPeriodType(pType)
    dispatch(onGetChartsData(pType))
  }

  const toggleIconCustom = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  return (
    <Fragment>
      <Row>
        <Col xl="4">
          <WelcomeComp />
          <MonthlyEarning />
        </Col>
        <Col xl="8">
          <Row>
            {/* Reports Render 
                {reports.map((report, key) => (
                  <Col md="4" key={"_col_" + key}>
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <Media>
                          <Media body>
                            <p className="text-muted fw-medium">
                              {report.title}
                            </p>
                            <h4 className="mb-0">{report.description}</h4>
                          </Media>
                          <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                            <span className="avatar-title rounded-circle bg-primary">
                              <i
                                className={
                                  "bx " + report.iconClass + " font-size-24"
                                }
                              ></i>
                            </span>
                          </div>
                        </Media>
                      </CardBody>
                    </Card>
                  </Col>
                ))}*/}
          </Row>

          <Card>
            <CardBody>
              <div className="d-sm-flex flex-wrap">
                <h4 className="card-title mb-4">
                  Estatus de cursos por usuario
                </h4>
                <div className="ms-auto">
                  <ul className="nav nav-pills">
                    <li className="nav-item">
                      <Link
                        to="#"
                        className={classNames(
                          { active: periodType === "weekly" },
                          "nav-link"
                        )}
                        onClick={() => {
                          onChangeChartPeriod("weekly")
                        }}
                        id="one_month"
                      >
                        Semanal
                      </Link>{" "}
                    </li>
                    <li className="nav-item">
                      <Link
                        to="#"
                        className={classNames(
                          { active: periodType === "monthly" },
                          "nav-link"
                        )}
                        onClick={() => {
                          onChangeChartPeriod("monthly")
                        }}
                        id="one_month"
                      >
                        Mensual
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="#"
                        className={classNames(
                          { active: periodType === "yearly" },
                          "nav-link"
                        )}
                        onClick={() => {
                          onChangeChartPeriod("yearly")
                        }}
                        id="one_month"
                      >
                        Anual
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <Row className="mt-2 mb-2">
                <Col md={4}>
                  Por perfil:
                  <Input type="select">
                    <option value="">Todos los perfiles</option>
                    <option value="N1">N1</option>
                    <option value="N2">N2</option>
                    <option value="N3">N3</option>
                    <option value="N4">N4</option>
                    <option value="N5">N5</option>
                    <option value="N6">N6</option>
                    <option value="N7">N7</option>
                    <option value="N8">N8</option>
                  </Input>
                </Col>
                <Col md={4}>
                  Zona comercial:
                  <Input type="select">
                    <option value="">Todas las zonas</option>
                    <option value="Norte">Norte</option>
                    <option value="Centro">Centro</option>
                    <option value="Sur">Sur</option>
                  </Input>
                </Col>
                <Col md={4}>
                  Tipo usuario:
                  <Input type="select">
                    <option value="">Todos los tipos</option>
                    <option value="Interno">Interno</option>
                    <option value="Externo">Externo</option>
                  </Input>
                </Col>
              </Row>
              <div className="clearfix"></div>
              <StackedColumnChart periodData={periodData} />
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col xl="12">
          <Card>
            <CardBody>
              <Nav className="icon-tab nav-justified">
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: activeTab === "1",
                    })}
                    onClick={() => {
                      toggleIconCustom("1")
                    }}
                  >
                    <span className="d-none d-sm-block">
                      Cursos en progreso (3)
                    </span>
                    <span className="d-block d-sm-none">
                      <i className="fas fa-cogs"></i>
                    </span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: activeTab === "2",
                    })}
                    onClick={() => {
                      toggleIconCustom("2")
                    }}
                  >
                    <span className="d-none d-sm-block">
                      Cursos pendientes (0)
                    </span>
                    <span className="d-block d-sm-none">
                      <i className="fas fa-cogs"></i>
                    </span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: activeTab === "3",
                    })}
                    onClick={() => {
                      toggleIconCustom("3")
                    }}
                  >
                    <span className="d-none d-sm-block">
                      Cursos completados (0)
                    </span>
                    <span className="d-block d-sm-none">
                      <i className="fas fa-cogs"></i>
                    </span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: activeTab === "4",
                    })}
                    onClick={() => {
                      toggleIconCustom("4")
                    }}
                  >
                    <span className="d-none d-sm-block">
                      Cursos favoritos (0)
                    </span>
                    <span className="d-block d-sm-none">
                      <i className="fas fa-cogs"></i>
                    </span>
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={activeTab} className="p-3 text-muted mt-4">
                <TabPane tabId="1" className="pt-4">
                  <Row>
                    <Col lg={4}>
                      <Card>
                        <Row className="no-gutters align-items-center">
                          <Col md={4}>
                            <CardImg
                              className="img-fluid"
                              src={festerPisos}
                              alt="Skote"
                            />
                            <small>
                              <i className="fa fa-calendar"></i> Último ingreso:
                              15 oct 21
                            </small>
                            <br />
                            <small>
                              <i className="fa fa-comment"></i> 15 comentarios
                            </small>
                          </Col>
                          <Col md={8}>
                            <CardBody>
                              <CardTitle>
                                Nivelación de pisos a espesores gruesos
                                <br />
                                <small>Categoría impermeabilizantes</small>
                              </CardTitle>
                              <CardText>
                                <small className="text-muted">
                                  <Progress
                                    color="secondary"
                                    value={83}
                                    style={{ width: "83%" }}
                                  />
                                  83%
                                </small>
                                <hr />

                                <Link
                                  to="/courses/info/1"
                                  className="btn btn-primary float-end"
                                >
                                  Ir al curso{" "}
                                  <i className="fa fa-arrow-right"></i>
                                </Link>
                              </CardText>
                            </CardBody>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                    <Col lg="4">
                      <Card>
                        <Row>
                          <Col md={4}>
                            <CardImg
                              className="img-fluid"
                              src={festerConcreto}
                              alt="Skote"
                            />
                            <small>
                              <i className="fa fa-calendar"></i> Último ingreso:
                              20 sep 21
                            </small>
                            <br />
                            <small>
                              <i className="fa fa-comment"></i> 3 comentarios
                            </small>
                          </Col>
                          <Col md={8}>
                            <CardBody>
                              <CardTitle>
                                Reparación estructural de concreto
                                <small>Categoría impermeabilizantes</small>
                              </CardTitle>
                              <CardText>
                                <small className="text-muted">
                                  <Progress
                                    color="secondary"
                                    value={57}
                                    style={{ width: "57%" }}
                                  />
                                  57%
                                </small>
                                <hr />

                                <Link
                                  to="/courses/info/1"
                                  className="btn btn-primary float-end"
                                >
                                  Ir al curso{" "}
                                  <i className="fa fa-arrow-right"></i>
                                </Link>
                              </CardText>
                            </CardBody>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                    <Col lg="4">
                      <Card>
                        <Row>
                          <Col md={4}>
                            <CardImg
                              className="img-fluid"
                              src={festerImpermeabilizante}
                              alt="Skote"
                            />
                            <small>
                              <i className="fa fa-calendar"></i> Último ingreso:
                              15 oct 21
                            </small>
                            <br />
                            <small>
                              <i className="fa fa-comment"></i> 15 comentarios
                            </small>
                          </Col>
                          <Col md={8}>
                            <CardBody>
                              <CardTitle>
                                Nivelación de pisos a espesores gruesos
                                <small>Categoría pisos</small>
                              </CardTitle>
                              <CardText>
                                <small className="text-muted">
                                  <Progress
                                    color="secondary"
                                    value={83}
                                    style={{ width: "83%" }}
                                  />
                                  83%
                                </small>
                                <hr />

                                <Link
                                  to="/courses/info/1"
                                  className="btn btn-primary float-end"
                                >
                                  Ir al curso{" "}
                                  <i className="fa fa-arrow-right"></i>
                                </Link>
                              </CardText>
                            </CardBody>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="2"></TabPane>
                <TabPane tabId="3"></TabPane>
                <TabPane tabId="4"></TabPane>
              </TabContent>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col xl="4">
          <SocialSource />
        </Col>
        <Col xl="4">
          <ActivityComp />
        </Col>

        <Col xl="4">{<TopCities />}</Col>
      </Row>

      <Row>
        <Col lg="12">{/* <LatestTranaction /> */}</Col>
      </Row>
    </Fragment>
  )
}

export default N1
