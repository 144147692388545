import React, { Component } from "react"
import cloneDeep from "lodash.clonedeep"
import ReactEcharts from "echarts-for-react"
import { PropTypes } from "prop-types"

class GuageN8 extends Component {
  constructor(props) {
    super(props)
    console.log(props.value)
    this.state = this.getInitialState(props.value)
  }

  getOption = value => {
    return {
      tooltip: {
        formatter: "{a}{b}: {c}%",
      },
      series: [
        {
          name: "Cursos",
          type: "gauge",
          detail: { formatter: "{value}%" },
          axisLine: {
            lineStyle: {
              color: [
                [0.3, "#ec4561"],
                [0.7, "#f1b44c"],
                [1, "#02a499"],
              ],
              width: 5,
            },
          },
          axisLabel: {
            show: false,
          },
          data: [{ value: parseInt(value) ?? 0, name: "" }],
        },
      ],
    }
  }

  timeTicket = null
  getInitialState = value => ({ option: this.getOption(value) })

  componentDidMount() {
    if (this.timeTicket) {
      clearInterval(this.timeTicket)
    }
    this.timeTicket = setInterval(() => {
      const option = cloneDeep(this.state.option)
      option.series[0].data.value = (Math.random() * 100).toFixed(2) - 0
      this.setState({ option: option })
    }, 2000)
  }

  componentWillUnmount() {
    if (this.timeTicket) {
      clearInterval(this.timeTicket)
    }
  }

  render() {
    return (
      <React.Fragment>
        <ReactEcharts
          style={{ height: "160px", width: "100%" }}
          option={this.state.option}
        />
        <p style={{ position: "relative", top: -30 }} className="text-center">
          Completados
        </p>
      </React.Fragment>
    )
  }
}

GuageN8.propTypes = {
  value: PropTypes.number,
}

export default GuageN8
