import {
  START_EXAM,
  START_EXAM_SUCCESS,
  START_EXAM_CLEAN,
  START_EXAM_ERROR,
  FINISH_EXAM,
  FINISH_EXAM_SUCCESS,
  FINISH_EXAM_CLEAN,
  FINISH_EXAM_ERROR,
  ANSWER_QUESTION_EXAM,
  ANSWER_QUESTION_EXAM_SUCCESS,
  ANSWER_QUESTION_EXAM_CLEAN,
  ANSWER_QUESTION_EXAM_ERROR,
} from "./actionTypes"

export const startExam = form => ({
  type: START_EXAM,
  payload: { form },
})

export const startExamSuccess = exam => {
  return {
    type: START_EXAM_SUCCESS,
    payload: { exam },
  }
}

export const startExamClean = () => {
  return {
    type: START_EXAM_CLEAN,
    payload: {},
  }
}

export const startExamError = error => {
  return {
    type: START_EXAM_ERROR,
    payload: error,
  }
}

export const finishExam = form => ({
  type: FINISH_EXAM,
  payload: { form },
})

export const finishExamSuccess = exam => {
  return {
    type: FINISH_EXAM_SUCCESS,
    payload: { exam },
  }
}

export const finishExamClean = () => {
  return {
    type: FINISH_EXAM_CLEAN,
    payload: {},
  }
}

export const finishExamError = error => {
  return {
    type: FINISH_EXAM_ERROR,
    payload: error,
  }
}

export const answerQuestionExam = form => ({
  type: ANSWER_QUESTION_EXAM,
  payload: { form },
})

export const answerQuestionExamSuccess = exam => {
  return {
    type: ANSWER_QUESTION_EXAM_SUCCESS,
    payload: { exam },
  }
}

export const answerQuestionExamClean = () => {
  return {
    type: ANSWER_QUESTION_EXAM_CLEAN,
    payload: {},
  }
}

export const answerQuestionExamError = error => {
  return {
    type: ANSWER_QUESTION_EXAM_ERROR,
    payload: error,
  }
}
