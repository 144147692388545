import { PropTypes } from "prop-types"
import { useState, useEffect, Fragment } from "react"
import { useSelector, useDispatch } from "react-redux"
import ReactDragListView from "react-drag-listview/lib/index.js"
import { Card, CardBody, Row, Col, Table } from "reactstrap"
import Countdown from "react-countdown"
import {
  startExam,
  startExamClean,
  finishExam,
  finishExamClean,
  answerQuestionExam,
  answerQuestionExamClean,
} from "store/actions"

import {
  AvField,
  AvForm,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation"
import ModalCertificate from "components/Courses/ModalCertificate"
import {
  getExam,
  getExamClean,
  listQuestions,
  listQuestionsClean,
  getQuestion,
  getQuestionClean,
} from "store/actions"
import { Link } from "react-router-dom"

const canNotApplyExam = () => {
  return (
    <div className="alert alert-warning">
      <h3 className="text-center pt-2">
        <i className="fa fa-exclamation-circle"></i> Para poder aplicar el
        examen es necesario haber finalizado el curso al 100%.
      </h3>
    </div>
  )
}

const shuffle = array => {
  let currentIndex = array.length,
    randomIndex

  // While there remain elements to shuffle...
  while (currentIndex != 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex--

    // And swap it with the current element.
    ;[array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ]
  }

  return array
}

const CourseExamUser = props => {
  const dispatch = useDispatch()
  const canAppyExam = props.canApplyExam ?? false
  const idCourse = props.course?.idCurso ?? 0
  const [examOnCourse, setExamOnCourse] = useState(false)
  const [idExamTry, setIdExamTry] = useState(0)
  const [startTimer, setStartTimer] = useState(undefined)
  const [examResult, setExamResult] = useState(undefined)
  const [answerValue, setAnswerValue] = useState(0)
  const [questionsToDisplay, setQuestionsToDisplay] = useState([])
  const [answersToDisplay, setAnswersToDisplay] = useState([])
  const [questionIndex, setQuestionIndex] = useState(0)
  const [showCertificate, setShowCertificate] = useState(false)
  const [answerData, setAnswerData] = useState([])

  const {
    exam,
    loading,
    error,
    done,
    questions,
    loadingQuestions,
    errorQuestions,
    question,
    startExamValue,
    startExamDone,
    startExamLoading,
    finishExamDone,
    finishExamLoading,
    finishExamInfo,
    answerQuestionExamDone,
    answerQuestionExamLoading,
    whoIAm,
  } = useSelector(store => ({
    whoIAm: store.Profile.user,
    exam: store.ExamGet.exam,
    loading: store.ExamGet.loading,
    error: store.ExamGet.error,
    done: store.ExamGet.done,
    questions: store.QuestionsList.questions,
    loadingQuestions: store.QuestionsList.loading,
    errorQuestions: store.QuestionsList.error,
    question: store.QuestionGet.question,
    startExamValue: store.ExamTry.start,
    startExamDone: store.ExamTry.doneStart,
    startExamLoading: store.ExamTry.loadingStart,
    finishExamDone: store.ExamTry.doneFinish,
    finishExamLoading: store.ExamTry.loadingFinish,
    finishExamInfo: store.ExamTry.finish,
    answerQuestionExamDone: store.ExamTry.doneAnswerQuestion,
    answerQuestionExamLoading: store.ExamTry.loadingAnswerQuestion,
  }))

  useEffect(() => {
    dispatch(getExam(idCourse))
    return () => {
      dispatch(getExamClean())
    }
  }, [idCourse])

  const idExam = exam?.idCourseExam

  useEffect(() => {
    if (idExam !== undefined) {
      const questionCount =
        exam?.questionsCount === 0
          ? exam?.examQuestionexam?.examQuestion
          : exam?.questionsCount
      const shuffleQuestions = shuffle(exam?.examQuestion)
      const maxQuestions =
        questionCount < shuffleQuestions.length
          ? exam.questionsCount
          : shuffleQuestions.length
      setQuestionsToDisplay(shuffleQuestions.slice(0, maxQuestions))
    }
  }, [idExam])

  useEffect(() => {
    if (question !== undefined) setAnswersToDisplay(shuffle(question.answers))
  }, [question])

  useEffect(() => {
    const myExam = localStorage.getItem(`myExam-${idExam}`)
    if (myExam) {
      setExamResult(JSON.parse(myExam))
    }
  }, [idCourse, idExam])

  useEffect(() => {
    setAnswerData(answersToDisplay)
    return () => {
      setAnswerData([])
    }
  }, [answersToDisplay])

  const handleStartExam = () => {
    const questions = []
    questionsToDisplay.forEach(question => {
      questions.push(question.idQuestion)
    })

    const request = {
      idExam: idExam,
      Questions: questions,
    }

    dispatch(startExam(request))
  }

  const handleAnswer = async (event, errors, values) => {
    if (errors.length > 0) {
      return
    }

    const request = {
      idExam: idExam,
      idExamTry: idExamTry,
      idQuestion: questionsToDisplay[questionIndex].idQuestion,
      idSelectedResponses: [values.answer],
    }

    dispatch(answerQuestionExam(request))
  }

  const handleSubmitOrderedAnswers = () => {
    const selectedResponsens = []
    answerData.map(answer => selectedResponsens.push(answer.idQuestionAnswer))
    const request = {
      idExam: idExam,
      idExamTry: idExamTry,
      idQuestion: questionsToDisplay[questionIndex].idQuestion,
      idSelectedResponses: selectedResponsens,
    }

    dispatch(answerQuestionExam(request))
  }

  const Next = () => {
    setQuestionIndex(questionIndex + 1)
    const idQuestion = questionsToDisplay[questionIndex + 1].idQuestion
    dispatch(getQuestion(idQuestion))
  }

  const Prev = () => {
    setQuestionIndex(questionIndex - 1)
    const idQuestion = questionsToDisplay[questionIndex - 1].idQuestion
    dispatch(getQuestion(idQuestion))
  }

  const handleFinishExam = () => {
    const request = {
      idExam: idExam,
      idExamTry: idExamTry,
    }
    dispatch(finishExam(request))
  }

  const handleShowCertificate = () => {
    setShowCertificate(true)
  }

  const handleRepeatExam = () => {
    setExamOnCourse(false)
    setExamResult(undefined)
    localStorage.setItem(`myExam-${idExam}`, undefined)
    localStorage.removeItem(`myExam-${idExam}`)
  }

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      const modifiedData = [...answerData]
      const item = modifiedData.splice(fromIndex, 1)[0]
      modifiedData.splice(toIndex, 0, item)
      setAnswerData(modifiedData)
    },
    nodeSelector: "tr",
    handleSelector: "tr",
  }

  if (startExamDone) {
    setExamOnCourse(true)
    setIdExamTry(startExamValue.idExamTry)
    setStartTimer(Date.now())
    const idQuestion = questionsToDisplay[questionIndex].idQuestion
    dispatch(getQuestion(idQuestion))
    dispatch(startExamClean())
  }

  if (finishExamDone) {
    setExamOnCourse(false)
    setExamResult(finishExamInfo)
    localStorage.setItem(`myExam-${idExam}`, JSON.stringify(finishExamInfo))
    dispatch(finishExamClean())
  }

  if (answerQuestionExamDone) {
    if (questionIndex + 1 < questionsToDisplay.length) Next()
    else handleFinishExam()
    dispatch(answerQuestionExamClean())
  }

  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      if (exam?.endExamOnTimeOut) {
        handleFinishExam()
      }
      return (
        <h2 className="alt-celf-color d-flex justify-content-end">
          ¡Se acabó el tiempo!
        </h2>
      )
    } else {
      // Render a countdown
      return (
        <h2 className="alt-celf-color d-flex justify-content-end">
          {`${hours < 10 ? "0" : ""}${hours}`}:
          {`${minutes < 10 ? "0" : ""}${minutes}`}:
          {`${seconds < 10 ? "0" : ""}${seconds}`}
        </h2>
      )
    }
  }

  if (!canAppyExam) return canNotApplyExam()

  if (examResult !== undefined || props.approved) {
    const pass = examResult?.pass ?? false
    return (
      <Card>
        <CardBody>
          <h2 className="alt-celf-color">Examen</h2>
          <hr />
          <Row className="mb-4 pb-4">
            <Col md={4}>
              <p>Calificación</p>
              <label
                className={`badge bg-pill pt-2 bg-${
                  pass || props.approved ? "success" : "danger"
                }`}
              >
                <span style={{ fontSize: "24px" }} className="text-white">
                  {pass || props.approved ? "Aprobado" : "No aprobado"}
                </span>
              </label>
              {(pass || props.approved) && (
                <div className="mt-2">
                  <button
                    type="button"
                    className="btn btn-link"
                    onClick={() => handleShowCertificate()}
                    style={{ fontSize: "24px" }}
                  >
                    <i className="fas fa-medal"></i> Ver constancia
                  </button>
                </div>
              )}
            </Col>
            <Col md={4}>
              <p>Tiempo realizado</p>
              <h2>
                {examResult !== undefined ? examResult.totalMinutes : "5"}{" "}
                minutos
              </h2>
            </Col>
            <Col md={4}>
              <p>Preguntas correctas</p>
              {examResult === undefined && <h2>100%</h2>}
              {examResult !== undefined && (
                <h2>
                  {Math.round(
                    (examResult.correctAnsw / questionsToDisplay.length) * 100
                  )}
                  %{" "}
                  <small style={{ fontSize: "14px" }}>
                    {examResult.correctAnsw} de {questionsToDisplay.length}
                  </small>
                </h2>
              )}
            </Col>
          </Row>
          {examResult !== undefined && (
            <Row className="mt-4 mb-2">
              <Col>
                <Table className="table-striped">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <th>Pregunta</th>
                      <th>Tipo respuesta</th>
                      <th>Referencia lección</th>
                      <th>Correcta</th>
                    </tr>
                  </thead>
                  <tbody>
                    {examResult.Answers.map((answer, index) => (
                      <tr key={`answer-${index}`}>
                        <td>{index + 1}</td>
                        <td>{answer.Question}</td>
                        <td>{answer.QuestionType}</td>
                        <td>{answer.lesson === "" ? "N/A" : answer.lesson}</td>
                        <td>{answer.correct ? "Sí" : "No"}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
          )}
          {examResult !== undefined && (
            <Row>
              <Col className="d-flex justify-content-end">
                {!pass && (
                  <button
                    type="button"
                    onClick={handleRepeatExam}
                    className="btn btn-secondary"
                  >
                    Intentar de nuevo
                  </button>
                )}
              </Col>
            </Row>
          )}
        </CardBody>
        <ModalCertificate
          open={showCertificate}
          exam={examResult}
          user={whoIAm}
          course={props.course}
          onClose={() => setShowCertificate(false)}
        />
      </Card>
    )
  }

  if (examOnCourse) {
    return (
      <Card>
        <CardBody>
          <Row>
            <Col sm={8}>
              <h2 className="alt-celf-color">Examen</h2>
            </Col>
            <Col sm={4}>
              <Countdown
                date={startTimer + exam?.examTomeOut * 60 * 1000}
                renderer={renderer}
                intervalDelay={0}
                precision={6}
              />
            </Col>
          </Row>
          <hr />
          <h4>
            Pregunta {questionIndex + 1} de {questionsToDisplay.length}
          </h4>
          <Row>
            <Col md={6}>
              <p style={{ fontSize: "16px" }}>
                {questionsToDisplay[questionIndex].question}
              </p>
              {questionsToDisplay[questionIndex].image !== "" && (
                <Row>
                  <Col md={6} sm={12}>
                    <img
                      src={questionsToDisplay[questionIndex].image}
                      alt="Image"
                    />
                  </Col>
                </Row>
              )}
            </Col>
            <Col md={6}>
              {questionsToDisplay[questionIndex].questionType.idQuestionType ===
                3 && (
                <Fragment>
                  <ReactDragListView {...dragProps}>
                    <Table>
                      <thead>
                        <tr>
                          <th width={"10%"}></th>
                          <th width={"90%"}>
                            Ordene las respuesas de manera correcta
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {(answerData || []).map((answer, index) => {
                          return (
                            <tr key={`th-answer-${answer.idQuestionAnswer}`}>
                              <td>
                                <i className="fa fa-grip-vertical"></i>
                              </td>
                              <td>{answer.answer}</td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </Table>
                  </ReactDragListView>
                  <div className="d-flex justify-content-end mt-4">
                    {questionIndex > 0 && (
                      <button
                        type="button"
                        className="btn btn-light"
                        onClick={() => Prev()}
                      >
                        Anterior
                      </button>
                    )}

                    {questionIndex + 1 < questionsToDisplay.length && (
                      <Fragment>
                        <button
                          type="button"
                          onClick={handleSubmitOrderedAnswers}
                          className={`btn btn-secondary ${
                            answerQuestionExamLoading ? "disabled" : ""
                          }`}
                        >
                          Siguiente
                        </button>
                      </Fragment>
                    )}

                    {questionIndex + 1 >= questionsToDisplay.length && (
                      <Fragment>
                        <button
                          type="button"
                          onClick={handleSubmitOrderedAnswers}
                          className={`btn btn-secondary ${
                            !finishExamLoading && !answerQuestionExamLoading
                              ? ""
                              : "disabled"
                          }`}
                        >
                          {!finishExamLoading && !answerQuestionExamLoading
                            ? "Finalizar curso"
                            : "Finalizando curso..."}
                        </button>
                      </Fragment>
                    )}
                  </div>
                </Fragment>
              )}
              {questionsToDisplay[questionIndex].questionType.idQuestionType !==
                3 && (
                <AvForm className="form-horizontal" onSubmit={handleAnswer}>
                  <AvRadioGroup
                    name="answer"
                    className="form-control noNorder"
                    onChange={e => setAnswerValue(parseInt(e.target.value))}
                    value={answerValue}
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Seleccione la respuesta correcta",
                      },
                    }}
                  >
                    {answersToDisplay.map((answer, index) => {
                      return (
                        <Row
                          className="pb-3"
                          key={`answer-${answer.idQuestionAnswer}`}
                        >
                          <Col>
                            <AvRadio
                              label={answer.answer}
                              value={answer.idQuestionAnswer}
                            />
                          </Col>
                        </Row>
                      )
                    })}
                  </AvRadioGroup>
                  <div className="d-flex justify-content-end mt-4">
                    {questionIndex > 0 && (
                      <button
                        type="button"
                        className="btn btn-light"
                        onClick={() => Prev()}
                      >
                        Anterior
                      </button>
                    )}

                    {questionIndex + 1 < questionsToDisplay.length && (
                      <Fragment>
                        <button
                          type="submit"
                          className={`btn btn-secondary ${
                            answerQuestionExamLoading ? "disabled" : ""
                          }`}
                        >
                          Siguiente
                        </button>
                      </Fragment>
                    )}

                    {questionIndex + 1 >= questionsToDisplay.length && (
                      <Fragment>
                        <button
                          type="submit"
                          className={`btn btn-secondary ${
                            !finishExamLoading && !answerQuestionExamLoading
                              ? ""
                              : "disabled"
                          }`}
                        >
                          {!finishExamLoading && !answerQuestionExamLoading
                            ? "Finalizar curso"
                            : "Finalizando curso..."}
                        </button>
                      </Fragment>
                    )}
                  </div>
                </AvForm>
              )}
            </Col>
          </Row>
        </CardBody>
      </Card>
    )
  }

  return (
    <Card>
      <CardBody>
        <h2 className="alt-celf-color">Examen</h2>
        <hr />
        <h4>Consideraciones</h4>
        <ol className="lista">
          <li>
            La calificación aprobatoria para este curso es de{" "}
            <strong>{props.course?.minimoAprovatorio ?? 0}</strong>.
          </li>
          <li>
            Es altamente recomendable haber revisado el contenido del curso de
            forma completa.
          </li>
          <li>
            El examen está disponible desde el momento que se abre el curso
            hasta que finaliza, dentro del periodo de fechas del curso, pero una
            vez que lo inicia deberá terminarlo en una sola sesión.
          </li>
          <li>
            Este examen tiene un tiempo de realización aproximado de{" "}
            <strong>{exam?.examTomeOut ?? 0}</strong> minutos, en los cuales
            usted deberá finalizar el examen. A los{" "}
            <strong>{exam?.examTomeOut ?? 0}</strong> minutos de haber iniciado
            el curso se cerrará.
          </li>
          <li>
            El examen cuenta con un número diferente de preguntas que se toman
            de forma aleatoria.
          </li>
          <li>
            Al finalizar, se mostrará un resumen del examen por respuesta,
            indicando cual fue correcta y cual incorrecta, si hubiera
          </li>
          <li>No tiene un límite de oportunidades para aprobar el examen.</li>
          <li>
            Para iniciar el examen, de clic en &quot;Iniciar examen&quot;.
          </li>
        </ol>
        <div className="row">
          <div className="col-12 d-flex justify-content-end">
            {!startExamLoading && (
              <button
                className="btn btn-secondary"
                onClick={() => handleStartExam()}
              >
                Iniciar examen
              </button>
            )}
            {startExamLoading && (
              <button className="btn btn-secondary disabled">
                Iniciando examen...
              </button>
            )}
          </div>
        </div>
      </CardBody>
      <style jsx="true">{`
        ol.lista li {
          margin-bottom: 8px;
        }
      `}</style>
    </Card>
  )
}

CourseExamUser.propTypes = {
  canApplyExam: PropTypes.bool,
  course: PropTypes.object,
  approved: PropTypes.bool,
}

export default CourseExamUser
