/* eslint-disable no-undef */
import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { REMOVE_BLOCK } from "./actionTypes"
import { apiError, removeBlockSuccess } from "./actions"

//Include Both Helper File with needed methods
import { deleteBlock } from "../../../helpers/backend_helper"

function* remove(action) {
  try {
    const response = yield call(deleteBlock, action.payload.id)
    if (response === undefined) throw new Error("Error al remover bloque")
    if (!response.success)
      throw new Error(`Error al remover bloque: ${response.error}`)
    yield put(removeBlockSuccess(response))
  } catch (error) {
    console.log(error)
    yield put(apiError(`${error}`))
  }
}

function* blockRemoveSaga() {
  yield takeEvery(REMOVE_BLOCK, remove)
}

export default blockRemoveSaga
