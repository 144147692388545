import {
  GET_USER,
  GET_USER_SUCCESS,
  EDIT_USER,
  EDIT_USER_SUCCESS,
  API_ERROR,
  EDIT_USER_CLEAN,
  ACTIVATE_DEACTIVATE_USER,
  ACTIVATE_DEACTIVATE_USER_SUCCESS,
  ACTIVATE_DEACTIVATE_USER_ERROR,
  ACTIVATE_DEACTIVATE_USER_CLEAN,
} from "./actionTypes"

export const getUser = id => {
  return {
    type: GET_USER,
    payload: { id },
  }
}

export const getUserSuccess = user => {
  return {
    type: GET_USER_SUCCESS,
    payload: { user },
  }
}

export const editUser = form => ({
  type: EDIT_USER,
  payload: { form },
})

export const editUserSuccess = user => {
  return {
    type: EDIT_USER_SUCCESS,
    payload: { user },
  }
}

export const editUserClean = () => {
  return {
    type: EDIT_USER_CLEAN,
    payload: {},
  }
}

export const apiError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}

export const activateDeactivate = idUsuario => {
  return {
    type: ACTIVATE_DEACTIVATE_USER,
    payload: idUsuario,
  }
}

export const activateDeactivateSuccess = () => {
  return {
    type: ACTIVATE_DEACTIVATE_USER_SUCCESS,
    payload: {},
  }
}

export const activateDeactivateError = error => {
  return {
    type: ACTIVATE_DEACTIVATE_USER_ERROR,
    payload: error,
  }
}

export const activateDeactivateClean = () => {
  return {
    type: ACTIVATE_DEACTIVATE_USER_CLEAN,
    payload: {},
  }
}