import React, { Component, Fragment, useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Container, Row, Col, Card, CardBody } from "reactstrap"

import Breadcrumbs from "../../components/Common/Breadcrumb2"
import SupportMaterialTypesListTable from "components/SupportMaterialTypes/SupportMaterialTypesListTable"

const SupportMaterialTypes = props => {
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Tipos de material de apoyo | CELF</title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col sm={4}>
              <h4>Catálogo de tipos de material de apoyo</h4>
            </Col>
            <Col sm={8}>
              <Breadcrumbs
                breadcrumbItems={[
                  { key: "dashboard", title: "Dashboard", link: "/dashboard" },
                  { key: "catalog", title: "Catálogos", link: "/catalog" },
                  {
                    key: "support-material-types",
                    title: "Tipos de material de apoyo",
                  },
                ]}
              />
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <SupportMaterialTypesListTable />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default SupportMaterialTypes
