import {
  LIST_SUPPORT_MATERIAL_TYPES,
  LIST_SUPPORT_MATERIAL_TYPES_SUCCESS,
  LIST_SUPPORT_MATERIAL_TYPES_ERROR,
  LIST_SUPPORT_MATERIAL_TYPES_CLEAN,
} from "./actionTypes"

export const listSupportMaterialTypes = () => ({
  type: LIST_SUPPORT_MATERIAL_TYPES,
  payload: {},
})

export const listSupportMaterialTypesSuccess = supportMaterialTypes => {
  return {
    type: LIST_SUPPORT_MATERIAL_TYPES_SUCCESS,
    payload: { supportMaterialTypes },
  }
}

export const listSupportMaterialTypesClean = () => {
  return {
    type: LIST_SUPPORT_MATERIAL_TYPES_CLEAN,
    payload: {},
  }
}

export const apiError = error => {
  return {
    type: LIST_SUPPORT_MATERIAL_TYPES_ERROR,
    payload: error,
  }
}
