import {
  GET_LESSONS,
  GET_LESSONS_SUCCESS,
  GET_LESSONS_ERROR,
  GET_LESSONS_CLEAN,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  done: false,
  lessons: [],
}

const LessonsList = (state = initialState, action) => {
  switch (action.type) {
    case GET_LESSONS:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_LESSONS_SUCCESS:
      state = {
        ...state,
        lessons: action.payload.lessons,
        loading: false,
        done: true,
      }
      break
    case GET_LESSONS_CLEAN:
      state = initialState
      break
    case GET_LESSONS_ERROR:
      state = { ...state, error: action.payload, loading: false, done: true }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default LessonsList
