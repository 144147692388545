import React, { useEffect } from "react"

import { Row, Col, Card, CardBody } from "reactstrap"
import { Link } from "react-router-dom"

import avatar1 from "../../assets/images/user_without_pic.png"
import { useSelector, useDispatch } from "react-redux"
import { me } from "../../store/actions"
import GuageN8 from "components/Charts/GuageN8"

const WelcomeComp = () => {
  const dispatch = useDispatch()

  const { whoIAm } = useSelector(state => ({
    whoIAm: state.Profile.user,
  }))

  const user =
    whoIAm === undefined
      ? {
          idUsuario: 0,
          nombre: "",
          apellido: "",
          idRol: 0,
        }
      : whoIAm

  useEffect(() => {
    dispatch(me())
  }, [])

  return (
    <React.Fragment>
      <Card className="overflow-hidden">
        <div className="alt-celf-background-2">
          <Row>
            <Col xs="12">
              <div className="p-4 d-flex justify-content-between">
                <h5 className="main-celf-color text-primary">
                  ¡Bienvenid@ de regreso!
                </h5>
                <div className="">
                  {user.nombre} / N{user.idRol}
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <CardBody className="pt-0">
          <Row>
            <Col sm="4">
              <div className="avatar-md profile-user-wid mb-2">
                <img
                  src={avatar1}
                  alt=""
                  className="img-thumbnail rounded-circle"
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={6} className="mb-2">
              <p>
                Grupo:
                <br />
                <strong>Fester Network South & East</strong>
              </p>
              <p>
                Distribuidor:
                <br />
                <strong>Grupo Impermex SA de CV</strong>
              </p>
              <a href="#" className="btn btn-secondary">
                Ver perfil
              </a>
            </Col>
            <Col xs={6} className="mb-2">
              <GuageN8 />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}
export default WelcomeComp
