import {
  GET_USER,
  GET_USER_SUCCESS,
  EDIT_USER,
  EDIT_USER_SUCCESS,
  API_ERROR,
  EDIT_USER_CLEAN,
  ACTIVATE_DEACTIVATE_USER,
  ACTIVATE_DEACTIVATE_USER_SUCCESS,
  ACTIVATE_DEACTIVATE_USER_ERROR,
  ACTIVATE_DEACTIVATE_USER_CLEAN,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  done: false,
  updateLoading: false,
  updateDone: false,
  activateDeactivateLoading: false,
  activateDeactivateError: "",
  user: undefined,
}

const UserEdit = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_USER_SUCCESS:
      state = {
        ...state,
        loading: false,
        done: true,
        user: action.payload.user,
      }
      break
    case EDIT_USER:
      state = {
        ...state,
        updateLoading: true,
      }
      break
    case EDIT_USER_SUCCESS:
      state = {
        ...state,
        user: action.payload.user,
        updateLoading: false,
        updateDone: true,
      }
      break
    case EDIT_USER_CLEAN:
      state = {
        ...state,
        user: undefined,
        updateLoading: false,
        updateDone: false,
        loading: false,
        done: false,
      }
      break
    case ACTIVATE_DEACTIVATE_USER:
      state = {
        ...state,
        activateDeactivateLoading: true,
        activateDeactivateError: "",
      }
      break
    case ACTIVATE_DEACTIVATE_USER_SUCCESS:
      state = {
        ...state,
        activateDeactivateLoading: false,
        activateDeactivateSuccess: true,
        activateDeactivateError: "",
      }
      break
    case ACTIVATE_DEACTIVATE_USER_ERROR:
      state = {
        ...state,
        activateDeactivateLoading: false,
        activateDeactivateSuccess: false,
        activateDeactivateError: action.payload,
      }
      break
    case ACTIVATE_DEACTIVATE_USER_CLEAN:
      state = {
        ...state,
        activateDeactivateLoading: false,
        activateDeactivateSuccess: false,
        activateDeactivateError: "",
      }
      break
    case API_ERROR:
      state = {
        ...state,
        error: action.payload,
        loading: false,
        done: true,
        updateLoading: false,
        updateDone: false,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default UserEdit
