export const EDIT_LESSON = "EDIT_LESSON"
export const EDIT_LESSON_SUCCESS = "EDIT_LESSON_SUCCESS"
export const EDIT_LESSON_CLEAN = "EDIT_LESSON_CLEAN"
export const EDIT_LESSON_ERROR = "EDIT_LESSON_ERROR"
export const EDIT_LESSON_POSITIONS = "EDIT_LESSON_POSITIONS"
export const EDIT_LESSON_POSITIONS_SUCCESS = "EDIT_LESSON_POSITIONS_SUCCESS"
export const EDIT_LESSON_POSITIONS_CLEAN = "EDIT_LESSON_POSITIONS_CLEAN"
export const EDIT_LESSON_POSITIONS_ERROR = "EDIT_LESSON_POSITIONS_ERROR"
export const LESSON_START = "LESSON_START"
export const LESSON_START_SUCCESS = "LESSON_START_SUCCESS"
export const LESSON_START_CLEAN = "LESSON_START_CLEAN"
export const LESSON_START_ERROR = "LESSON_START_ERROR"
export const LESSON_FINISH = "LESSON_FINISH"
export const LESSON_FINISH_SUCCESS = "LESSON_FINISH_SUCCESS"
export const LESSON_FINISH_CLEAN = "LESSON_FINISH_CLEAN"
export const LESSON_FINISH_ERROR = "LESSON_FINISH_ERROR"
