/* eslint-disable no-undef */
import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { GET_SUPPORT_MATERIAL_TYPE } from "./actionTypes"
import { apiError, getSupportMaterialTypeSuccess } from "./actions"

//Include Both Helper File with needed methods
import { getSupportMaterialType } from "../../../helpers/backend_helper"

function* get(action) {
  try {
    const response = yield call(
      getSupportMaterialType,
      action.payload.idSupportMaterialType
    )
    yield put(getSupportMaterialTypeSuccess(response))
  } catch (error) {
    yield put(apiError(error))
  }
}

function* supportMaterialTypeGetSaga() {
  yield takeEvery(GET_SUPPORT_MATERIAL_TYPE, get)
}

export default supportMaterialTypeGetSaga
