import { withRouter } from "react-router"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Container, Row, Col, Card, CardBody } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb2"
import GroupsForm from "components/Groups/GroupsForm"

const AddGroup = props => {

  const emptyGroup = {
    nombreGrupo: "",
    tipoGrupo: 0,
    idNegocio: 0,
    idZonaComercial: 0,
    Responsible: [],
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Agregar grupo | CELF</title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col sm={4}>
              <h4>Crear nuevo grupo</h4>
            </Col>
            <Col sm={8}>
              <Breadcrumbs
                breadcrumbItems={[
                  { key: "dashboard", title: "Dashboard", link: "/dashboard" },
                  { key: "grupos", title: "Grupos", link: "/groups" },
                  { key: "add_group", title: "Nuevo grupo" },
                ]}
              />
            </Col>
          </Row>

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <GroupsForm insert={true} group={emptyGroup} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(AddGroup)
