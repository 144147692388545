import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { Modal, Input, Row, Col, FormGroup, Label } from "reactstrap"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"

const AddCourseToGroupModal = props => {
  const { SearchBar } = Search

  const addButton = (cell, row, rowIndex, extraData) => {
    return (
      <button
        onClick={() => props.onConfirm(row.idCurso)}
        className={`btn btn-light ${props.loading ? "disabled" : ""}`}
        key={`button-${row.idCurso}`}
      >
        <i className="fa fa-plus"></i>
      </button>
    )
  }

  const columns = [
    {
      dataField: "idCurso",
      text: "Id",
      sort: false,
      hidden: true,
    },
    {
      dataField: "claveCurso",
      text: "ID curso Henkel",
      sort: true,
    },
    {
      dataField: "nombreCurso",
      text: "Nombre del curso",
      sort: true,
    },
    {
      dataField: "actions",
      text: "Agregar",
      sort: false,
      formatter: addButton,
    },
  ]
  const pageOptions = {
    sizePerPage: 5,
    totalSize: props.courses.length,
    custom: true,
    showTotal: true,
  }

  const defaultSorted = [{ dataField: "idRol", order: "asc" }]

  return (
    <Modal
      isOpen={props.show}
      backdrop={"static"}
      size={"lg"}
      id="staticBackdrop"
    >
      <div className="modal-header">
        <h5 className="modal-title" id="staticBackdropLabel">
          Añadir cursos al grupo
        </h5>
        <button
          type="button"
          className="btn-close"
          onClick={() => {
            props.onCancel()
          }}
          aria-label="Close"
        ></button>
      </div>
      <div className="modal-body">
        {props.error !== "" && (
          <div className="alert alert-danger">{props.error}</div>
        )}
        <PaginationProvider
          pagination={paginationFactory(pageOptions)}
          keyField="idCurso"
          columns={columns}
          data={props.courses}
        >
          {({ paginationProps, paginationTableProps }) => (
            <ToolkitProvider
              keyField="idCurso"
              columns={columns}
              data={props.courses}
              search
            >
              {toolkitProps => (
                <React.Fragment>
                  <Row className="mb-2 pt-3">
                    <Col>
                      <div className="search-box me-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <SearchBar
                            {...toolkitProps.searchProps}
                            placeholder="Buscar"
                          />
                          <i className="bx bx-search-alt search-icon" />
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl="12">
                      <Fragment>
                        <BootstrapTable
                          keyField={"idUsuario"}
                          bordered={false}
                          striped={true}
                          defaultSorted={defaultSorted}
                          classes={"table align-middle table-nowrap"}
                          headerWrapperClasses={"thead-light"}
                          {...toolkitProps.baseProps}
                          {...paginationTableProps}
                        />
                        {props.courses.length <= 0 && (
                          <Row>
                            <Col md={12} className="text-center">
                              -- No hay cursos disponibles --
                            </Col>
                          </Row>
                        )}
                      </Fragment>
                    </Col>
                  </Row>

                  <Row className="align-items-md-center mt-30">
                    <Col className="inner-custom-pagination d-flex">
                      <div className="d-inline">
                        <SizePerPageDropdownStandalone {...paginationProps} />
                      </div>
                      <div className="text-md-right ms-auto">
                        <PaginationListStandalone {...paginationProps} />
                      </div>
                    </Col>
                  </Row>
                </React.Fragment>
              )}
            </ToolkitProvider>
          )}
        </PaginationProvider>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-light"
          onClick={() => {
            props.onCancel()
          }}
        >
          Salir
        </button>
      </div>
    </Modal>
  )
}

AddCourseToGroupModal.propTypes = {
  show: PropTypes.bool,
  loading: PropTypes.bool,
  courses: PropTypes.array,
  error: PropTypes.string,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
}

export default AddCourseToGroupModal
