import React, { useState } from "react"
import PropTypes from "prop-types"
import { Modal } from "reactstrap"

const DeleteCourseFromGroupModal = props => {
  return (
    <Modal isOpen={props.show} backdrop={"static"} id="staticBackdrop">
      <div className="modal-header">
        <h5 className="modal-title" id="staticBackdropLabel">
          Eliminar curso de grupo
        </h5>
        <button
          type="button"
          className="btn-close"
          onClick={() => {
            props.onCancel()
          }}
          aria-label="Close"
        ></button>
      </div>
      <div className="modal-body">
        {props.error !== "" && (
          <div className="alert alert-danger">{props.error}</div>
        )}
        <p>
          ¿Confirma que desea eliminar el curso {props.course?.nombreCurso} de
          este grupo?
        </p>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-light"
          onClick={() => {
            props.onCancel()
          }}
        >
          Cancelar
        </button>
        <button
          type="button"
          className={`btn btn-secondary  ${props.loading ? "disabled" : ""}`}
          onClick={() => props.onConfirm(props.course.idCurso)}
        >
          {props.loading ? "Eliminando..." : "Eliminar"}
        </button>
      </div>
    </Modal>
  )
}

DeleteCourseFromGroupModal.propTypes = {
  show: PropTypes.bool,
  loading: PropTypes.bool,
  course: PropTypes.object,
  error: PropTypes.string,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
}

export default DeleteCourseFromGroupModal
