import {
  REMOVE_QUESTION,
  REMOVE_QUESTION_SUCCESS,
  REMOVE_QUESTION_CLEAN,
  REMOVE_QUESTION_ERROR,
} from "./actionTypes"

export const removeQuestion = id => ({
  type: REMOVE_QUESTION,
  payload: { id },
})

export const removeQuestionSuccess = question => {
  return {
    type: REMOVE_QUESTION_SUCCESS,
    payload: { question },
  }
}

export const removeQuestionClean = () => {
  return {
    type: REMOVE_QUESTION_CLEAN,
    payload: {},
  }
}

export const apiError = error => {
  return {
    type: REMOVE_QUESTION_ERROR,
    payload: error,
  }
}
