import {
  ADD_SUPPORT_MATERIAL_TYPE,
  ADD_SUPPORT_MATERIAL_TYPE_SUCCESS,
  ADD_SUPPORT_MATERIAL_TYPE_CLEAN,
  ADD_SUPPORT_MATERIAL_TYPE_ERROR,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  done: false,
  supportMaterialType: {},
}

const SupportMaterialTypeAdd = (state = initialState, action) => {
  switch (action.type) {
    case ADD_SUPPORT_MATERIAL_TYPE:
      state = {
        ...state,
        loading: true,
        error: "",
      }
      break
    case ADD_SUPPORT_MATERIAL_TYPE_SUCCESS:
      state = {
        ...state,
        supportMaterialType: action.payload.supportMaterialType,
        loading: false,
        done: true,
      }
      break
    case ADD_SUPPORT_MATERIAL_TYPE_CLEAN:
      state = initialState
      break
    case ADD_SUPPORT_MATERIAL_TYPE_ERROR:
      state = { ...state, error: action.payload, loading: false, done: false }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default SupportMaterialTypeAdd
