import {
  EDIT_SUPPORT_MATERIAL_TYPE,
  EDIT_SUPPORT_MATERIAL_TYPE_SUCCESS,
  EDIT_SUPPORT_MATERIAL_TYPE_CLEAN,
  EDIT_SUPPORT_MATERIAL_TYPE_ERROR,
} from "./actionTypes"

export const editSupportMaterialType = form => ({
  type: EDIT_SUPPORT_MATERIAL_TYPE,
  payload: { form },
})

export const editSupportMaterialTypeSuccess = supportMaterialType => {
  return {
    type: EDIT_SUPPORT_MATERIAL_TYPE_SUCCESS,
    payload: { supportMaterialType },
  }
}

export const editSupportMaterialTypeClean = () => {
  return {
    type: EDIT_SUPPORT_MATERIAL_TYPE_CLEAN,
    payload: {},
  }
}

export const apiError = error => {
  return {
    type: EDIT_SUPPORT_MATERIAL_TYPE_ERROR,
    payload: error,
  }
}
