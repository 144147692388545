import {
  ADD_ANSWERS,
  ADD_ANSWERS_SUCCESS,
  ADD_ANSWERS_CLEAN,
  ADD_ANSWERS_ERROR,
} from "./actionTypes"

export const addAnsswers = form => ({
  type: ADD_ANSWERS,
  payload: { form },
})

export const addAnsswersSuccess = answer => {
  return {
    type: ADD_ANSWERS_SUCCESS,
    payload: { answer },
  }
}

export const addAnsswersClean = () => {
  return {
    type: ADD_ANSWERS_CLEAN,
    payload: {},
  }
}

export const apiError = error => {
  return {
    type: ADD_ANSWERS_ERROR,
    payload: error,
  }
}
