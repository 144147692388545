import {
  GET_COURSES,
  GET_COURSES_SUCCESS,
  API_ERROR,
  GET_COURSES_BY_USER,
  GET_COURSES_BY_USER_SUCCESS,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  done: false,
  courses: [],
}

const CoursesList = (state = initialState, action) => {
  switch (action.type) {
    case GET_COURSES:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_COURSES_SUCCESS:
      state = {
        ...state,
        courses: action.payload.courses,
        loading: false,
        done: true,
      }
      break
    case GET_COURSES_BY_USER:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_COURSES_BY_USER_SUCCESS:
      state = {
        ...state,
        courses: action.payload.courses,
        loading: false,
        done: true,
      }
      break
    case API_ERROR:
      state = { ...state, error: action.payload, loading: false, done: true }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default CoursesList
