import { GET_CUSTOMERS, GET_CUSTOMERS_SUCCESS, API_ERROR } from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  done: false,
  customers: [],
}

const CustomersList = (state = initialState, action) => {
  switch (action.type) {
    case GET_CUSTOMERS:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_CUSTOMERS_SUCCESS:
      state = {
        ...state,
        customers: action.payload.customers,
        loading: false,
        done: true,
      }
      break
    case API_ERROR:
      state = { ...state, error: action.payload, loading: false, done: true }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default CustomersList
