import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Row, Col, Button, Input } from "reactstrap"
import { Link, withRouter } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

import {
  getProfiles,
  getCustomers,
  addUser,
  addUserClean,
  editUser,
  getGroups,
  getPositions,
  getUser,
  getUsersByRole,
  editUserClean,
} from "store/actions"
import SweetAlert from "react-bootstrap-sweetalert"
import { AvForm, AvField } from "availity-reactstrap-validation"

const UsersForm = props => {
  const insert = props.insert ?? true
  const id = props.id ?? 0

  const [formError, setFormError] = useState(false)
  const [ocupationField, setOcupationField] = useState(false)
  const [n4Field, setN4Field] = useState(false)
  const [n5Field, setN5Field] = useState(false)
  const [n6Field, setN6Field] = useState(false)
  const [n7Field, setN7Field] = useState(false)
  const [userByRoleTag, setUserByRoleTag] = useState("")

  const changeProfile = evt => {
    const profileId = parseInt(evt.target.value)
    setVisibleFields(profileId)
  }

  const setVisibleFields = profileId => {
    switch (profileId) {
      case 1:
        setOcupationField(false)
        setN4Field(false)
        setN5Field(false)
        setN6Field(false)
        setN7Field(false)
        break
      case 2:
        setOcupationField(false)
        setN4Field(false)
        setN5Field(false)
        setN6Field(false)
        setN7Field(false)
        break
      case 3:
        setOcupationField(true)
        setN4Field(false)
        setN5Field(false)
        setN6Field(false)
        setN7Field(false)
        dispatch(getPositions())
        break
      case 4:
        setOcupationField(true)
        setN4Field(false)
        setN5Field(false)
        setN6Field(false)
        setN7Field(false)
        dispatch(getPositions())
        break
      case 5:
        setOcupationField(true)
        setN4Field(true)
        setN5Field(false)
        setN6Field(false)
        setN7Field(false)
        setUserByRoleTag("Director N4")
        dispatch(getPositions())
        dispatch(getUsersByRole(4))
        break
      case 6:
        setOcupationField(true)
        setN4Field(false)
        setN5Field(true)
        setN6Field(false)
        setN7Field(false)
        setUserByRoleTag("Director N5")
        dispatch(getPositions())
        dispatch(getUsersByRole(5))
        break
      case 7:
        setOcupationField(true)
        setN4Field(false)
        setN5Field(false)
        setN6Field(true)
        setN7Field(false)
        setUserByRoleTag("Interno N6")
        dispatch(getPositions())
        dispatch(getUsersByRole(6))
        break
      case 8:
        setOcupationField(true)
        setN4Field(false)
        setN5Field(false)
        setN6Field(false)
        setN7Field(true)
        dispatch(getPositions())
        dispatch(getCustomers())
        break
    }
  }

  const dispatch = useDispatch()

  const {
    usersByRole,
    profiles,
    groups,
    positions,
    clients,
    loading,
    done,
    user,
    insertedUser,
    insertedUserError,
    getUserLoading,
    getUserDone,
    editUserLoading,
    editUserError,
    editUserDone,
  } = useSelector(state => ({
    usersByRole: state.UsersList.usersByRole,
    profiles: state.ProfilesList.profiles,
    groups: state.GroupsList.groups,
    positions: state.PositionsList.positions,
    clients: state.CustomersList.customers,
    loading: state.UserAdd.loading,
    done: state.UserAdd.done,
    insertedUser: state.UserAdd.user,
    insertedUserError: state.UserAdd.error,
    user: state.UserEdit.user,
    getUserLoading: state.UserEdit.loading,
    getUserDone: state.UserEdit.done,
    editUserLoading: state.UserEdit.updateLoading,
    editUserDone: state.UserEdit.updateDone,
    editUserError: state.UserEdit.error,
  }))

  const userInfo =
    !insert && user !== undefined
      ? user
      : {
          nombre: "",
          apellido: "",
          email: "",
          idPosicion: 0,
          idGroup: 0,
          idRol: 0,
          idhCliente: 0,
          nombreCliente: "",
          idUserN4assigned: 0,
          idUserN5assigned: 0,
          idUserN6assigned: 0,
        }

  const idRol = userInfo.idRol

  const [form, setForm] = useState({
    firstName: "",
    lastName: "",
    email: "",
    profile: 0,
    occupation: 0,
    managerId: 0,
    customerId: 0,
    customerName: "",
  })

  useEffect(() => {
    dispatch(getProfiles())
    dispatch(getGroups())
    if (!insert) {
      dispatch(getUser(id))
      setVisibleFields(idRol)
    }
    return () => {
      dispatch(addUserClean())
    }
  }, [id, idRol])

  const handleSubmit = (event, errors, values) => {
    if (errors.length > 0) {
      setFormError(true)
      return
    }
    setFormError(false)
    const profileId = parseInt(values.profile)
    const occupation =
      profileId != 1 && profileId != 2 ? parseInt(values.occupation) : null
    const userByRole =
      profileId == 5 || profileId == 6 || profileId == 7
        ? parseInt(values.userByRole)
        : null
    const idh = profileId == 7 ? values.idh : null
    let customerName = profileId == 7 ? values.customerName : null
    let idhCustomer = profileId == 8 ? values.idhCustomer : null

    if (profileId == 7 || profileId == 8) {
      const selectedClient =
        profileId == 7
          ? []
          : clients.filter(element => element.idhCliente === idhCustomer)
      idhCustomer = profileId == 7 ? idh : idhCustomer
      customerName =
        profileId == 7 ? customerName : selectedClient[0].nombreCliente
    }

    let request = {
      nombre: values.firstName,
      apellido: values.lastName,
      email: values.email,
      idRol: profileId,
      idGrupo: parseInt(values.group),
      returnUri: `${process.env.REACT_APP_URL}/restore-password`,
    }

    if (occupation !== null) {
      request = { ...request, idPosicion: occupation }
    }
    if (userByRole !== null && profileId == 5) {
      request = { ...request, idUserN4assigned: userByRole }
    }
    if (userByRole !== null && profileId == 6) {
      request = { ...request, idUserN5assigned: userByRole }
    }
    if (userByRole !== null && profileId == 7) {
      request = { ...request, idUserN6assigned: userByRole }
    }
    if (idhCustomer !== null) {
      request = { ...request, idhCliente: idhCustomer }
    }
    if (customerName !== null) {
      request = { ...request, nombreCliente: customerName }
    }

    if (insert) {
      dispatch(addUser(request))
    } else {
      request = {
        ...request,
        idUsuario: id,
        idStatus: userInfo.idStatus,
      }
      dispatch(editUser(request))
    }
  }

  return (
    <AvForm className="form-horizontal" onSubmit={handleSubmit}>
      {done && insert && insertedUserError !== "" && (
        <div className="alert alert-danger">
          Ocurrió un error al registrar al usuario: {insertedUserError}
        </div>
      )}
      <Row className="mb-3">
        <Col md={6}>
          <AvField
            name="firstName"
            value={userInfo.nombre}
            label="* Nombre:"
            className="form-control"
            placeholder=""
            type="text"
            validate={{
              required: {
                value: true,
                errorMessage: "Este campo es requerido",
              },
            }}
          />
        </Col>
        <Col md={6}>
          <AvField
            name="lastName"
            value={userInfo.apellido}
            label="* Apellido:"
            className="form-control"
            placeholder=""
            type="text"
            validate={{
              required: {
                value: true,
                errorMessage: "Este campo es requerido",
              },
            }}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col md={12}>
          <AvField
            name="email"
            value={userInfo.email}
            label="* Correo electrónico de la compañia:"
            className="form-control"
            placeholder=""
            type="email"
            validate={{
              required: {
                value: true,
                errorMessage: "Este campo es requerido",
              },
              email: {
                value: true,
                errorMessage:
                  "Este campo debe contener un correo electrónico válido",
              },
            }}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col md={12}>
          {usersByRole !== undefined && (
            <AvField
              name="profile"
              value={userInfo.idRol}
              label="* Perfil:"
              className="form-control"
              placeholder=""
              type="select"
              onChange={event => {
                changeProfile(event)
              }}
              validate={{
                required: {
                  value: true,
                  errorMessage: "Este campo es requerido",
                },
              }}
            >
              <option key={0} value="">
                -- Seleccione perfil --
              </option>
              {profiles.map((element, index) => (
                <option value={element.idRol} key={element.idRol}>
                  {element.perfil}
                </option>
              ))}
            </AvField>
          )}
        </Col>
      </Row>
      {(n4Field || n5Field || n6Field) && (
        <Row className="mb-3">
          <Col md={12}>
            {usersByRole !== undefined && (
              <AvField
                name="userByRole"
                value={
                  idRol === 5
                    ? userInfo.idUserN4assigned
                    : idRol === 6
                    ? userInfo.idUserN5assigned
                    : userInfo.idUserN6assigned
                }
                label={`* ${userByRoleTag}`}
                className="form-control"
                placeholder=""
                type="select"
                validate={{
                  required: {
                    value: true,
                    errorMessage: "Este campo es requerido",
                  },
                }}
              >
                <option>-- Seleccione usuario --</option>
                {usersByRole.map((element, index) => (
                  <option value={element.IdUsuario} key={element.IdUsuario}>
                    {element.nombre} {element.apellido}
                  </option>
                ))}
              </AvField>
            )}
          </Col>
        </Row>
      )}
      {n6Field && (
        <Row className="mb-3">
          <Col md={4}>
            <AvField
              name="idh"
              value={userInfo.idhCliente}
              label="* IDH Cliente:"
              className="form-control"
              placeholder=""
              type="text"
              validate={{
                required: {
                  value: true,
                  errorMessage: "Este campo es requerido",
                },
                number: {
                  value: true,
                  errorMessage: "Este campo debe contener únicamente números",
                },
              }}
            />
          </Col>
          <Col md={8}>
            <AvField
              name="customerName"
              value={userInfo.nombreCliente}
              label="* Nombre cliente:"
              className="form-control"
              placeholder=""
              type="text"
              validate={{
                required: {
                  value: true,
                  errorMessage: "Este campo es requerido",
                },
              }}
            />
          </Col>
        </Row>
      )}
      {n7Field && (
        <Row className="mb-3">
          <Col md={12}>
            {clients !== undefined && (
              <AvField
                name="idhCustomer"
                value={userInfo.idhCliente}
                label={`* Nombre cliente`}
                className="form-control"
                placeholder=""
                type="select"
                validate={{
                  required: {
                    value: true,
                    errorMessage: "Este campo es requerido",
                  },
                }}
              >
                <option>-- Seleccione cliente --</option>
                {clients.map((element, index) => (
                  <option value={element.idhCliente} key={element.idhCliente}>
                    {element.nombreCliente}
                  </option>
                ))}
              </AvField>
            )}
          </Col>
        </Row>
      )}
      {ocupationField && (
        <Row className="mb-3">
          <Col md={12}>
            {positions !== undefined && (
              <AvField
                name="occupation"
                value={userInfo.idPosicion}
                label="* Ocupación:"
                className="form-control"
                placeholder=""
                type="select"
                validate={{
                  required: {
                    value: true,
                    errorMessage: "Este campo es requerido",
                  },
                }}
              >
                <option>-- Seleccione ocupación --</option>
                {positions.map((element, index) => (
                  <option value={element.idPosicion} key={element.idPosicion}>
                    {element.posicion}
                  </option>
                ))}
              </AvField>
            )}
          </Col>
        </Row>
      )}
      <Row className="mb-3">
        <Col md={12}>
          {groups !== undefined && (
            <AvField
              name="group"
              value={userInfo.idGroup}
              defaultValue={userInfo.idGroup}
              label="* Grupo:"
              className="form-control"
              placeholder=""
              type="select"
              validate={{
                required: {
                  value: true,
                  errorMessage: "Este campo es requerido",
                },
              }}
            >
              <option value="">-- Seleccione grupo --</option>
              <option value={0} key={0}>
                Ninguno
              </option>
              {groups.map((element, index) => (
                <option value={element.idGrupo} key={element.idGrupo}>
                  {element.nombreGrupo}
                </option>
              ))}
            </AvField>
          )}
        </Col>
      </Row>
      <Row className="mb-3">
        <Col xl={8} lg={8} md={8} sm={8} xs={0}></Col>
        <Col xl={2} lg={2} md={2} sm={2} xs={6}>
          <Button
            className={`float-right ${loading ? "disabled" : ""} btn-block`}
            type="submit"
          >
            {!loading && <span>Guardar</span>}
            {loading && <span>Guardando...</span>}
          </Button>
        </Col>
        <Col xl={2} lg={2} md={2} sm={2} xs={6}>
          <Link to="/users" className="btn btn-light btn-block">
            Cancelar
          </Link>
        </Col>
      </Row>
      {done && insert && insertedUserError === "" && (
        <SweetAlert
          title="Usuario registrado exitosamente"
          success
          confirmBtnBsStyle="success"
          onConfirm={() => {
            dispatch(addUserClean())
            props.history.push("/users")
          }}
        >
          <p>
            Hemos enviado un correo electrónico al usuario con las instrucciones
            para acceder a la plataforma.
          </p>
        </SweetAlert>
      )}

      {editUserDone && !insert && editUserError === "" && (
        <SweetAlert
          title="Usuario modificado exitosamente"
          success
          confirmBtnBsStyle="success"
          onConfirm={() => {
            dispatch(editUserClean())
            props.history.push("/users")
          }}
        >
          <p>El usuario ha sido modificado exitosamente.</p>
        </SweetAlert>
      )}
    </AvForm>
  )
}

UsersForm.propTypes = {
  id: PropTypes.number,
  insert: PropTypes.bool,
  history: PropTypes.object,
}

export default withRouter(UsersForm)
