import {
  GET_LESSONS,
  GET_LESSONS_SUCCESS,
  GET_LESSONS_ERROR,
  GET_LESSONS_CLEAN,
} from "./actionTypes"

export const getLessons = idCurso => ({
  type: GET_LESSONS,
  payload: { idCurso },
})

export const getLessonsSuccess = lessons => {
  return {
    type: GET_LESSONS_SUCCESS,
    payload: { lessons },
  }
}

export const getLessonsClean = () => {
  return {
    type: GET_LESSONS_CLEAN,
    payload: {},
  }
}

export const apiError = error => {
  return {
    type: GET_LESSONS_ERROR,
    payload: error,
  }
}
