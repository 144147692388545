import {
  REMOVE_FAV,
  REMOVE_FAV_SUCCESS,
  REMOVE_FAV_CLEAN,
  REMOVE_FAV_ERROR,
} from "./actionTypes"

export const removeFav = idCourse => ({
  type: REMOVE_FAV,
  payload: { idCourse },
})

export const removeFavSuccess = fav => {
  return {
    type: REMOVE_FAV_SUCCESS,
    payload: { fav },
  }
}

export const removeFavClean = () => {
  return {
    type: REMOVE_FAV_CLEAN,
    payload: {},
  }
}

export const apiError = error => {
  return {
    type: REMOVE_FAV_ERROR,
    payload: error,
  }
}
