/* eslint-disable no-undef */
import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { GET_COURSES, GET_COURSES_BY_USER } from "./actionTypes"
import { apiError, getCoursesSuccess, getCoursesByUserSuccess } from "./actions"

//Include Both Helper File with needed methods
import { getCourses, getCoursesByUser } from "../../../helpers/backend_helper"

function* courses() {
  try {
    const response = yield call(getCourses)
    yield put(getCoursesSuccess(response))
  } catch (error) {
    yield put(apiError(error))
  }
}

function* coursesByUser(action) {
  try {
    const response = yield call(getCoursesByUser, action.payload.idUser)
    yield put(getCoursesByUserSuccess(response))
  } catch (error) {
    yield put(apiError(error))
  }
}

function* coursesSaga() {
  yield takeEvery(GET_COURSES, courses)
  yield takeEvery(GET_COURSES_BY_USER, coursesByUser)
}

export default coursesSaga
