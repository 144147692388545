import PropTypes from "prop-types"
import { useState } from "react"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import DeletePollModal from "./DeletePollModal"

const CoursePollQuestions = props => {
  const [dropDown, setDropDown] = useState(0)
  const [pollToDelete, setPollToDelete] = useState(undefined)

  return (
    <table className="table">
      <thead>
        <tr>
          <th>No.</th>
          <th>Pregunta</th>
          <th>Tipo respuesta</th>
          <th colSpan={2}>Acciones</th>
        </tr>
      </thead>
      <tbody>
        {props.pollQuestions.length < 1 && (
          <tr>
            <td colSpan="6" className="text-center">
              {" "}
              -- No se han registrado preguntas --
            </td>
          </tr>
        )}
        {props.pollQuestions !== undefined &&
          props.pollQuestions.map((question, index) => {
            return (
              <tr key={`course-exam-question-${index}`}>
                <td>{index + 1}</td>
                <td>{question.question}</td>
                <td>{question.idQuestionType}</td>
                <td>
                  <Dropdown
                    key={`course-exam-dropdown-${question.idQuiz}`}
                    isOpen={question.idQuiz === dropDown}
                    toggle={() => {
                      setDropDown(
                        question.idQuiz === dropDown ? 0 : question.idQuiz
                      )
                    }}
                  >
                    <DropdownToggle
                      color="primary"
                      className="btn btn-primary btn-md"
                    >
                      Acciones <i className="mdi mdi-chevron-down" />
                    </DropdownToggle>
                    <DropdownMenu className="white-celf-background">
                      <DropdownItem onClick={() => props.onEdit(question)}>
                        Editar
                      </DropdownItem>
                      <DropdownItem onClick={() => setPollToDelete(question)}>
                        Eliminar
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </td>
              </tr>
            )
          })}
      </tbody>
      <DeletePollModal
        show={pollToDelete !== undefined}
        onCancel={() => setPollToDelete(undefined)}
        onConfirm={() => {
          props.onDelete(pollToDelete)
          setPollToDelete(undefined)
        }}
        error={""}
      />
    </table>
  )
}

CoursePollQuestions.propTypes = {
  pollQuestions: PropTypes.array,
  course: PropTypes.object,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
}

export default CoursePollQuestions
