import React, { useState } from "react"
import ReactPlayer from "react-player"
import PropTypes from "prop-types"
import { Modal, Row, Col } from "reactstrap"
import toastr from "toastr"
import "toastr/build/toastr.min.css"

const VideoPlayerModal = props => {
  return (
    <Modal
      isOpen={props.show}
      backdrop={"static"}
      size={"lg"}
      id="staticBackdrop"
    >
      <div className="modal-header">
        <h5 className="modal-title" id="staticBackdropLabel">
          {props.title}
        </h5>
        <button
          type="button"
          className="btn-close"
          onClick={() => {
            props.onCancel()
          }}
          aria-label="Close"
        ></button>
      </div>
      <div className="modal-body">
        <ReactPlayer url={props.path} controls={true} width={"100%"} />
        {!props.readOnly && (
          <Row className="pt-3">
            <Col className="d-flex justify-content-end">
              <button
                className={`btn btn-light`}
                onClick={() => {
                  toastr.success(
                    "La ruta del video fue copiada al portapapeles",
                    "Ruta copiada"
                  )
                  navigator.clipboard.writeText(props.path)
                }}
                type="button"
              >
                <i className="fa fa-clipboard"></i>
              </button>
              <span>&nbsp;&nbsp;&nbsp;</span>
              <button
                className={`btn btn-light`}
                onClick={() => {
                  toastr.success(
                    "El código del video fue copiadp al portapapeles",
                    "Código copiado"
                  )
                  navigator.clipboard.writeText(
                    `<video src="${props.path}" preload="auto" controls="" style="width: 100%; height: 100%;"></video>`
                  )
                }}
                type="button"
              >
                <i className="fa fa-code"></i>
              </button>
            </Col>
          </Row>
        )}
      </div>
    </Modal>
  )
}

VideoPlayerModal.propTypes = {
  path: PropTypes.string,
  title: PropTypes.string,
  show: PropTypes.bool,
  readOnly: PropTypes.bool,
  onCancel: PropTypes.func,
}

export default VideoPlayerModal
