import { Fragment, useState } from "react"
import { PropTypes } from "prop-types"
import {
  Col,
  Row,
  Media,
  Input,
  Modal,
  Table,
  Card,
  CardBody,
} from "reactstrap"

const CourseLessonIntro = props => {
  const [modal, setModal] = useState(true)

  return (
    <Fragment>
      <Row>
        <Col md={12}>
          <Card>
            <CardBody>
              <Table className="table table-striped">
                <tbody>
                  <tr>
                    <td align="right">Categorías</td>
                    <td>
                      {props.knowledgeCategories === undefined
                        ? ""
                        : props.knowledgeCategories
                            .map(category => category.label.trim())
                            .join(", ")}
                    </td>
                  </tr>
                  <tr>
                    <td align="right">Clave curso</td>
                    <td>{props.course?.claveCurso}</td>
                  </tr>
                  <tr>
                    <td align="right">Curso previo</td>
                    <td>
                      {props.previousCourses === undefined
                        ? ""
                        : props.previousCourses.length <= 0
                        ? "Ninguno"
                        : props.previousCourses
                            .map(category => category.label.trim())
                            .join(", ")}
                    </td>
                  </tr>
                  <tr>
                    <td align="right">Tiempo estimado</td>
                    <td>{props.course?.tiempoDedicacion} minutos</td>
                  </tr>
                  <tr>
                    <td align="right">Nivel</td>
                    <td>{props.level}</td>
                  </tr>
                  <tr>
                    <td align="right">Mínimo aprobatorio</td>
                    <td>{props.course?.minimoAprovatorio}</td>
                  </tr>
                  <tr>
                    <td align="right">Valoración usuarios</td>
                    <td>
                      <div>
                        <i className="far fa-star" />
                        <i className="far fa-star" />
                        <i className="far fa-star" />
                        <i className="far fa-star" />
                        <i className="far fa-star" />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Fragment>
  )
}

CourseLessonIntro.propTypes = {
  knowledgeCategories: PropTypes.array,
  course: PropTypes.object,
  previousCourses: PropTypes.array,
  responsibles: PropTypes.array,
  level: PropTypes.string,
}

export default CourseLessonIntro
