import {
  GET_USERS,
  GET_USERS_SUCCESS,
  API_ERROR,
  GET_USERS_BY_ROLE,
  GET_USERS_BY_ROLE_SUCCESS,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_ERROR,
  CHANGE_PASSWORD_CLEAN,
} from "./actionTypes"

export const getUsers = () => ({
  type: GET_USERS,
  payload: {},
})

export const getUsersSuccess = users => {
  return {
    type: GET_USERS_SUCCESS,
    payload: { users },
  }
}

export const getUsersByRole = idRol => ({
  type: GET_USERS_BY_ROLE,
  payload: { idRol },
})

export const getUsersByRoleSuccess = users => {
  return {
    type: GET_USERS_BY_ROLE_SUCCESS,
    payload: { users },
  }
}

export const changePassword = (id, password) => {
  return {
    type: CHANGE_PASSWORD,
    payload: { id, password },
  }
}

export const changePasswordSuccess = result => {
  return {
    type: CHANGE_PASSWORD_SUCCESS,
    payload: { result },
  }
}

export const apiError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}

export const changePasswordError = error => {
  return {
    type: CHANGE_PASSWORD_ERROR,
    payload: error,
  }
}

export const changePasswordClean = () => {
  return {
    type: CHANGE_PASSWORD_CLEAN,
    payload: {},
  }
}