/* eslint-disable no-undef */
import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { START_EXAM, FINISH_EXAM, ANSWER_QUESTION_EXAM } from "./actionTypes"
import {
  startExamSuccess,
  startExamError,
  finishExamSuccess,
  finishExamError,
  answerQuestionExamSuccess,
  answerQuestionExamError,
} from "./actions"

//Include Both Helper File with needed methods
import {
  startExam,
  finishExam,
  answerQuestionExam,
} from "../../../helpers/backend_helper"

function* start(request) {
  try {
    const response = yield call(startExam, request.payload.form)
    if (response === undefined) throw new Error("API error")
    if (!response.success) throw new Error(response.error)
    yield put(startExamSuccess(response))
  } catch (error) {
    console.log(error.message)
    yield put(startExamError(error.message))
  }
}

function* finish(request) {
  try {
    const response = yield call(finishExam, request.payload.form)
    if (response === undefined) throw new Error("API error")
    if (!response.success) throw new Error(response.error)
    yield put(finishExamSuccess(response))
  } catch (error) {
    console.log(error.message)
    yield put(finishExamError(error.message))
  }
}

function* answerQuestion(request) {
  try {
    const response = yield call(answerQuestionExam, request.payload.form)
    if (response === undefined) throw new Error("API error")
    if (!response.success) throw new Error(response.error)
    yield put(answerQuestionExamSuccess(response))
  } catch (error) {
    console.log(error.message)
    yield put(answerQuestionExamError(error.message))
  }
}

function* examTrySaga() {
  yield takeEvery(START_EXAM, start)
  yield takeEvery(FINISH_EXAM, finish)
  yield takeEvery(ANSWER_QUESTION_EXAM, answerQuestion)
}

export default examTrySaga
