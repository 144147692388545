import {
  EDIT_QUESTION,
  EDIT_QUESTION_SUCCESS,
  EDIT_QUESTION_CLEAN,
  EDIT_QUESTION_ERROR,
} from "./actionTypes"

export const editQuestion = form => ({
  type: EDIT_QUESTION,
  payload: { form },
})

export const editQuestionSuccess = question => {
  return {
    type: EDIT_QUESTION_SUCCESS,
    payload: { question },
  }
}

export const editQuestionClean = () => {
  return {
    type: EDIT_QUESTION_CLEAN,
    payload: {},
  }
}

export const apiError = error => {
  return {
    type: EDIT_QUESTION_ERROR,
    payload: error,
  }
}
