import {
  ADD_POLL,
  ADD_POLL_SUCCESS,
  ADD_POLL_CLEAN,
  ADD_POLL_ERROR,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  done: false,
  poll: undefined,
}

const PollAdd = (state = initialState, action) => {
  switch (action.type) {
    case ADD_POLL:
      state = {
        ...state,
        loading: true,
      }
      break
    case ADD_POLL_SUCCESS:
      state = {
        ...state,
        poll: action.payload.poll,
        loading: false,
        done: true,
      }
      break
    case ADD_POLL_CLEAN:
      state = initialState
      break
    case ADD_POLL_ERROR:
      state = { ...state, error: action.payload, loading: false, done: false }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default PollAdd
