import {
  PROFILE_ERROR,
  PROFILE_SUCCESS,
  EDIT_PROFILE,
  RESET_PROFILE_FLAG,
  MY_PROFILE,
  MY_PROFILE_SUCCESS,
  MY_PROFILE_ERROR,
  MY_PROFILE_RESET,
  ACCEPT_PRIVACY_TYC,
  ACCEPT_PRIVACY_TYC_SUCCESS,
  ACCEPT_PRIVACY_TYC_ERROR,
  ACCEPT_PRIVACY_TYC_RESET,
} from "./actionTypes"

const initialState = {
  error: "",
  success: "",
  user: undefined,
  profileError: "",
  privacyTycLoading: false,
  privacyTycSuccess: false,
  privacyTycError: "",
}

const profile = (state = initialState, action) => {
  switch (action.type) {
    case MY_PROFILE:
      state = { ...state }
      break
    case MY_PROFILE_SUCCESS:
      state = { ...state, user: action.payload.user }
      break
    case MY_PROFILE_ERROR:
      state = { ...state, profileError: action.payload }
      break
    case MY_PROFILE_RESET:
      state = { ...state, profileError: "", user: undefined }
      break
    case EDIT_PROFILE:
      state = { ...state }
      break
    case PROFILE_SUCCESS:
      state = { ...state, success: action.payload }
      break
    case PROFILE_ERROR:
      state = { ...state, error: action.payload }
      break
    case RESET_PROFILE_FLAG:
      state = { ...state, success: null }
      break
    case ACCEPT_PRIVACY_TYC:
      state = {
        ...state,
        privacyTycLoading: true,
      }
      break
    case ACCEPT_PRIVACY_TYC_SUCCESS:
      state = {
        ...state,
        privacyTycLoading: false,
        privacyTycError: "",
        privacyTycSuccess: true,
        user: { ...state.user, aceptotyc: true, aceptoPrivacidadDatos: true },
      }
      break
    case ACCEPT_PRIVACY_TYC_ERROR:
      state = {
        ...state,
        privacyTycLoading: false,
        privacyTycError: action.payload,
        privacyTycSuccess: false,
      }
      break
    case ACCEPT_PRIVACY_TYC_RESET:
      state = {
        ...state,
        privacyTycError: "",
        privacyTycSuccess: false,
        privacyTycLoading: false,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default profile
