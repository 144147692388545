import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import classnames from "classnames"
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardTitle,
  CardText,
  CardImg,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Media,
  Table,
  Nav,
  NavItem,
  NavLink,
  TabPane,
  TabContent,
  Progress,
  CardFooter,
} from "reactstrap"
import { Link, withRouter } from "react-router-dom"

import classNames from "classnames"

//import Charts
import StackedColumnChart from "./StackedColumnChart"

//import action
import { getChartsData as onGetChartsData } from "../../store/actions"

import festerImpermeabilizante from "../../assets/images/fester-impermeabilizante.jpg"
import festerConcreto from "../../assets/images/fester-concreto.jpeg"
import festerPisos from "../../assets/images/fester-pisos.jpeg"

// Pages Components
import WelcomeComp from "./WelcomeComp"
import MonthlyEarning from "./MonthlyEarning"
import SocialSource from "./SocialSource"
import ActivityComp from "./ActivityComp"
import TopCities from "./TopCities"
import LatestTranaction from "./LatestTranaction"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

//redux
import { useSelector, useDispatch } from "react-redux"
import N1 from "components/Dashboard/N1"
import N8 from "components/Dashboard/N8"
import N2to7 from "components/Dashboard/N2to7"

const Dashboard = props => {
  const dispatch = useDispatch()
  const [modal, setmodal] = useState(false)
  const [activeTab, setActiveTab] = useState("1")
  const [subscribemodal, setSubscribemodal] = useState(false)

  const { chartsData, user } = useSelector(state => ({
    chartsData: state.Dashboard.chartsData,
    user: state.Profile.user,
  }))

  if (
    user !== undefined &&
    user?.id !== 0 &&
    (user?.aceptotyc !== true || user?.aceptoPrivacidadDatos !== true)
  ) {
    props.history.push("/accept-privacy-tyc")
  }

  const idRol = user !== undefined ? user.idRol : 0

  const reports = [
    { title: "Orders", iconClass: "bx-copy-alt", description: "1,235" },
    { title: "Revenue", iconClass: "bx-archive-in", description: "$35, 723" },
    {
      title: "Average Price",
      iconClass: "bx-purchase-tag-alt",
      description: "$16.2",
    },
  ]

  const [periodData, setPeriodData] = useState([])
  const [periodType, setPeriodType] = useState("yearly")

  useEffect(() => {
    setPeriodData(chartsData)
  }, [chartsData])

  const onChangeChartPeriod = pType => {
    setPeriodType(pType)
    dispatch(onGetChartsData(pType))
  }

  const toggleIconCustom = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  useEffect(() => {
    dispatch(onGetChartsData("yearly"))
  }, [dispatch])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Dashboard | CELF</title>
        </MetaTags>
        <Container fluid>
          {idRol === 1 && <N1 />}
          {idRol === 8 && <N8 />}
          {idRol === 2 && <N2to7 />}
          {idRol === 3 && <N2to7 />}
          {idRol === 4 && <N2to7 />}
          {idRol === 5 && <N2to7 />}
          {idRol === 6 && <N2to7 />}
          {idRol === 7 && <N2to7 />}
        </Container>
      </div>

      {/* subscribe ModalHeader*/}
      <Modal
        isOpen={subscribemodal}
        role="dialog"
        autoFocus={true}
        centered
        data-toggle="modal"
        toggle={() => {
          setSubscribemodal(!subscribemodal)
        }}
      >
        <div className="modal-content">
          <div className="modal-header border-bottom-0">
            <ModalHeader
              toggle={() => {
                setSubscribemodal(!subscribemodal)
              }}
            ></ModalHeader>
          </div>
          <div className="modal-body">
            <div className="text-center mb-4">
              <div className="avatar-md mx-auto mb-4">
                <div className="avatar-title bg-light  rounded-circle text-primary h1">
                  <i className="mdi mdi-email-open"></i>
                </div>
              </div>

              <div className="row justify-content-center">
                <div className="col-xl-10">
                  <h4 className="text-primary">¡Suscríbete!</h4>
                  <p className="text-muted font-size-14 mb-4">
                    Suscríbete a nuestro newsletter y mantente notificado con
                    todas las actualizaciones de la capacitación en línea
                    Fester.
                  </p>

                  <Button color="primary" type="button" id="button-addon2">
                    <i className="bx bxs-paper-plane"></i> Suscribirme ahora
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
  history: PropTypes.object,
}

export default withRouter(withTranslation()(Dashboard))
