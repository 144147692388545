import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { useDispatch, useSelector } from "react-redux"
import { Row, Col, Card, CardBody, CardTitle, Modal, Input } from "reactstrap"
import CoursePollForm from "./CoursePollForm"
import CoursePollQuestions from "./CoursePollQuestions"
import ValPollQuestionModal from "./ValPollQuestionModal"
import MultiPollQuestionModal from "./MultiPollQuestionModal"
import { listPolls, listPollsClean } from "store/actions"

const questionTypes = {
  MULTI: { id: 1, name: "multiple" },
  VAL: { id: 4, name: "valoration" },
}

const CoursePoll = props => {
  const course = props.course
  const idCourse = course.idCurso

  const [showPollQuestionForm, setShowPollQuestionForm] = useState(undefined)
  const [pollQuestionToEdit, setPollQuestionToEdit] = useState(undefined)
  const [pollQuestionType, setPollQuestionType] = useState(0)

  const dispatch = useDispatch()
  const { pollQuestions } = useSelector(store => ({
    pollQuestions: store.PollsList.polls,
  }))

  useEffect(() => {
    dispatch(listPolls(idCourse))
    return () => {
      dispatch(listPollsClean())
    }
  }, [idCourse])

  const handleAddPollQuestion = questionType => {
    setShowPollQuestionForm(questionType)
  }
  const handleDeletePollQuestion = pollQuestion => {}
  const handleEditPollQuestion = pollQuestion => {
    setPollQuestionToEdit(pollQuestion)
    setShowPollQuestionForm(
      getPollQuestionTypeEnum(pollQuestion.questionType.idQuestionType)
    )
  }

  const getPollQuestionTypeEnum = idQuestionType => {
    return idQuestionType == 1 ? questionTypes.MULTI : questionTypes.VAL
  }

  return (
    <Row>
      <Col lg="12">
        <Card>
          <CardBody>
            <CardTitle className="mb-3">Encuesta</CardTitle>
            <CoursePollForm course={course} />
            <hr />
            <Row>
              <Col xl={2} lg={2} md={3}>
                <Row>
                  <button
                    onClick={() => {
                      handleAddPollQuestion(questionTypes.MULTI)
                    }}
                    className={`btn btn-primary mb-2`}
                  >
                    <i className="fa fa-question-circle"></i>
                    <br />
                    Opción múltiple
                  </button>
                  <button
                    onClick={() => {
                      handleAddPollQuestion(questionTypes.VAL)
                    }}
                    className={`btn btn-primary mb-2`}
                  >
                    <i className="fa fa-question-circle"></i>
                    <br />
                    Valoración
                  </button>
                </Row>
              </Col>
              <Col xl={1} lg={1} md={1}></Col>
              <Col xl={9} lg={9} md={8}>
                <CoursePollQuestions
                  course={course}
                  pollQuestions={pollQuestions ?? []}
                  onDelete={pollQuestion =>
                    handleDeletePollQuestion(pollQuestion)
                  }
                  onEdit={pollQuestion => handleEditPollQuestion(pollQuestion)}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
      <MultiPollQuestionModal
        show={showPollQuestionForm === questionTypes.MULTI}
        question={pollQuestionToEdit}
        course={course}
        onCancel={() => setShowPollQuestionForm(undefined)}
        onConfirm={() => {
          dispatch(listPolls(idCourse))
          setShowPollQuestionForm(undefined)
        }}
      />
      <ValPollQuestionModal
        show={showPollQuestionForm === questionTypes.VAL}
        question={pollQuestionToEdit}
        course={course}
        onCancel={() => setShowPollQuestionForm(undefined)}
        onConfirm={() => {
          dispatch(listPolls(idCourse))
          setShowPollQuestionForm(undefined)
        }}
      />
    </Row>
  )
}

CoursePoll.propTypes = {
  course: PropTypes.object,
}

export default CoursePoll
