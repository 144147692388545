import {
  GET_BLOCKS,
  GET_BLOCKS_SUCCESS,
  GET_BLOCKS_ERROR,
  GET_BLOCKS_CLEAN,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  done: false,
  blocks: [],
}

const BlocksList = (state = initialState, action) => {
  switch (action.type) {
    case GET_BLOCKS:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_BLOCKS_SUCCESS:
      state = {
        ...state,
        blocks: action.payload.blocks,
        loading: false,
        done: true,
      }
      break
    case GET_BLOCKS_ERROR:
      state = { ...state, error: action.payload, loading: false, done: true }
      break
    case GET_BLOCKS_CLEAN:
      state = initialState
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default BlocksList
