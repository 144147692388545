import { Fragment, useState, useEffect, useRef } from "react"
import { PropTypes } from "prop-types"
import { useDispatch, useSelector } from "react-redux"
import { Col, Row, Media, Modal, Card, CardBody, CardHeader } from "reactstrap"
import { listNotes } from "store/actions"
import moment from "common/moment_i18n"

const CourseNotes = props => {
  const dispatch = useDispatch()
  const idCourse = props.course.idCurso

  const { notes, notesLoading, notesDone, removeDone, addDone } = useSelector(
    store => ({
      notes: store.NotesList.notes,
      notesLoading: store.NotesList.loading,
      notesDone: store.NotesList.done,
      removeDone: store.NoteRemove.done,
      addDone: store.NoteAdd.done,
    })
  )

  useEffect(() => {
    dispatch(listNotes(idCourse))
  }, [idCourse])
  return (
    <Row>
      {notes !== undefined &&
        notes.map((note, index) => {
          return (
            <Col lg={4} sm={6} key={`main-note-${index}`}>
              <Card>
                <CardHeader>
                  <small>
                    {moment(note.noteDate)
                      .locale("es")
                      .format("DD/MM/Y hh:mm [hrs.]")}
                  </small>
                  <h3>Nota {index + 1}</h3>
                  <p>{note.note}</p>
                  <div>
                    <button
                      className="btn btn-link"
                      onClick={() => props.goToLesson(note.idLesson)}
                    >
                      Ir a lección <i className="fa fa-arrow-right"></i>
                    </button>
                  </div>
                </CardHeader>
              </Card>
            </Col>
          )
        })}
    </Row>
  )
}

CourseNotes.propTypes = {
  course: PropTypes.object,
  goToLesson: PropTypes.func,
}

export default CourseNotes
