import {
  START_LESSON,
  START_LESSON_SUCCESS,
  START_LESSON_CLEAN,
  START_LESSON_ERROR,
} from "./actionTypes"

export const startLesson = form => ({
  type: START_LESSON,
  payload: { form },
})

export const startLessonSuccess = lesson => {
  return {
    type: START_LESSON_SUCCESS,
    payload: { lesson },
  }
}

export const startLessonClean = () => {
  return {
    type: START_LESSON_CLEAN,
    payload: {},
  }
}

export const startLessonError = error => {
  return {
    type: START_LESSON_ERROR,
    payload: error,
  }
}
