import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { Modal, Input, Row, Col, FormGroup, Label } from "reactstrap"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"

const AddUserToGroupModal = props => {
  const { SearchBar } = Search

  const addButton = (cell, row, rowIndex, extraData) => {
    return (
      <button
        onClick={() => props.onConfirm(row.idUsuario)}
        className={`btn btn-light ${props.loading ? "disabled" : ""}`}
        key={`button-${row.idUsuario}`}
      >
        <i className="fa fa-plus"></i>
      </button>
    )
  }

  const columns = [
    {
      dataField: "idUsuario",
      text: "Id",
      sort: false,
      hidden: true,
    },
    {
      dataField: "nombre",
      text: "Nombre completo y correo",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (column, row) => {
        return (
          <div key={`nombre-${row.idUsuario}`}>
            <strong>
              {row.nombre} {row.apellido}
            </strong>
            <br />
            <span>{row.email}</span>
          </div>
        )
      },
    },
    {
      dataField: "apellido",
      text: "Apellido",
      sort: true,
      hidden: true,
    },
    {
      dataField: "email",
      text: "Correo",
      sort: true,
      hidden: true,
    },
    {
      dataField: "actions",
      text: "Agregar",
      sort: false,
      formatter: addButton,
    },
  ]
  const pageOptions = {
    sizePerPage: 5,
    totalSize: props.users.length,
    custom: true,
    showTotal: true,
  }

  const defaultSorted = [{ dataField: "idRol", order: "asc" }]

  return (
    <Modal isOpen={props.show} backdrop={"static"} id="staticBackdrop">
      <div className="modal-header">
        <h5 className="modal-title" id="staticBackdropLabel">
          Añadir usuarios al grupo
        </h5>
        <button
          type="button"
          className="btn-close"
          onClick={() => {
            props.onCancel()
          }}
          aria-label="Close"
        ></button>
      </div>
      <div className="modal-body">
        {props.error !== "" && (
          <div className="alert alert-danger">{props.error}</div>
        )}
        <PaginationProvider
          pagination={paginationFactory(pageOptions)}
          keyField="idUsuario"
          columns={columns}
          data={props.users}
        >
          {({ paginationProps, paginationTableProps }) => (
            <ToolkitProvider
              keyField="idUsuario"
              columns={columns}
              data={props.users}
              search
            >
              {toolkitProps => (
                <React.Fragment>
                  <Row className="mb-2 pt-3">
                    <Col>
                      <div className="search-box me-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <SearchBar
                            {...toolkitProps.searchProps}
                            placeholder="Buscar"
                          />
                          <i className="bx bx-search-alt search-icon" />
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl="12">
                      <Fragment>
                        <BootstrapTable
                          keyField={"idUsuario"}
                          bordered={false}
                          striped={true}
                          defaultSorted={defaultSorted}
                          classes={"table align-middle table-nowrap"}
                          headerWrapperClasses={"thead-light"}
                          {...toolkitProps.baseProps}
                          {...paginationTableProps}
                        />
                        {props.users.length <= 0 && (
                          <Row>
                            <Col md={12} className="text-center">
                              -- No hay usuarios disponibles --
                            </Col>
                          </Row>
                        )}
                      </Fragment>
                    </Col>
                  </Row>

                  <Row className="align-items-md-center mt-30">
                    <Col className="inner-custom-pagination d-flex">
                      <div className="d-inline">
                        <SizePerPageDropdownStandalone {...paginationProps} />
                      </div>
                      <div className="text-md-right ms-auto">
                        <PaginationListStandalone {...paginationProps} />
                      </div>
                    </Col>
                  </Row>
                </React.Fragment>
              )}
            </ToolkitProvider>
          )}
        </PaginationProvider>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-light"
          onClick={() => {
            props.onCancel()
          }}
        >
          Salir
        </button>
      </div>
    </Modal>
  )
}

AddUserToGroupModal.propTypes = {
  show: PropTypes.bool,
  loading: PropTypes.bool,
  users: PropTypes.array,
  error: PropTypes.string,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
}

export default AddUserToGroupModal
