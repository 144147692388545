import React, { useEffect, useState } from "react"

import { Row, Col, Card, CardBody } from "reactstrap"
import { Link } from "react-router-dom"
import { PropTypes } from "prop-types"

import avatar1 from "../../assets/images/user_without_pic.png"
import { useSelector, useDispatch } from "react-redux"
import { getGroups, me } from "../../store/actions"
import GuageN8 from "components/Charts/GuageN8"

const WelcomeN2to7 = props => {
  const dispatch = useDispatch()

  const [groupName, setGroupName] = useState("")
  const [showGuage, setShowGuage] = useState(false)

  const { whoIAm, groups } = useSelector(state => ({
    whoIAm: state.Profile.user,
    groups: state.GroupsList.groups,
  }))

  const user =
    whoIAm === undefined
      ? {
          idUsuario: 0,
          nombre: "",
          apellido: "",
          idRol: 0,
          idGroup: 0,
        }
      : whoIAm

  const avg = props.completedAvg

  useEffect(() => {
    dispatch(me())
    dispatch(getGroups())
  }, [])

  useEffect(() => {
    if (!isNaN(avg)) {
      setShowGuage(true)
    }
    return () => {
      setShowGuage(false)
    }
  }, [avg])

  useEffect(() => {
    const listGroups = groups ?? []
    const myGroup = listGroups.find(group => group.idGrupo === user.idGroup)
    if (myGroup !== undefined) {
      setGroupName(myGroup.nombreGrupo)
    }
  }, [groups])

  return (
    <React.Fragment>
      <Card className="overflow-hidden">
        <div className="alt-celf-background-2">
          <Row>
            <Col xs="12">
              <div className="p-4 d-flex justify-content-between">
                <h5 className="main-celf-color text-primary">
                  ¡Bienvenid@ de regreso!
                </h5>
                <div className="">
                  {user.nombre} / N{user.idRol}
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <CardBody className="pt-0">
          <Row>
            <Col sm="4">
              <div className="avatar-md profile-user-wid mb-2">
                <img
                  src={avatar1}
                  alt=""
                  className="img-thumbnail rounded-circle"
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={6} className="mb-2">
              <p>
                Grupo:
                <br />
                {groupName}
              </p>
              <a href="#" className="btn btn-secondary">
                Ver perfil
              </a>
            </Col>
            <Col xs={6} className="mb-2">
              {showGuage && <GuageN8 value={avg} />}
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

WelcomeN2to7.propTypes = {
  completedAvg: PropTypes.number,
}

export default WelcomeN2to7
