/* USERS */
export const GET_USER = "GET_USER"
export const GET_USER_SUCCESS = "GET_USER_SUCCESS"
export const EDIT_USER = "EDIT_USER"
export const EDIT_USER_SUCCESS = "EDIT_USER_SUCCESS"
export const EDIT_USER_CLEAN = "EDIT_USER_CLEAN"
export const API_ERROR = "API_ERROR"
export const ACTIVATE_DEACTIVATE_USER = "ACTIVATE_DEACTIVATE_USER"
export const ACTIVATE_DEACTIVATE_USER_SUCCESS =
  "ACTIVATE_DEACTIVATE_USER_SUCCESS"
export const ACTIVATE_DEACTIVATE_USER_ERROR = "ACTIVATE_DEACTIVATE_USER_ERROR"
export const ACTIVATE_DEACTIVATE_USER_CLEAN = "ACTIVATE_DEACTIVATE_USER_CLEAN"
