import { useEffect, useState } from "react"
import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React from "react"

import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"

import { withRouter, Link } from "react-router-dom"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

// actions
import { loginUser, loginClean } from "../../store/actions"
import SessionFooter from "components/Common/SessionFooter"
import SessionLogo from "components/Common/SessionLogo"

const Login = props => {
  const dispatch = useDispatch()

  const [formError, setFormError] = useState(false)

  const { error, loading } = useSelector(state => ({
    error: state.Login.error,
    loading: state.Login.loading,
  }))

  useEffect(() => {
    document.body.className = "main-celf-background"
    localStorage.removeItem("authUser")
    if (error) dispatch(loginClean())
    return () => {
      document.body.className = ""
    }
  }, [])

  // handleValidSubmit
  const handleSubmit = (event, errors, values) => {
    if (errors.length > 0) {
      setFormError(true)
      return
    }
    setFormError(false)
    dispatch(loginUser(values, props.history))
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>Iniciar sesión | CELF</title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-2">
        <Container>
          <Row className="justify-content-center mt-4">
            <Col md={8} lg={6} xl={5}>
              <Row>
                <Col xs={{ size: 8, offset: 2 }}>
                  <div className="fester-logo-login"></div>
                </Col>
              </Row>
              <Card className="overflow-hidden">
                <SessionLogo />
                <CardBody className="pt-0">
                  <div className="p-2">
                    <AvForm className="form-horizontal" onSubmit={handleSubmit}>
                      {error && (
                        <Alert color="danger">{error.toString()}</Alert>
                      )}
                      {formError && (
                        <Alert color="danger">
                          Por favor verifica los errores que se indican en el
                          formulario.
                        </Alert>
                      )}

                      <div className="mb-3">
                        <AvField
                          name="email"
                          label="Correo electrónico"
                          value=""
                          className="form-control"
                          placeholder="Correo electrónico"
                          type="email"
                          validate={{
                            required: {
                              value: true,
                              errorMessage: "Este campo es requerido",
                            },
                            email: {
                              value: true,
                              errorMessage:
                                "Este campo debe ser un correo electrónico",
                            },
                          }}
                        />
                      </div>

                      <div className="mb-3">
                        <AvField
                          name="password"
                          label="Contraseña"
                          value=""
                          type="password"
                          className="form-control"
                          placeholder="Contraseña"
                          validate={{
                            required: {
                              value: true,
                              errorMessage: "Este campo es requerido",
                            },
                          }}
                        />
                      </div>

                      <div className="mt-3 d-grid">
                        <button
                          className={`btn btn-secondary btn-block ${
                            loading ? "disabled" : ""
                          }`}
                          type="submit"
                        >
                          {!loading && <span>Iniciar sesión</span>}
                          {loading && <span>Validando...</span>}
                        </button>
                      </div>

                      <div className="mt-4 text-center">
                        <Link to="/forgot-password" className="text-muted">
                          <i className="mdi mdi-lock me-1" />
                          ¿Olvidaste tu contraseña?
                        </Link>
                      </div>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <SessionFooter />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
}
