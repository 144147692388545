/* eslint-disable no-undef */
import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import { ADD_USER } from "./actionTypes"
import { apiError, addUserSuccess } from "./actions"

//Include Both Helper File with needed methods
import { addUser } from "../../../helpers/backend_helper"

function* add(action) {
  try {
    const response = yield call(addUser, action.payload.form)
    console.log(response)
    if (response == undefined) throw new Error("API error")
    if (!response.success) throw new Error("Error al guardar al usuario")
    yield put(addUserSuccess(response))
  } catch (error) {
    console.log(error.message)
    yield put(apiError(error.message))
  }
}

function* userAddSaga() {
  yield takeEvery(ADD_USER, add)
}

export default userAddSaga
