import {
  REMOVE_BLOCK,
  REMOVE_BLOCK_SUCCESS,
  REMOVE_BLOCK_CLEAN,
  REMOVE_BLOCK_ERROR,
} from "./actionTypes"

export const removeBlock = id => ({
  type: REMOVE_BLOCK,
  payload: { id },
})

export const removeBlockSuccess = lesson => {
  return {
    type: REMOVE_BLOCK_SUCCESS,
    payload: { lesson },
  }
}

export const removeBlockClean = () => {
  return {
    type: REMOVE_BLOCK_CLEAN,
    payload: {},
  }
}

export const apiError = error => {
  return {
    type: REMOVE_BLOCK_ERROR,
    payload: error,
  }
}
