import { useEffect, useState } from "react"
import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React from "react"

import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Label,
  Button,
} from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"

import { withRouter, Link } from "react-router-dom"

// availity-reactstrap-validation
import {
  AvForm,
  AvField,
  AvFeedback,
  AvGroup,
} from "availity-reactstrap-validation"

// actions
import {
  userForgetPassword,
  userForgetPasswordClean,
} from "../../store/actions"
import SessionFooter from "components/Common/SessionFooter"
import SessionLogo from "components/Common/SessionLogo"

const ForgetPassword = props => {
  const dispatch = useDispatch()

  const { loading, success, error } = useSelector(state => ({
    loading: state.ForgetPassword.forgetLoading,
    success: state.ForgetPassword.forgetSuccessMsg,
    error: state.ForgetPassword.forgetError,
  }))

  const [formError, setFormError] = useState(false)
  const [hideButton, setHideButton] = useState(false)

  const backToLogin = () => {
    dispatch(userForgetPasswordClean())
    props.history.push("/login")
  }

  useEffect(() => {
    document.body.className = "main-celf-background"
    localStorage.removeItem("authUser")
    if (success) {
      setHideButton(true)
    }
    return () => {
      document.body.className = ""
    }
  }, [success])

  // handleValidSubmit
  const handleSubmit = (event, errors, values) => {
    if (errors.length > 0) {
      setFormError(true)
      return
    }
    setFormError(false)
    dispatch(userForgetPassword(values.email))
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>¿Olvidaste tu contraseña? | CELF</title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-2">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Row>
                <Col xs={{ size: 8, offset: 2 }}>
                  <div className="fester-logo-login"></div>
                </Col>
              </Row>
              <Card className="overflow-hidden">
                <SessionLogo />
                <CardBody className="pt-0">
                  <div className="p-2">
                    {formError && (
                      <div className="alert alert-danger">
                        Por favor verifica los errores que se indican en el
                        formulario.
                      </div>
                    )}
                    {success && (
                      <div className="alert alert-success">
                        Hemos enviado a tu bandeja de correo las instrucciones
                        para recuperar tu contraseña.
                      </div>
                    )}
                    <AvForm className="form-horizontal" onSubmit={handleSubmit}>
                      {error ? (
                        <Alert color="danger">{error.toString()}</Alert>
                      ) : null}

                      <div className="mb-3">
                        <AvGroup>
                          <AvField
                            name="email"
                            value=""
                            label="Correo electrónico"
                            className="form-control"
                            placeholder="Correo electrónico"
                            type="email"
                            validate={{
                              required: {
                                value: true,
                                errorMessage: "Este campo es requerido",
                              },
                              email: {
                                value: true,
                                errorMessage:
                                  "Este campo debe ser un correo electrónico válido",
                              },
                            }}
                          />
                        </AvGroup>
                      </div>

                      {!hideButton && (
                        <div className="mt-3 d-grid">
                          <button
                            className={`btn btn-secondary btn-block ${
                              loading ? "disabled" : ""
                            }`}
                            type="submit"
                          >
                            {loading ? "Validando..." : "Recuperar contraseña"}
                          </button>
                        </div>
                      )}
                    </AvForm>
                    <div className="mt-4 text-center">
                      <button
                        onClick={backToLogin}
                        className={`text-muted btn btn-link`}
                      >
                        <i className="mdi mdi-arrow-left me-1" />
                        Regresar al login
                      </button>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <SessionFooter />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(ForgetPassword)

ForgetPassword.propTypes = {
  history: PropTypes.object,
}
