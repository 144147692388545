import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { useDispatch, useSelector } from "react-redux"

const CoursePollForm = props => {
  return <h3>Course poll form</h3>
}

CoursePollForm.propTypes = {
  course: PropTypes.object,
}

export default CoursePollForm
