/* eslint-disable no-undef */
import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { FINISH_LESSON } from "./actionTypes"
import { finishLessonError, finishLessonSuccess } from "./actions"

//Include Both Helper File with needed methods
import { finishLesson } from "../../../helpers/backend_helper"

function* finish(action) {
  try {
    const response = yield call(finishLesson, action.payload.form)
    if (response == undefined) {
      throw new Error("API error")
    }
    if (!response.success) {
      throw new Error("Error al guardar la lección")
    }
    yield put(finishLessonSuccess(response))
  } catch (error) {
    yield put(finishLessonError(error.message))
  }
}

function* lessonFinishSaga() {
  yield takeEvery(FINISH_LESSON, finish)
}

export default lessonFinishSaga
