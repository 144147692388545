import {
  GET_COURSES,
  GET_COURSES_SUCCESS,
  API_ERROR,
  GET_COURSES_BY_USER,
  GET_COURSES_BY_USER_SUCCESS,
} from "./actionTypes"

export const getCourses = () => ({
  type: GET_COURSES,
  payload: {},
})

export const getCoursesSuccess = courses => {
  return {
    type: GET_COURSES_SUCCESS,
    payload: { courses },
  }
}

export const getCoursesByUser = idUser => ({
  type: GET_COURSES_BY_USER,
  payload: { idUser },
})

export const getCoursesByUserSuccess = courses => {
  return {
    type: GET_COURSES_BY_USER_SUCCESS,
    payload: { courses },
  }
}

export const apiError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}
