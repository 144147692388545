/* eslint-disable no-undef */
import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { REMOVE_LESSON } from "./actionTypes"
import { apiError, removeLessonSuccess } from "./actions"

//Include Both Helper File with needed methods
import { deleteLesson } from "../../../helpers/backend_helper"

function* remove(action) {
  try {
    const response = yield call(deleteLesson, action.payload.id)
    if (response === undefined) throw new Error("Error al remover lección")
    if (!response.success)
      throw new Error(`Error al remover lección: ${response.error}`)
    yield put(removeLessonSuccess(response))
  } catch (error) {
    console.log(error)
    yield put(apiError(`${error}`))
  }
}

function* lessonRemoveSaga() {
  yield takeEvery(REMOVE_LESSON, remove)
}

export default lessonRemoveSaga
