/* eslint-disable no-undef */
import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { EDIT_SUPPORT_MATERIAL_TYPE } from "./actionTypes"
import { apiError, editSupportMaterialTypeSuccess } from "./actions"

//Include Both Helper File with needed methods
import { updateSupportMaterialType } from "../../../helpers/backend_helper"

function* edit(action) {
  try {
    const response = yield call(updateSupportMaterialType, action.payload.form)
    console.log(response)
    if (response === undefined)
      yield put(apiError("Error al editar tipo de material de apoyo"))
    if (!response.success)
      yield put(
        apiError(`Error al editar tipo de material de apoyo: ${response.error}`)
      )
    else yield put(editSupportMaterialTypeSuccess(response))
  } catch (error) {
    yield put(apiError(error))
  }
}

function* supportMaterialTypeEditSaga() {
  yield takeEvery(EDIT_SUPPORT_MATERIAL_TYPE, edit)
}

export default supportMaterialTypeEditSaga
