import React, { useEffect } from "react"

import { Row, Col, Card, CardBody } from "reactstrap"
import { Link } from "react-router-dom"

import avatar1 from "../../assets/images/user_without_pic.png"
import { useSelector, useDispatch } from "react-redux"
import { me } from "../../store/actions"

const WelcomeComp = () => {
  const dispatch = useDispatch()

  const { whoIAm } = useSelector(state => ({
    whoIAm: state.Profile.user,
  }))

  const user =
    whoIAm === undefined
      ? {
          id: 1,
          nombre: "",
          apellido: "",
          idRol: 0,
        }
      : whoIAm

  useEffect(() => {
    dispatch(me())
  }, [])
  return (
    <React.Fragment>
      <Card className="overflow-hidden">
        <div className="alt-celf-background-2">
          <Row>
            <Col xs="7">
              <div className="text-primary p-4">
                <h5 className="main-celf-color">
                  ¡Bienvenid@ de regreso, {user.nombre}!
                </h5>
              </div>
            </Col>
          </Row>
        </div>
        <CardBody className="pt-0">
          <Row>
            <Col sm="4">
              <div className="avatar-md profile-user-wid mb-4">
                <img
                  src={avatar1}
                  alt=""
                  className="img-thumbnail rounded-circle"
                />
              </div>
              <h5 className="font-size-15 text-truncate">
                {user.nombre} {user.apellido}
              </h5>
              <p className="text-muted mb-0 text-truncate">
                Perfil N{user.idRol}
              </p>
            </Col>

            <Col sm="8">
              <div className="pt-4">
                <Row>
                  <Col xs="6">
                    <h5 className="font-size-15">Habilidades</h5>
                    <p className="text-muted mb-0">Ver más...</p>
                  </Col>
                  <Col xs="6">
                    <h5 className="font-size-15">Conocimientos</h5>
                    <p className="text-muted mb-0">Ver más...</p>
                  </Col>
                </Row>
                <div className="mt-4">
                  <Link
                    to="/profile"
                    className="btn btn-secondary btn-sm float-end"
                  >
                    Cuenta <i className="mdi mdi-arrow-right ms-1"></i>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}
export default WelcomeComp
