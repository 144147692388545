import {
  LIST_SUPPORT_MATERIALS,
  LIST_SUPPORT_MATERIALS_SUCCESS,
  LIST_SUPPORT_MATERIALS_ERROR,
  LIST_SUPPORT_MATERIALS_CLEAN,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  done: false,
  supportMaterials: [],
}

const SupportMaterialsList = (state = initialState, action) => {
  switch (action.type) {
    case LIST_SUPPORT_MATERIALS:
      state = {
        ...state,
        loading: true,
      }
      break
    case LIST_SUPPORT_MATERIALS_SUCCESS:
      state = {
        ...state,
        supportMaterials: action.payload.supportMaterials,
        loading: false,
        done: true,
      }
      break
    case LIST_SUPPORT_MATERIALS_CLEAN:
      state = initialState
      break
    case LIST_SUPPORT_MATERIALS_ERROR:
      state = { ...state, error: action.payload, loading: false, done: true }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default SupportMaterialsList
