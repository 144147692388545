import {
  ADD_USER,
  ADD_USER_SUCCESS,
  ADD_USER_CLEAN,
  API_ERROR,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  done: false,
  user: {},
}

const UserAdd = (state = initialState, action) => {
  switch (action.type) {
    case ADD_USER:
      state = {
        ...state,
        loading: true,
      }
      break
    case ADD_USER_SUCCESS:
      state = {
        ...state,
        user: action.payload.user,
        loading: false,
        done: true,
      }
      break
    case ADD_USER_CLEAN:
      state = initialState
      break
    case API_ERROR:
      state = { ...state, error: action.payload, loading: false, done: true }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default UserAdd
