/* eslint-disable no-undef */
import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { ADD_ANSWERS } from "./actionTypes"
import { apiError, addAnsswersSuccess } from "./actions"

//Include Both Helper File with needed methods
import { addAnswersMulti } from "../../../helpers/backend_helper"

function* add(request) {
  try {
    const response = yield call(addAnswersMulti, request.payload.form)
    if (response == undefined) throw new Error("API error")
    yield put(addAnsswersSuccess(response))
  } catch (error) {
    yield put(apiError(error.message))
  }
}

function* answersAddSaga() {
  yield takeEvery(ADD_ANSWERS, add)
}

export default answersAddSaga
