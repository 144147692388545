import React, { useEffect, useState, Fragment } from "react"
import classnames from "classnames"
import { useSelector, useDispatch } from "react-redux"
import StackedColumnChart from "../../pages/Dashboard/StackedColumnChart"
import { getChartsData as onGetChartsData } from "../../store/actions"
import {
  Container,
  Row,
  Col,
  Card,
  CardTitle,
  CardText,
  CardImg,
  CardBody,
  Input,
  Nav,
  NavItem,
  NavLink,
  TabPane,
  TabContent,
  Progress,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  CardFooter,
  Button,
  Carousel,
  CarouselIndicators,
  CarouselItem,
  CarouselCaption,
  CarouselControl,
} from "reactstrap"
import { Link, withRouter } from "react-router-dom"

import WelcomeN8 from "../../pages/Dashboard/WelcomeN8"

import {
  getCourseKnowledges,
  getSkills,
  getCoursesByUser,
} from "../../store/actions"
import WelcomeN2to7 from "pages/Dashboard/WelcomeN2to7"
import CoursesByStatus from "./CoursesByStatus"

const N2to7 = props => {
  const [activeTabHab, setActiveTabHab] = useState("1")

  const dispatch = useDispatch()

  const { knowledges, skills, user, courses } = useSelector(store => ({
    knowledges: store.CourseKnowledgesList.courseKnowledges,
    skills: store.SkillsList.skills,
    user: store.Profile.user,
    courses: store.CoursesList.courses,
  }))

  const idUser = user?.idUsuario ?? 0

  useEffect(() => {
    dispatch(getCourseKnowledges())
    dispatch(getSkills())
    dispatch(getCoursesByUser(idUser))
  }, [idUser])

  const [periodData, setPeriodData] = useState([])
  const [periodType, setPeriodType] = useState("yearly")

  const onChangeChartPeriod = pType => {
    setPeriodType(pType)
    dispatch(onGetChartsData(pType))
  }

  const toggleIconCustomHab = tab => {
    if (activeTabHab !== tab) {
      setActiveTabHab(tab)
    }
  }

  const getColor = index => {
    if (index % 4 == 0) return "success"
    if (index % 4 == 1) return "warning"
    if (index % 4 == 2) return "dark"
    if (index % 4 == 3) return "info"
  }

  const getCompleteCourses = () => {
    const completed =
      courses !== undefined
        ? courses.filter(
            course =>
              course.estatusCurso === 2 &&
              course.CourseParticipant?.advancePercentage >= 100
          )
        : []

    return completed.length
  }

  return (
    <Fragment>
      ,
      <Row className="mb-2">
        <Col md={12}>
          <h4 className="mb-4">Tus avances en enero 2022</h4>
          <Row>
            <Col md={3}>
              <Card>
                <CardBody>
                  <div className="d-flex justify-content-between">
                    <h3>Cursos completados</h3>
                    {/* <div>
                      <div>
                        <span
                          className="badge bg-pill bg-success"
                          style={{ fontSize: "16px", borderRadius: "14px" }}
                        >
                          <i className="fa fa-arrow-up"></i> 19%
                        </span>
                      </div>
                    </div> */}
                  </div>
                  <div className="d-flex justify-content-between">
                    <div className="mt-3">
                      <span style={{ fontSize: "32px", fontWeight: "bold" }}>
                        {getCompleteCourses()}
                      </span>{" "}
                      <span style={{ fontSize: "18px" }}>cursos</span>
                    </div>
                    {/* <small
                      className="text-right"
                      style={{ textAlign: "right" }}
                    >
                      Por arriba del promedio
                      <br />
                      de participantes
                    </small> */}
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md={3}>
              <Card>
                <CardBody>
                  <div className="d-flex justify-content-between">
                    <h3>Insignias ganadas</h3>
                    <div>
                      <div>
                        <span
                          className="badge bg-pill bg-success"
                          style={{ fontSize: "16px", borderRadius: "14px" }}
                        >
                          0
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between mb-2">
                    <div className="mt-4">
                      <span
                        className="badge bg-pill bg-warning"
                        style={{
                          fontSize: "14px",
                          borderRadius: "14px",
                          padding: "6px",
                        }}
                      >
                        <i className="fa fa-trophy"></i> No se han ganado
                        insignias
                      </span>
                    </div>
                    {/* <small
                      className="text-right"
                      style={{ textAlign: "right" }}
                    >
                      Más que el mes anterior
                    </small> */}
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md={3}>
              <Card>
                <CardBody>
                  <div className="d-flex justify-content-between">
                    <h3>Tiempo dedicado</h3>
                    {/* <div>
                      <div>
                        <span
                          className="badge bg-pill bg-success"
                          style={{ fontSize: "16px", borderRadius: "14px" }}
                        >
                          <i className="fa fa-arrow-up"></i> 2.1%
                        </span>
                      </div>
                    </div> */}
                  </div>
                  <div className="d-flex justify-content-between">
                    <div className="mt-3">
                      <span style={{ fontSize: "32px", fontWeight: "bold" }}>
                        0 hr. 0 min.
                      </span>
                    </div>
                    {/* <small
                      className="text-right"
                      style={{ textAlign: "right" }}
                    >
                      Mayor que el
                      <br />
                      mes anterior
                    </small> */}
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md={3}>
              <Card>
                <CardBody>
                  <div className="d-flex justify-content-between">
                    <h3>Promedios en capacitación</h3>
                  </div>
                  <div className="d-flex justify-content-between">
                    <span
                      className="badge bg-pill bg-warning"
                      style={{ fontSize: "14px", borderRadius: "14px" }}
                    >
                      <i className="fa fa-arrow-up"></i> 0 min/curso
                    </span>
                    <span className="pt-1">Sin iniciar</span>
                  </div>
                  <div className="d-flex justify-content-between mt-3">
                    <span
                      className="badge bg-pill bg-warning"
                      style={{ fontSize: "14px", borderRadius: "14px" }}
                    >
                      <i className="fa fa-minus"></i> 0 veces cursado/curso
                    </span>
                    <span className="pt-1">Sin iniciar</span>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
      {/* <Row>
        <Col>
          <Card>
            <CardBody>
              <div className="d-sm-flex flex-wrap">
                <h4 className="card-title mb-4">
                  Estatus de cursos por usuario
                </h4>
              </div>
              <Row className="mt-2 mb-2">
                <Col md={2}>
                  Tipo de usuario:
                  <Input type="select">
                    <option value="">Todos los tipos</option>
                    <option value="Interno">Interno</option>
                    <option value="Externo">Externo</option>
                  </Input>
                </Col>
                <Col md={2}>
                  Perfil:
                  <Input type="select">
                    <option value="">Todos los perfiles</option>
                    <option value="N1">N1</option>
                    <option value="N2">N2</option>
                    <option value="N3">N3</option>
                    <option value="N4">N4</option>
                    <option value="N5">N5</option>
                    <option value="N6">N6</option>
                    <option value="N7">N7</option>
                    <option value="N8">N8</option>
                  </Input>
                </Col>
                <Col md={2}>
                  Intervalo de avance:
                  <Input type="select">
                    <option value="">Todos los intervalos</option>
                    <option value="0%">0%</option>
                    <option value="De 1% hasta 25%">De 1% hasta 25%</option>
                    <option value="De 25% a 50%">De 25% a 50%</option>
                    <option value="De 51% a 75%">De 51% a 75%</option>
                    <option value="De 75% a 100%">De 75% a 100%</option>
                  </Input>
                </Col>
                <Col md={2}>
                  Grupo:
                  <Input type="select">
                    <option value="">Todos los grupos</option>
                  </Input>
                </Col>
                <Col md={2}>
                  Usuario:
                  <Input type="select">
                    <option value="">Todos los usuarios</option>
                  </Input>
                </Col>
                <Col md={2}>
                  Curso:
                  <Input type="select">
                    <option value="">Todos los cursos</option>
                  </Input>
                </Col>
              </Row>
              <div className="clearfix"></div>
              <StackedColumnChart periodData={periodData} />
            </CardBody>
          </Card>
        </Col>
      </Row> */}
      <Row>
        <Col md={4}>
          <WelcomeN2to7 completedAvg={getCompleteCourses()} />
          {/* <Card>
            <CardBody>
              <Nav className="icon-tab nav-justified">
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: activeTabHab === "1",
                    })}
                    onClick={() => {
                      toggleIconCustomHab("1")
                    }}
                  >
                    <span className="d-sm-block">Habilidades adquiridas</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: activeTabHab === "2",
                    })}
                    onClick={() => {
                      toggleIconCustomHab("2")
                    }}
                  >
                    <span className="d-sm-block">Conocimientos adquiridos</span>
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={activeTabHab} className="text-muted mt-4">
                <TabPane tabId="1">
                  <div className="">
                    {skills.map((skill, index) =>
                      index % 3 == 0 ? (
                        <span
                          key={`skill-${skill.idAbility}`}
                          className={`badge bg-pill bg-${getColor(
                            index
                          )} pt-2 m-1`}
                          style={{ fontSize: "16px", borderRadius: "14px" }}
                        >
                          {skill.ability}
                        </span>
                      ) : (
                        <span></span>
                      )
                    )}
                  </div>
                </TabPane>
                <TabPane tabId="2">
                  <div className="">
                    {knowledges.map((knowledge, index) =>
                      index % 3 == 0 ? (
                        <span
                          key={`knowledge-${knowledge.idKnowledge}`}
                          className={`badge bg-pill bg-${getColor(
                            index
                          )} pt-2 m-1`}
                          style={{ fontSize: "16px", borderRadius: "14px" }}
                        >
                          {knowledge.knowledge}
                        </span>
                      ) : (
                        <span></span>
                      )
                    )}
                  </div>
                </TabPane>
              </TabContent>
            </CardBody>
          </Card> */}
        </Col>
        <Col md={8}>
          <CoursesByStatus courses={courses} />
        </Col>
      </Row>
    </Fragment>
  )
}

export default N2to7
