import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"
import RestorePassword from "pages/Authentication/RestorePassword"

// Dashboard
import Dashboard from "../pages/Dashboard/index"

// Users
import UsersList from "../pages/Users/UsersList"
import AddUser from "../pages/Users/AddUser"
import EditUser from "../pages/Users/EditUser"

// Courses
import CoursesStart from "pages/Courses/CoursesStart"
import CourseDetails from "pages/Courses/CourseDetails"
import CourseInfo from "pages/Courses/CourseInfo"
import CourseIntro from "pages/Courses/CourseIntro"

// Groups
import GroupsList from "pages/Groups/GroupList"
import AddGroup from "pages/Groups/AddGroup"
import GroupDetails from "pages/Groups/GroupDetails"
import ShowAnalytics from "pages/Analytics/ShowAnalytics"

// Terms and Privacy
import AcceptPrivacyTyC from "pages/Accept-privacy-tyc"

// Catalog
import Catalog from "pages/Catalog"

// Course knowledges
import CourseKnowledgesList from "pages/CourseKnowledges/CourseKnowledgesList"

// Knowledge categories
import KnowledgeCategoriesList from "pages/KnowledgeCategories/KnowledgeCategoryList"

// Businesses
import BusinessesList from "pages/Businesses/BusinessesList"

// Levels

import LevelsList from "pages/Levels/LevelsList"
// Skills
import SkillsList from "pages/Skills/SkillsList"

// Shopping areas
import ShoppingAreasList from "pages/ShoppingAreas/ShoppingAreasList"
import PositionsList from "pages/Positions/PositionsList"
import SupportMaterialTypes from "pages/SupportMaterialTypes/SupportMaterialTypesList"

const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },

  // Privacy, Terms and Conditions
  { path: "/accept-privacy-tyc", component: AcceptPrivacyTyC },

  // Users
  { path: "/users", component: UsersList },
  { path: "/users/add", component: AddUser },
  { path: "/users/edit/:id", component: EditUser },

  // Courses
  { path: "/courses", component: CoursesStart },
  { path: "/courses/add", component: CourseDetails },
  { path: "/courses/detail/:id", component: CourseDetails },
  { path: "/courses/info/:id", component: CourseInfo },
  { path: "/courses/intro/:id", component: CourseIntro },

  // Groups
  { path: "/groups", component: GroupsList },
  { path: "/groups/add", component: AddGroup },
  { path: "/groups/detail/:id", component: GroupDetails },

  // profile
  { path: "/profile", component: UserProfile },

  // Analytics
  { path: "/analytics", component: ShowAnalytics },

  // Catalog
  { path: "/catalog", component: Catalog },
  { path: "/catalog/businesses", component: BusinessesList },
  { path: "/catalog/shopping-areas", component: ShoppingAreasList },
  { path: "/catalog/positions", component: PositionsList },
  { path: "/catalog/levels", component: LevelsList },
  { path: "/catalog/skills", component: SkillsList },
  { path: "/catalog/knowledges", component: CourseKnowledgesList },
  { path: "/catalog/knowledge-categories", component: KnowledgeCategoriesList },
  { path: "/catalog/support-material-types", component: SupportMaterialTypes },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/restore-password/:token", component: RestorePassword },
  { path: "/register", component: Register },
]

export { publicRoutes, authProtectedRoutes }
