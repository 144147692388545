/* eslint-disable no-undef */
import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { REMOVE_POLL_ANSWER } from "./actionTypes"
import { removePollAnswerError, removePollAnswerSuccess } from "./actions"

//Include Both Helper File with needed methods
import { deletePollAnswer } from "../../../helpers/backend_helper"

function* remove(request) {
  try {
    const response = yield call(deletePollAnswer, request.payload.form)
    if (response == undefined) throw new Error("API error")
    yield put(removePollAnswerSuccess(response))
  } catch (error) {
    console.log(error.message)
    yield put(removePollAnswerError(error.message))
  }
}

function* pollAnswerRemoveSaga() {
  yield takeEvery(REMOVE_POLL_ANSWER, remove)
}

export default pollAnswerRemoveSaga
