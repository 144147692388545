import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import { BrowserRouter } from "react-router-dom"
import { FirebaseAppProvider } from "reactfire"
import * as Sentry from "@sentry/react"
import { Integrations } from "@sentry/tracing"

import "./i18n"
import { Provider } from "react-redux"

import store from "./store"
import reportWebVitals from "./reportWebVitals"
import firebaseConfig from "./firebaseConfig"

Sentry.init({
  dsn: "https://df874d08971348b2b0e6d5cb95daf678@o1032553.ingest.sentry.io/5999510",
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
})

const app = (
  <FirebaseAppProvider firebaseConfig={firebaseConfig}>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </FirebaseAppProvider>
)

ReactDOM.render(app, document.getElementById("root"))
serviceWorker.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
