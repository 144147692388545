import {
  EDIT_ANSWER,
  EDIT_ANSWER_SUCCESS,
  EDIT_ANSWER_CLEAN,
  EDIT_ANSWER_ERROR,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  done: false,
  answer: undefined,
}

const AnswerEdit = (state = initialState, action) => {
  switch (action.type) {
    case EDIT_ANSWER:
      state = {
        ...state,
        loading: true,
      }
      break
    case EDIT_ANSWER_SUCCESS:
      state = {
        ...state,
        answer: action.payload.answer,
        loading: false,
        done: true,
      }
      break
    case EDIT_ANSWER_CLEAN:
      state = initialState
      break
    case EDIT_ANSWER_ERROR:
      state = { ...state, error: action.payload, loading: false, done: true }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default AnswerEdit
