import {
  REMOVE_NOTE,
  REMOVE_NOTE_CLEAN,
  REMOVE_NOTE_SUCCESS,
  REMOVE_NOTE_ERROR,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  done: false,
  note: undefined,
}

const NoteRemove = (state = initialState, action) => {
  switch (action.type) {
    case REMOVE_NOTE:
      state = {
        ...state,
        loading: true,
      }
      break
    case REMOVE_NOTE_SUCCESS:
      state = {
        ...state,
        loading: false,
        done: true,
        note: action.payload.note,
      }
      break
    case REMOVE_NOTE_CLEAN:
      state = initialState
      break
    case REMOVE_NOTE_ERROR:
      state = {
        ...state,
        error: action.payload,
        loading: false,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default NoteRemove
