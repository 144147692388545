import React, { useState, Fragment, useEffect } from "react"
import PropTypes from "prop-types"
import {
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Progress,
  Button,
} from "reactstrap"
import { Link } from "react-router-dom"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import moment from "common/moment_i18n"

import {
  listGroupCourses,
  addGroupCourse,
  addGroupCourseClean,
  removeGroupCourse,
  removeGroupCourseClean,
  getCourses,
} from "../../store/actions"
import "../../assets/scss/datatables.scss"
import { useDispatch, useSelector } from "react-redux"
import AddCourseToGroupModal from "./AddCourseToGroupModal"
import DeleteCourseFromGroupModal from "./DeleteCourseFromGroupModal"

const CoursesGroupListTable = props => {
  const dispatch = useDispatch()
  const { SearchBar } = Search

  const [dropDown, setDropDown] = useState(0)
  const [showAddCourse, setShowAddCourse] = useState(false)
  const [showDeleteCourse, setShowDeleteCourse] = useState(undefined)

  const idGroup = props.group.idGrupo ?? 0

  useEffect(() => {
    dispatch(listGroupCourses(idGroup))
    dispatch(getCourses())
  }, [idGroup])

  const {
    groupCourses,
    addCourseLoading,
    addCourseError,
    addCourseDone,
    removeCourseLoading,
    removeCourseError,
    removeCourseDone,
    coursesList,
  } = useSelector(store => ({
    groupCourses: store.GroupCoursesList.groupCourses,
    addCourseLoading: store.GroupCourseAdd.loading,
    addCourseError: store.GroupCourseAdd.error,
    addCourseDone: store.GroupCourseAdd.done,
    removeCourseLoading: store.GroupCourseRemove.loading,
    removeCourseError: store.GroupCourseRemove.error,
    removeCourseDone: store.GroupCourseRemove.done,
    coursesList: store.CoursesList.courses,
  }))

  let courses = []
  let availableCourses = []

  if (coursesList !== undefined && groupCourses !== undefined) {
    coursesList.forEach(course => {
      const exists = groupCourses.find(
        groupCourse => groupCourse.idCourse === course.idCurso
      )
      if (exists !== undefined) {
        course.groupUserPercentage = exists.groupUserPercentage
        courses.push(course)
      } else {
        availableCourses.push(course)
      }
    })
  }

  const dropDownActions = (cell, row, rowIndex, extraData) => {
    const active = extraData?.active ?? 0
    return (
      <Dropdown
        key={`dropdown-${row.idCurso}`}
        isOpen={row.idCurso === active}
        toggle={() => {
          setDropDown(row.idCurso === active ? 0 : row.idCurso)
        }}
      >
        <DropdownToggle color="primary" className="btn btn-primary btn-md">
          Acciones <i className="mdi mdi-chevron-down" />
        </DropdownToggle>
        <DropdownMenu className="white-celf-background">
          <DropdownItem>
            <Link to={`courses/detail/${row.idCurso}`}>Ver curso</Link>
          </DropdownItem>
          <DropdownItem>Editar curso</DropdownItem>
          <DropdownItem>Resetear curso</DropdownItem>
          <DropdownItem onClick={() => setShowDeleteCourse(row)}>
            Eliminar del grupo
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    )
  }

  const columns = [
    {
      dataField: "idCurso",
      text: "Id",
      sort: false,
      hidden: true,
    },
    {
      dataField: "claveCurso",
      text: "ID curso Henkel",
      sort: true,
    },
    {
      dataField: "nombreCurso",
      text: "Nombre del curso",
      sort: true,
    },
    {
      dataField: "categoria",
      text: "Categoría(s) de conocimiento",
      sort: false,
      // eslint-disable-next-line react/display-name
      formatter: (column, row) => {
        let knowledgeCategories = []
        row.knowledgeCategories.forEach(object => {
          knowledgeCategories.push(object.knowledgeCategory)
        })
        let string = knowledgeCategories.join(", ")
        return (
          <div key={`knowledge-categories-${row.idCurso}`}>
            {string === ""
              ? "-- Sin categorías de conocimiento asignadas --"
              : string}
          </div>
        )
      },
    },
    {
      dataField: "lastActivity",
      text: "Último ingreso",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (column, row) => {
        return (
          <span key={`time-${row.idCurso}`}>
            {moment(row.lastActivity)
              .locale("es")
              .format("D [de] MMM [de] YYYY")}
          </span>
        )
      },
    },
    {
      dataField: "groupUserPercentage",
      text: "Avance grupal",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (column, row) => {
        return (
          <div key={`progress-course-${row.idCurso}`}>
            <div className="mb-1 text-center">{`${Math.round(
              row.groupUserPercentage
            )}%`}</div>
            <Progress multi>
              <Progress
                bar
                value={row.groupUserPercentage}
                color="secondary"
                style={{ width: `${row.groupUserPercentage}%` }}
                animated
              ></Progress>
              <Progress
                bar
                value={100 - row.groupUserPercentage}
                color="light"
                style={{ width: `${100 - row.groupUserPercentage}%` }}
              ></Progress>
            </Progress>
          </div>
        )
      },
    },
    {
      dataField: "actions",
      text: "Acciones",
      sort: false,
      formatter: dropDownActions,
      formatExtraData: {
        active: dropDown,
      },
    },
  ]

  const pageOptions = {
    sizePerPage: 10,
    totalSize: courses?.length,
    custom: true,
    showTotal: true,
  }

  const defaultSorted = [{ dataField: "idCurso", order: "asc" }]

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
    clickToSelect: false,
  }

  const handleAddCourse = courseId => {
    const request = {
      idGroup: props.group.idGrupo,
      idCourse: courseId,
    }

    dispatch(addGroupCourse(request))
  }

  const handleDeleteCourse = courseId => {
    const request = {
      idGroup: props.group.idGrupo,
      idCourse: courseId,
    }

    dispatch(removeGroupCourse(request))
  }

  if (addCourseDone) {
    dispatch(listGroupCourses(props.group.idGrupo))
    dispatch(addGroupCourseClean())
  }

  if (removeCourseDone) {
    setShowDeleteCourse(undefined)
    dispatch(listGroupCourses(props.group.idGrupo))
    dispatch(removeGroupCourseClean())
  }

  return (
    <Fragment>
      <PaginationProvider
        pagination={paginationFactory(pageOptions)}
        keyField="idUsuario"
        columns={columns}
        data={courses}
      >
        {({ paginationProps, paginationTableProps }) => (
          <ToolkitProvider
            keyField="idUsuario"
            columns={columns}
            data={courses}
            search
          >
            {toolkitProps => (
              <React.Fragment>
                <Row>
                  <Col className="text-center">
                    <button className="btn btn-link">
                      <i className="fa fa-file-excel"></i> Exportar a Excel
                    </button>
                    {"   "}
                    <Link to="/courses" className="btn btn-link">
                      <i className="fa fa-users"></i> Ver todos los cursos
                    </Link>
                    {"   "}
                    <button className="btn btn-link">
                      <i className="fa fa-sync-alt"></i> Resetear cursos
                    </button>
                  </Col>
                </Row>
                <Row className="mb-2 pt-3">
                  <Col xl={2} lg={3} md={4} sm={4} xs={6}>
                    <div className="d-grid">
                      <button
                        onClick={() => setShowAddCourse(true)}
                        className="btn btn-primary btn-rounded"
                      >
                        <i className="fa fa-plus"></i> Agregar curso
                      </button>
                    </div>
                  </Col>
                  <Col
                    xl={{ size: 2, offset: 8 }}
                    lg={{ size: 3, offset: 6 }}
                    md={{ size: 4, offset: 4 }}
                    sm={{ size: 4, offset: 4 }}
                    xs={6}
                  >
                    <div className="search-box me-2 mb-2 d-inline-block">
                      <div className="position-relative">
                        <SearchBar
                          {...toolkitProps.searchProps}
                          placeholder="Buscar"
                        />
                        <i className="bx bx-search-alt search-icon" />
                      </div>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col xl="12">
                    <Fragment>
                      <BootstrapTable
                        keyField={"idUsuario"}
                        bordered={false}
                        striped={true}
                        defaultSorted={defaultSorted}
                        classes={"table align-middle table-nowrap"}
                        headerWrapperClasses={"thead-light"}
                        {...toolkitProps.baseProps}
                        {...paginationTableProps}
                      />
                      {courses.length <= 0 && (
                        <Row>
                          <Col md={12} className="text-center">
                            -- No hay registros --
                          </Col>
                        </Row>
                      )}
                    </Fragment>
                  </Col>
                </Row>

                <Row className="align-items-md-center mt-30">
                  <Col className="inner-custom-pagination d-flex">
                    <div className="d-inline">
                      <SizePerPageDropdownStandalone {...paginationProps} />
                    </div>
                    <div className="text-md-right ms-auto">
                      <PaginationListStandalone {...paginationProps} />
                    </div>
                  </Col>
                </Row>
              </React.Fragment>
            )}
          </ToolkitProvider>
        )}
      </PaginationProvider>

      <AddCourseToGroupModal
        show={showAddCourse}
        loading={addCourseLoading}
        courses={availableCourses}
        onConfirm={courseId => handleAddCourse(courseId)}
        onCancel={() => setShowAddCourse(false)}
        error={addCourseError}
      />
      <DeleteCourseFromGroupModal
        show={showDeleteCourse !== undefined}
        course={showDeleteCourse}
        loading={removeCourseLoading}
        onConfirm={courseId => handleDeleteCourse(courseId)}
        onCancel={() => setShowDeleteCourse(undefined)}
        error={removeCourseError}
      />
    </Fragment>
  )
}

CoursesGroupListTable.propTypes = {
  group: PropTypes.object,
}

export default CoursesGroupListTable
