/* eslint-disable no-undef */
import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import { CHANGE_PASSWORD, GET_USERS, GET_USERS_BY_ROLE } from "./actionTypes"
import {
  apiError,
  getUsersSuccess,
  getUsersByRoleSuccess,
  changePasswordSuccess,
  changePasswordError,
} from "./actions"

import {
  getUsers,
  getUsersByRole,
  changeUserPassword,
} from "../../../helpers/backend_helper"

function* users() {
  try {
    const response = yield call(getUsers)
    yield put(getUsersSuccess(response))
  } catch (error) {
    yield put(apiError(error))
  }
}

function* usersByRole(action) {
  try {
    const response = yield call(getUsersByRole, action.payload.idRol)
    yield put(getUsersByRoleSuccess(response))
  } catch (error) {
    yield put(apiError(error))
  }
}

function* changePassword({ payload: { id, password } }) {
  try {
    const response = yield call(changeUserPassword, {
      idUser: id,
      password: password,
      manual: true,
    })
    if (response.success) {
      yield put(changePasswordSuccess(response.sucess))
    } else {
      yield put(changePasswordError(response.error))
    }
  } catch (error) {
    yield put(changePasswordError(error))
  }
}

function* usersListSaga() {
  yield takeEvery(GET_USERS, users)
  yield takeEvery(GET_USERS_BY_ROLE, usersByRole)
  yield takeEvery(CHANGE_PASSWORD, changePassword)
}

export default usersListSaga
