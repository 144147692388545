import React, { useState, Fragment, useEffect } from "react"
import PropTypes from "prop-types"
import {
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Progress,
  Button,
} from "reactstrap"
import { Link, withRouter } from "react-router-dom"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit"
import moment from "moment"

import { getCourses } from "../../store/actions"
import "../../assets/scss/datatables.scss"
import { useDispatch, useSelector } from "react-redux"

const CoursesListTable = props => {
  const dispatch = useDispatch()

  const { courses, loading, error } = useSelector(state => ({
    courses: state.CoursesList.courses,
    loading: state.CoursesList.loading,
    error: state.CoursesList.error,
  }))

  useEffect(() => {
    dispatch(getCourses())
  }, [])

  const { SearchBar } = Search

  const [dropDown, setDropDown] = useState(0)
  const [page, setPage] = useState(undefined)
  const [sizePerPage, setSizePerPage] = useState(undefined)
  const [curso, setCurso] = useState({
    idCurso: 0,
  })

  const dropDownActions = (cell, row, rowIndex, extraData) => {
    const active = extraData?.active ?? 0
    return (
      <Dropdown
        key={`dropdown-${row.idCurso}`}
        isOpen={row.idCurso === active}
        toggle={() => {
          setDropDown(row.idCurso === active ? 0 : row.idCurso)
        }}
      >
        <DropdownToggle color="primary" className="btn btn-primary btn-md">
          Acciones <i className="mdi mdi-chevron-down" />
        </DropdownToggle>
        <DropdownMenu className="white-celf-background">
          <DropdownItem
            onClick={() => props.history.push(`courses/detail/${row.idCurso}`)}
          >
            Editar
          </DropdownItem>
          <DropdownItem>Agregar a grupo</DropdownItem>
          <DropdownItem>Previsualizar</DropdownItem>
          <DropdownItem>{row.estatus ? "Desactivar" : "Activar"}</DropdownItem>
        </DropdownMenu>
      </Dropdown>
    )
  }

  const columns = [
    {
      dataField: "idCurso",
      text: "Id",
      sort: false,
      hidden: true,
    },
    {
      dataField: "claveCurso",
      text: "ID Curso Henkel",
      sort: true,
    },
    {
      dataField: "nombreCurso",
      text: "Nombre del curso",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (column, row) => (
        <Link to={`courses/detail/${row.idCurso}`}>{row.nombreCurso}</Link>
      ),
    },
    {
      dataField: "categoria",
      text: "Categoría(s) de conocimiento",
      sort: false,
      // eslint-disable-next-line react/display-name
      formatter: (column, row) => {
        let knowledgeCategories = []
        row.knowledgeCategories.forEach(object => {
          knowledgeCategories.push(object.knowledgeCategory)
        })
        let string = knowledgeCategories.join(", ")
        return (
          <div key={`knowledge-categories-${row.idUsuario}`}>
            {string === ""
              ? "-- Sin categorías de conocimiento asignadas --"
              : string}
          </div>
        )
      },
    },
    {
      dataField: "lastActivity",
      text: "Último ingreso",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (column, row) => {
        return (
          <span key={`time-${row.idUsuario}`}>
            {moment(row.lastActivity).format("D [de] MMM [de] YYYY")}
          </span>
        )
      },
    },
    {
      dataField: "estatusCurso",
      text: "Estatus",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (column, row) => {
        return (
          <h4 style={{ marginBottom: 0 }} key={`status-${row.idCurso}`}>
            {row.estatusCurso == 2 && (
              <span className="pt-2 badge bg-success">Publicado</span>
            )}
            {row.estatusCurso == 3 && (
              <span className="pt-2 badge bg-dark">Inactivo</span>
            )}
            {row.estatusCurso == 4 && (
              <span className="pt-2 badge bg-warning">Obsoleto</span>
            )}
            {row.estatusCurso == 1 && (
              <span className="pt-2 badge bg-light">Edición</span>
            )}
          </h4>
        )
      },
    },
    {
      dataField: "actions",
      text: "Acciones",
      sort: false,
      formatter: dropDownActions,
      formatExtraData: {
        active: dropDown,
      },
    },
  ]

  const pageOptions = {
    sizePerPage: sizePerPage === undefined ? 10 : sizePerPage,
    totalSize: courses?.length,
    custom: true,
    showTotal: true,
    page: page === undefined ? 1 : page,
    onPageChange: page => {
      setPage(page)
    },
    onSizePerPageChange: sizePerPage => {
      console.log(sizePerPage)
      setSizePerPage(sizePerPage)
    },
  }

  const { ExportCSVButton } = CSVExport

  const defaultSorted = [{ dataField: "idRol", order: "asc" }]

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
    clickToSelect: false,
  }

  return (
    <PaginationProvider
      pagination={paginationFactory(pageOptions)}
      keyField="idCurso"
      columns={columns}
      data={courses}
    >
      {({ paginationProps, paginationTableProps }) => (
        <ToolkitProvider
          keyField="idCurso"
          columns={columns}
          data={courses}
          search
        >
          {toolkitProps => (
            <React.Fragment>
              <Row className="mb-2 pt-3">
                <Col lg={2} md={3} sm={4} xs={6}>
                  <div className="d-grid">
                    <Link
                      to="/courses/add"
                      className="btn btn-primary btn-rounded"
                    >
                      <i className="fa fa-plus"></i> Agregar curso
                    </Link>
                    <button className="btn btn-link mt-2">
                      <i className="fa fa-file-excel"></i> Exportar a Excel
                    </button>
                  </div>
                </Col>
                <Col
                  lg={{ size: 2, offset: 8 }}
                  md={{ size: 3, offset: 6 }}
                  sm={{ size: 4, offset: 4 }}
                  xs={6}
                >
                  <div className="search-box me-2 mb-2 d-inline-block">
                    <div className="position-relative">
                      <SearchBar
                        {...toolkitProps.searchProps}
                        placeholder="Buscar"
                      />
                      <i className="bx bx-search-alt search-icon" />
                    </div>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col xl="12">
                  <div className="table-responsive">
                    <BootstrapTable
                      keyField={"idCurso"}
                      bordered={false}
                      striped={true}
                      defaultSorted={defaultSorted}
                      classes={"table align-middle table-nowrap"}
                      headerWrapperClasses={"thead-light"}
                      {...toolkitProps.baseProps}
                      {...paginationTableProps}
                    />
                    {courses.length <= 0 && (
                      <Row>
                        <Col md={12} className="text-center">
                          -- No hay registros --
                        </Col>
                      </Row>
                    )}
                  </div>
                </Col>
              </Row>

              <Row className="align-items-md-center mt-30">
                <Col className="inner-custom-pagination d-flex">
                  <div className="d-inline">
                    <SizePerPageDropdownStandalone {...paginationProps} />
                  </div>
                  <div className="text-md-right ms-auto">
                    <PaginationListStandalone {...paginationProps} />
                  </div>
                </Col>
              </Row>
            </React.Fragment>
          )}
        </ToolkitProvider>
      )}
    </PaginationProvider>
  )
}

CoursesListTable.propTypes = {
  group: PropTypes.object,
  history: PropTypes.object,
}

export default withRouter(CoursesListTable)
