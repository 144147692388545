import {
  ADD_USER,
  ADD_USER_SUCCESS,
  ADD_USER_CLEAN,
  API_ERROR,
} from "./actionTypes"

export const addUser = form => ({
  type: ADD_USER,
  payload: { form },
})

export const addUserSuccess = user => {
  return {
    type: ADD_USER_SUCCESS,
    payload: { user },
  }
}

export const addUserClean = user => {
  return {
    type: ADD_USER_CLEAN,
    payload: { user },
  }
}

export const apiError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}
