/* eslint-disable no-undef */
import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { ADD_POLL } from "./actionTypes"
import { addPollError, addPollSuccess } from "./actions"

//Include Both Helper File with needed methods
import { insertPoll } from "../../../helpers/backend_helper"

function* list(request) {
  try {
    const response = yield call(insertPoll, request.payload.form)
    if (response == undefined) throw new Error("API error")
    yield put(addPollSuccess(response))
  } catch (error) {
    console.log(error.message)
    yield put(addPollError(error.message))
  }
}

function* pollAddSaga() {
  yield takeEvery(ADD_POLL, list)
}

export default pollAddSaga
