import { GET_CUSTOMERS, GET_CUSTOMERS_SUCCESS, API_ERROR } from "./actionTypes"

export const getCustomers = () => ({
  type: GET_CUSTOMERS,
  payload: {},
})

export const getCustomersSuccess = customers => {
  return {
    type: GET_CUSTOMERS_SUCCESS,
    payload: { customers },
  }
}

export const apiError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}
