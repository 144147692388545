/* eslint-disable no-undef */
import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { GET_QUESTION } from "./actionTypes"
import { apiError, getQuestionSuccess } from "./actions"

//Include Both Helper File with needed methods
import { getQuestion } from "../../../helpers/backend_helper"

function* get(request) {
  try {
    const response = yield call(getQuestion, request.payload.idQuestion)
    if (response == undefined) throw new Error("API error")
    yield put(getQuestionSuccess(response))
  } catch (error) {
    console.log(error.message)
    yield put(apiError(error.message))
  }
}

function* questionGetSaga() {
  yield takeEvery(GET_QUESTION, get)
}

export default questionGetSaga
