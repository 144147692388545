import {
  FORGET_PASSWORD,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_ERROR,
  RESTORE_PASSWORD,
  RESTORE_PASSWORD_SUCCESS,
  RESTORE_PASSWORD_ERROR,
  FORGET_PASSWORD_CLEAN,
} from "./actionTypes"

export const userForgetPassword = email => {
  return {
    type: FORGET_PASSWORD,
    payload: { email },
  }
}

export const userForgetPasswordSuccess = message => {
  return {
    type: FORGET_PASSWORD_SUCCESS,
    payload: message,
  }
}

export const userForgetPasswordError = message => {
  return {
    type: FORGET_PASSWORD_ERROR,
    payload: message,
  }
}

export const userForgetPasswordClean = () => {
  return {
    type: FORGET_PASSWORD_CLEAN,
    payload: {},
  }
}

export const userRestorePassword = (key, password) => {
  return {
    type: RESTORE_PASSWORD,
    payload: { key, password },
  }
}

export const userRestorePasswordSuccess = message => {
  return {
    type: RESTORE_PASSWORD_SUCCESS,
    payload: message,
  }
}

export const userRestorePasswordError = message => {
  return {
    type: RESTORE_PASSWORD_ERROR,
    payload: message,
  }
}
