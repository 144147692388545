import { Fragment, useState, useEffect, useRef } from "react"
import { Col, Row, Media, Input, Modal, Card, CardBody } from "reactstrap"
import { PropTypes } from "prop-types"
import { useDispatch, useSelector } from "react-redux"
import SweetAlert from "react-bootstrap-sweetalert"
import {
  listNotes,
  removeNote,
  removeNoteClean,
  addNote,
  addNoteClean,
} from "store/actions"
import moment from "common/moment_i18n"
import AvForm from "availity-reactstrap-validation/lib/AvForm"
import AvField from "availity-reactstrap-validation/lib/AvField"

const Notes = props => {
  const dispatch = useDispatch()
  const idCourse = props.course.idCurso
  const idLesson = props.lesson?.idLesson ?? 0

  const [noteToDelete, setNoteToDelete] = useState(undefined)

  const ref = useRef(null)

  const { notes, notesLoading, notesDone, removeDone, addDone } = useSelector(
    store => ({
      notes: store.NotesList.notes,
      notesLoading: store.NotesList.loading,
      notesDone: store.NotesList.done,
      removeDone: store.NoteRemove.done,
      addDone: store.NoteAdd.done,
    })
  )

  useEffect(() => {
    dispatch(listNotes(idCourse))
  }, [idCourse])

  const handleDelete = () => {
    if (noteToDelete !== undefined) dispatch(removeNote(noteToDelete.idNote))
    setNoteToDelete(undefined)
  }

  const handleSubmit = (event, errors, values) => {
    if (errors.length > 0) {
      return
    }

    dispatch(
      addNote({
        idLesson: idLesson,
        idCourse: idCourse,
        note: values.note,
      })
    )

    ref.current.reset()
  }

  if (removeDone) {
    dispatch(removeNoteClean())
    dispatch(listNotes(idCourse))
  }

  if (addDone) {
    dispatch(addNoteClean())
    dispatch(listNotes(idCourse))
  }

  return (
    <div className="border p-4">
      <h4>Agregar nota</h4>
      <AvForm ref={ref} className="form-horizontal" onSubmit={handleSubmit}>
        <AvField
          name="note"
          type="textarea"
          className="form-control"
          placeholder="Escribe una nota"
          validate={{
            required: {
              value: true,
              errorMessage: "Este campo es requerido",
            },
          }}
        />
        <div className="text-center pt-2">
          <button type="submit" className="btn btn-primary btn-block">
            Agregar
          </button>
        </div>
      </AvForm>
      {notes !== undefined &&
        notes
          .filter(note => note.idLesson === idLesson)
          .map(note => {
            return (
              <div
                className="alert alert-info mt-4"
                key={`lesson-card-${note.idNote}`}
              >
                <h4 className="alert-heading mt-1">Nota</h4>
                <button
                  type="button"
                  className="close ml-3"
                  onClick={() => setNoteToDelete(note)}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <div className="mb-1">{note.note}</div>
                <div
                  className="italic"
                  title={moment(note.noteDate)
                    .locale("es")
                    .format("D [de] MMMM [de] YYYY [a las] HH:mm:ss [hrs.]")}
                >
                  {moment(note.noteDate).locale("es").fromNow()}
                </div>
              </div>
            )
          })}
      {noteToDelete !== undefined && (
        <SweetAlert
          title="¿Borrar la nota seleccionada?"
          warning
          confirmBtnBsStyle="secondary"
          confirmBtnText="Sí, borrar"
          showCancel={true}
          cancelBtnBsStyle="light"
          cancelBtnText="No, cancelar"
          onConfirm={() => {
            handleDelete()
          }}
          onCancel={() => setNoteToDelete(undefined)}
        ></SweetAlert>
      )}
      <style jsx="true">{`
        .italic {
          font-style: italic;
          text-align: right;
          font-size: 12px;
        }
      `}</style>
    </div>
  )
}

Notes.propTypes = {
  course: PropTypes.object,
  lesson: PropTypes.object,
}

export default Notes
