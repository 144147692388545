/* eslint-disable no-undef */
import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import { GET_COURSE } from "./actionTypes"
import { apiError, getCourseSuccess } from "./actions"

//Include Both Helper File with needed methods
import { getCourse } from "../../../helpers/backend_helper"

function* get(params) {
  try {
    const response = yield call(getCourse, params.payload.id)
    yield put(getCourseSuccess(response))
  } catch (error) {
    yield put(apiError(error))
  }
}

function* courseGetSaga() {
  yield takeEvery(GET_COURSE, get)
}

export default courseGetSaga
