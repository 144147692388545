import {
  ADD_FAV,
  ADD_FAV_SUCCESS,
  ADD_FAV_CLEAN,
  ADD_FAV_ERROR,
} from "./actionTypes"

export const addFav = idCourse => ({
  type: ADD_FAV,
  payload: { idCourse },
})

export const addFavSuccess = fav => {
  return {
    type: ADD_FAV_SUCCESS,
    payload: { fav },
  }
}

export const addFavClean = () => {
  return {
    type: ADD_FAV_CLEAN,
    payload: {},
  }
}

export const apiError = error => {
  return {
    type: ADD_FAV_ERROR,
    payload: error,
  }
}
