import {
  GET_POSITIONS,
  GET_POSITIONS_SUCCESS,
  GET_POSITIONS_ERROR,
} from "./actionTypes"

export const getPositions = () => ({
  type: GET_POSITIONS,
  payload: {},
})

export const getPositionsSuccess = positions => {
  return {
    type: GET_POSITIONS_SUCCESS,
    payload: { positions },
  }
}

export const apiError = error => {
  return {
    type: GET_POSITIONS_ERROR,
    payload: error,
  }
}
