import {
  ADD_EXAM,
  ADD_EXAM_SUCCESS,
  ADD_EXAM_CLEAN,
  ADD_EXAM_ERROR,
} from "./actionTypes"

export const addExam = form => ({
  type: ADD_EXAM,
  payload: { form },
})

export const addExamSuccess = exam => {
  return {
    type: ADD_EXAM_SUCCESS,
    payload: { exam },
  }
}

export const addExamClean = () => {
  return {
    type: ADD_EXAM_CLEAN,
    payload: {},
  }
}

export const apiError = error => {
  return {
    type: ADD_EXAM_ERROR,
    payload: error,
  }
}
