import {
  GET_BLOCKS,
  GET_BLOCKS_SUCCESS,
  GET_BLOCKS_ERROR,
  GET_BLOCKS_CLEAN,
} from "./actionTypes"

export const getBlocks = idLesson => ({
  type: GET_BLOCKS,
  payload: { idLesson },
})

export const getBlocksSuccess = blocks => {
  return {
    type: GET_BLOCKS_SUCCESS,
    payload: { blocks },
  }
}

export const getBlocksClean = () => {
  return {
    type: GET_BLOCKS_CLEAN,
    payload: {},
  }
}

export const apiError = error => {
  return {
    type: GET_BLOCKS_ERROR,
    payload: error,
  }
}
