import { Row, Col } from "reactstrap"

const SessionLogo = () => {
  return (
    <div className="celf-content-login">
      <Row>
        <Col
          xs={{ size: 10, offset: 1 }}
          sm={{ size: 8, offset: 2 }}
          className="text-center mt-4 mb-2"
        >
          <h2 className="celf-subtitle-login">Capacitación en Línea</h2>
          <p className="celf-title-login">Fester</p>
        </Col>
      </Row>
    </div>
  )
}

export default SessionLogo
