import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import PropTypes from "prop-types"
import { Modal, Input, Row, Col, FormGroup, Label } from "reactstrap"
import {
  AvField,
  AvForm,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation"
import {
  getLessons,
  addQuestion,
  addQuestionClean,
  addAnsswers,
  editQuestion,
  editQuestionClean,
  editAnswer,
  removeQuestion,
} from "store/actions"
import AvGroup from "availity-reactstrap-validation/lib/AvGroup"
import ImageUploadModal from "components/Common/ImageUploadModal"

const TrueFalseQuestionModal = props => {
  const idQuestionType = 2
  const dispatch = useDispatch()
  const [formError, setFormError] = useState(false)
  const [showImageUploadModal, setShowImageUploadModal] = useState(false)
  const [image, setImage] = useState("")
  const [value, setValue] = useState(1)
  const [questionName, setQuestionName] = useState("")

  const idQuestion =
    props.question !== undefined ? props.question.idQuestion : 0

  const answers = props.question !== undefined ? props.question.answers : []

  const {
    lessons,
    questionAddLoading,
    questionAddDone,
    questionAddError,
    questionEditLoading,
    questionEditDone,
    questionEditError,
  } = useSelector(store => ({
    lessons: store.LessonsList.lessons,
    questionAddLoading: store.QuestionAdd.loading,
    questionAddDone: store.QuestionAdd.done,
    questionAddError: store.QuestionAdd.error,
    questionEditLoading: store.QuestionEdit.loading,
    questionEditDone: store.QuestionEdit.done,
    questionEditError: store.QuestionEdit.error,
  }))

  useEffect(() => {
    dispatch(getLessons(props.course.idCurso))
    setQuestionName(props.question !== undefined ? props.question.question : "")
    setImage(props.question !== undefined ? props.question.image : "")
    return () => {
      setFormError(false)
    }
  }, [props.question])

  useEffect(() => {
    if (answers.length > 0) {
      const correct = answers.find(answer => answer.correctAnswer === 1)
      setValue(correct.answer === "Verdadero" ? 1 : 2)
    } else {
      setValue(1)
    }
    return () => {}
  }, [answers])

  const handleSubmit = async (event, errors, values) => {
    if (errors.length > 0) {
      setFormError(true)
      return
    }
    setFormError(false)

    const request = {
      courseExam: props.exam.idCourseExam,
      idQuestionType: idQuestionType,
      idLessonRef: parseInt(values.idLessonRef),
      question: values.question,
      image: image,
    }

    if (props.question === undefined) {
      dispatch(addQuestion(request))
    } else {
      request.idExamQuestion = props.question.idQuestion
      dispatch(editQuestion(request))
      if (!(props.question.answers.length > 0)) {
        const requestAnswers = {
          idExamQuestion: props.question.idQuestion,
          List: [
            {
              answer: "Verdadero",
              image: "",
              correctAnswer: parseInt(value) === 1 ? 1 : 0,
            },
            {
              answer: "Falso",
              image: "",
              correctAnswer: parseInt(value) === 2 ? 1 : 0,
            },
          ],
        }
        dispatch(addAnsswers(requestAnswers))
      } else {
        const trueAnswer = answers.find(answer => answer.answer === "Verdadero")
        const falseAnswer = answers.find(answer => answer.answer === "Falso")
        dispatch(
          editAnswer({
            idQuestionAnswer: trueAnswer.idQuestionAnswer,
            idExamQuestion: trueAnswer.idQuestion,
            answer: trueAnswer.answer,
            image: "",
            correctAnswer: parseInt(value) === 1 ? 1 : 0,
          })
        )
        dispatch(
          editAnswer({
            idQuestionAnswer: falseAnswer.idQuestionAnswer,
            idExamQuestion: falseAnswer.idQuestion,
            answer: falseAnswer.answer,
            image: "",
            correctAnswer: parseInt(value) === 2 ? 1 : 0,
          })
        )
      }
    }
  }

  if (questionAddDone) {
    dispatch(addQuestionClean())
    props.onConfirm()
  }

  if (questionEditDone) {
    dispatch(editQuestionClean())
    props.onConfirm()
  }

  return (
    <Modal isOpen={props.show} backdrop={"static"} id="staticBackdrop">
      <AvForm className="form-horizontal" onSubmit={handleSubmit}>
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            Pregunta: Falso/Verdadero
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              if (questionName === "") {
                dispatch(removeQuestion(idQuestion))
              }
              props.onCancel()
            }}
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">
          {formError && (
            <div className="alert alert-danger">
              Existen errores en el formulario. Por favor corrígelos para
              continuar.
            </div>
          )}
          {questionAddError !== "" && (
            <div className="alert alert-danger">{questionAddError}</div>
          )}
          <Row className="mb-4">
            <Col md={12}>
              <AvField
                name="question"
                label="Ingresa la pregunta"
                className="form-control"
                placeholder="Ingresar"
                value={
                  props.question !== undefined ? props.question.question : ""
                }
                type="text"
                validate={{
                  required: {
                    value: true,
                    errorMessage: "Este campo es requerido",
                  },
                }}
              />
            </Col>
          </Row>
          <Row className="mb-4">
            <Col>
              <a
                href={image}
                target={"_blank"}
                className={`btn btn-light btn-block ${
                  image !== "" ? "" : "disabled"
                }`}
                rel="noreferrer"
              >
                <i className="fa fa-image"></i> Ver imagen cargada
              </a>
            </Col>
            <Col>
              {image !== "" ? (
                <button
                  onClick={() => setShowImageUploadModal(true)}
                  type="button"
                  className="btn btn-light btn-block"
                >
                  <i className="fa fa-undo"></i> Cambiar imagen
                </button>
              ) : (
                <button
                  onClick={() => setShowImageUploadModal(true)}
                  type="button"
                  className="btn btn-light btn-block"
                >
                  <i className="fa fa-plus-circle"></i> Agregar imagen
                </button>
              )}
            </Col>
          </Row>
          <Row className="mb-4">
            <Col md={12}>
              <AvRadioGroup
                name="answers"
                className="form-control"
                onChange={e => setValue(parseInt(e.target.value))}
                value={value}
                validate={{
                  required: {
                    value: true,
                    errorMessage: "Este campo es requerido",
                  },
                }}
              >
                <AvRadio value={1} label="Verdadero" />
                <AvRadio value={2} label="Falso" />
              </AvRadioGroup>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <AvField
                name="idLessonRef"
                label="Referencia a una lección"
                className="form-control"
                placeholder=""
                type="select"
                value={
                  props.question !== undefined
                    ? props.question.idLessonReference
                    : ""
                }
                validate={{}}
              >
                <option value="">Sin referencia</option>
                {lessons.map(lesson => (
                  <option key={lesson.idLesson} value={lesson.idLesson}>
                    {lesson.title}
                  </option>
                ))}
              </AvField>
            </Col>
          </Row>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-light"
            onClick={() => {
              if (questionName === "") {
                dispatch(removeQuestion(idQuestion))
              }
              props.onCancel()
            }}
          >
            Cancelar
          </button>
          <button
            type="submit"
            className={`btn btn-secondary ${
              questionAddLoading || questionEditLoading ? "disabled" : ""
            }`}
          >
            {questionAddLoading || questionEditLoading
              ? "Guardando..."
              : "Guardar"}
          </button>
        </div>
      </AvForm>
      {showImageUploadModal && (
        <ImageUploadModal
          key={"true-false-question-modal"}
          onConfirm={image => {
            setImage(image)
            setShowImageUploadModal(false)
          }}
          onCancel={() => setShowImageUploadModal(false)}
          show={true}
        />
      )}
    </Modal>
  )
}

TrueFalseQuestionModal.propTypes = {
  show: PropTypes.bool,
  question: PropTypes.object,
  exam: PropTypes.object,
  course: PropTypes.object,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
}

export default TrueFalseQuestionModal
