import {
  REMOVE_LESSON,
  REMOVE_LESSON_SUCCESS,
  REMOVE_LESSON_CLEAN,
  REMOVE_LESSON_ERROR,
} from "./actionTypes"

export const removeLesson = id => ({
  type: REMOVE_LESSON,
  payload: { id },
})

export const removeLessonSuccess = lesson => {
  return {
    type: REMOVE_LESSON_SUCCESS,
    payload: { lesson },
  }
}

export const removeLessonClean = () => {
  return {
    type: REMOVE_LESSON_CLEAN,
    payload: {},
  }
}

export const apiError = error => {
  return {
    type: REMOVE_LESSON_ERROR,
    payload: error,
  }
}
