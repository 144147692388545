import {
  EDIT_LESSON,
  EDIT_LESSON_CLEAN,
  EDIT_LESSON_SUCCESS,
  EDIT_LESSON_ERROR,
  EDIT_LESSON_POSITIONS,
  EDIT_LESSON_POSITIONS_CLEAN,
  EDIT_LESSON_POSITIONS_SUCCESS,
  EDIT_LESSON_POSITIONS_ERROR,
  LESSON_START,
  LESSON_START_CLEAN,
  LESSON_START_SUCCESS,
  LESSON_START_ERROR,
  LESSON_FINISH,
  LESSON_FINISH_CLEAN,
  LESSON_FINISH_SUCCESS,
  LESSON_FINISH_ERROR,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  done: false,
  loadingPositions: false,
  donePositions: false,
  loadingStart: false,
  doneStart: false,
  loadingFinish: false,
  doneFinish: false,
  lesson: undefined,
}

const LessonEdit = (state = initialState, action) => {
  switch (action.type) {
    case EDIT_LESSON:
      state = {
        ...state,
        loading: true,
      }
      break
    case EDIT_LESSON_SUCCESS:
      state = {
        ...state,
        loading: false,
        done: true,
        lesson: action.payload.lesson,
      }
      break
    case EDIT_LESSON_CLEAN:
      state = initialState
      break
    case EDIT_LESSON_ERROR:
      state = {
        ...state,
        error: action.payload,
        loading: false,
      }
      break
    case EDIT_LESSON_POSITIONS:
      state = {
        ...state,
        loadingPositions: true,
      }
      break
    case EDIT_LESSON_POSITIONS_SUCCESS:
      state = {
        ...state,
        loadingPositions: false,
        donePositions: true,
        lesson: action.payload.lesson,
      }
      break
    case EDIT_LESSON_POSITIONS_CLEAN:
      state = initialState
      break
    case EDIT_LESSON_POSITIONS_ERROR:
      state = {
        ...state,
        error: action.payload,
        loadingPositions: false,
      }
      break
    case LESSON_START:
      state = {
        ...state,
        loadingStart: true,
      }
      break
    case LESSON_START_SUCCESS:
      state = {
        ...state,
        loadingStart: false,
        doneStart: true,
        lesson: action.payload.lesson,
      }
      break
    case LESSON_START_CLEAN:
      state = initialState
      break
    case LESSON_START_ERROR:
      state = {
        ...state,
        error: action.payload,
        loadingStart: false,
      }
      break
    case LESSON_FINISH:
      state = {
        ...state,
        loadingFinish: true,
      }
      break
    case LESSON_FINISH_SUCCESS:
      state = {
        ...state,
        loadingFinish: false,
        doneFinish: true,
        lesson: action.payload.lesson,
      }
      break
    case LESSON_FINISH_CLEAN:
      state = initialState
      break
    case LESSON_FINISH_ERROR:
      state = {
        ...state,
        error: action.payload,
        loadingFinish: false,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default LessonEdit
