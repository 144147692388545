import React, { useEffect, useState, Fragment } from "react"
import classnames from "classnames"
import { useSelector, useDispatch } from "react-redux"
import { getChartsData as onGetChartsData } from "../../store/actions"
import {
  Container,
  Row,
  Col,
  Card,
  CardTitle,
  CardText,
  CardImg,
  CardBody,
  Input,
  Nav,
  NavItem,
  NavLink,
  TabPane,
  TabContent,
  Progress,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  CardFooter,
  Button,
  Carousel,
  CarouselIndicators,
  CarouselItem,
  CarouselCaption,
  CarouselControl,
} from "reactstrap"
import { Link, withRouter } from "react-router-dom"

import WelcomeN8 from "../../pages/Dashboard/WelcomeN8"
import CourseInfo from "components/Dashboard/CourseInfo"

import {
  getCourseKnowledges,
  getSkills,
  getCoursesByUser,
} from "../../store/actions"

const N8 = props => {
  const [activeTab, setActiveTab] = useState("1")
  const [activeTabHab, setActiveTabHab] = useState("1")

  const dispatch = useDispatch()

  const { knowledges, skills, user, courses } = useSelector(store => ({
    knowledges: store.CourseKnowledgesList.courseKnowledges,
    skills: store.SkillsList.skills,
    user: store.Profile.user,
    courses: store.CoursesList.courses,
  }))

  const idUser = user?.idUsuario ?? 0
  const myCourses = courses === undefined ? [] : courses
  const coursesInProgress = myCourses.filter(
    course =>
      course.CourseParticipant?.advancePercentage < 100 &&
      course.CourseParticipant?.advancePercentage > 0
  )
  const coursesPending = myCourses.filter(
    course => course.CourseParticipant?.advancePercentage <= 0
  )
  const coursesCompleted = myCourses.filter(
    course => course.CourseParticipant?.advancePercentage >= 100
  )
  const coursesFav = myCourses.filter(
    course => course.CourseParticipant?.favourite === true
  )

  useEffect(() => {
    dispatch(getCourseKnowledges())
    dispatch(getSkills())
    dispatch(getCoursesByUser(idUser))
  }, [idUser])

  const [periodData, setPeriodData] = useState([])
  const [periodType, setPeriodType] = useState("yearly")

  const onChangeChartPeriod = pType => {
    setPeriodType(pType)
    dispatch(onGetChartsData(pType))
  }

  const toggleIconCustom = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  const toggleIconCustomHab = tab => {
    if (activeTabHab !== tab) {
      setActiveTabHab(tab)
    }
  }

  const getColor = index => {
    if (index % 4 == 0) return "success"
    if (index % 4 == 1) return "warning"
    if (index % 4 == 2) return "dark"
    if (index % 4 == 3) return "info"
  }

  return (
    <Fragment>
      ,
      <Row className="mb-2">
        <Col md={12}>
          <h4 className="mb-4">Tus avances en octubre 2021</h4>
          <Row>
            <Col md={3}>
              <Card>
                <CardBody>
                  <div className="d-flex justify-content-between">
                    <h3>Cursos completados</h3>
                    <div>
                      <div>
                        <span
                          className="badge bg-pill bg-success"
                          style={{ fontSize: "16px", borderRadius: "14px" }}
                        >
                          <i className="fa fa-arrow-up"></i> 19%
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between">
                    <div className="mt-3">
                      <span style={{ fontSize: "32px", fontWeight: "bold" }}>
                        3
                      </span>{" "}
                      <span style={{ fontSize: "18px" }}>
                        cursos - Total 27/30
                      </span>
                    </div>
                    <small
                      className="text-right"
                      style={{ textAlign: "right" }}
                    >
                      Por arriba del promedio
                      <br />
                      de participantes
                    </small>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md={3}>
              <Card>
                <CardBody>
                  <div className="d-flex justify-content-between">
                    <h3>Insignias ganadas</h3>
                    <div>
                      <div>
                        <span
                          className="badge bg-pill bg-success"
                          style={{ fontSize: "16px", borderRadius: "14px" }}
                        >
                          <i className="fa fa-arrow-up"></i> 1
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between mb-2">
                    <div className="mt-4">
                      <span
                        className="badge alt-celf-background"
                        style={{
                          fontSize: "14px",
                          borderRadius: "14px",
                          padding: "6px",
                        }}
                      >
                        <i className="fa fa-trophy"></i> Impermeabilizador todo
                        terreno
                      </span>
                    </div>
                    <small
                      className="text-right"
                      style={{ textAlign: "right" }}
                    >
                      Más que el mes anterior
                    </small>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md={3}>
              <Card>
                <CardBody>
                  <div className="d-flex justify-content-between">
                    <h3>Tiempo dedicado</h3>
                    <div>
                      <div>
                        <span
                          className="badge bg-pill bg-success"
                          style={{ fontSize: "16px", borderRadius: "14px" }}
                        >
                          <i className="fa fa-arrow-up"></i> 2.1%
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between">
                    <div className="mt-3">
                      <span style={{ fontSize: "32px", fontWeight: "bold" }}>
                        1 hr. 03 min.
                      </span>
                    </div>
                    <small
                      className="text-right"
                      style={{ textAlign: "right" }}
                    >
                      Mayor que el
                      <br />
                      mes anterior
                    </small>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md={3}>
              <Card>
                <CardBody>
                  <div className="d-flex justify-content-between">
                    <h3>Promedios en capacitación</h3>
                  </div>
                  <div className="d-flex justify-content-between">
                    <span
                      className="badge bg-pill bg-success"
                      style={{ fontSize: "14px", borderRadius: "14px" }}
                    >
                      <i className="fa fa-arrow-up"></i> 25.04 min/curso
                    </span>
                    <span className="pt-1">Más que el promedio</span>
                  </div>
                  <div className="d-flex justify-content-between mt-3">
                    <span
                      className="badge bg-pill bg-warning"
                      style={{ fontSize: "14px", borderRadius: "14px" }}
                    >
                      <i className="fa fa-minus"></i> 1.25 veces cursado/curso
                    </span>
                    <span className="pt-1">En el promedio</span>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <WelcomeN8 />
          <Card>
            <CardBody>
              <Nav className="icon-tab nav-justified">
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: activeTabHab === "1",
                    })}
                    onClick={() => {
                      toggleIconCustomHab("1")
                    }}
                  >
                    <span className="d-sm-block">Habilidades adquiridas</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: activeTabHab === "2",
                    })}
                    onClick={() => {
                      toggleIconCustomHab("2")
                    }}
                  >
                    <span className="d-sm-block">Conocimientos adquiridos</span>
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={activeTabHab} className="text-muted mt-4">
                <TabPane tabId="1">
                  <div className="">
                    {skills.map((skill, index) =>
                      index % 3 == 0 ? (
                        <span
                          key={`skill-${skill.idAbility}`}
                          className={`badge bg-pill bg-${getColor(
                            index
                          )} pt-2 m-1`}
                          style={{ fontSize: "16px", borderRadius: "14px" }}
                        >
                          {skill.ability}
                        </span>
                      ) : (
                        <span></span>
                      )
                    )}
                  </div>
                </TabPane>
                <TabPane tabId="2">
                  <div className="">
                    {knowledges.map((knowledge, index) =>
                      index % 3 == 0 ? (
                        <span
                          key={`knowledge-${knowledge.idKnowledge}`}
                          className={`badge bg-pill bg-${getColor(
                            index
                          )} pt-2 m-1`}
                          style={{ fontSize: "16px", borderRadius: "14px" }}
                        >
                          {knowledge.knowledge}
                        </span>
                      ) : (
                        <span></span>
                      )
                    )}
                  </div>
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
        </Col>
        <Col md={8}>
          <Card>
            <CardBody>
              <Nav className="icon-tab nav-justified">
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: activeTab === "1",
                    })}
                    onClick={() => {
                      toggleIconCustom("1")
                    }}
                  >
                    <span className="d-none d-sm-block">
                      Cursos en progreso ({coursesInProgress.length})
                    </span>
                    <span className="d-block d-sm-none">
                      <i className="fas fa-cogs"></i>
                    </span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: activeTab === "2",
                    })}
                    onClick={() => {
                      toggleIconCustom("2")
                    }}
                  >
                    <span className="d-none d-sm-block">
                      Cursos pendientes ({coursesPending.length})
                    </span>
                    <span className="d-block d-sm-none">
                      <i className="fas fa-cogs"></i>
                    </span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: activeTab === "3",
                    })}
                    onClick={() => {
                      toggleIconCustom("3")
                    }}
                  >
                    <span className="d-none d-sm-block">
                      Cursos completados ({coursesCompleted.length})
                    </span>
                    <span className="d-block d-sm-none">
                      <i className="fas fa-cogs"></i>
                    </span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: activeTab === "4",
                    })}
                    onClick={() => {
                      toggleIconCustom("4")
                    }}
                  >
                    <span className="d-none d-sm-block">
                      Cursos favoritos ({coursesFav.length})
                    </span>
                    <span className="d-block d-sm-none">
                      <i className="fas fa-cogs"></i>
                    </span>
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={activeTab} className="p-3 text-muted mt-4">
                <TabPane tabId="1">
                  {coursesInProgress.map(course => (
                    <Row key={`course-progress-${course.idCurso}`}>
                      <Col lg={12}>
                        <CourseInfo
                          id={course.idCurso}
                          title={course.nombreCurso}
                          category={course.knowledgeCategories
                            .map(category => category.knowledgeCategory.trim())
                            .join(", ")}
                          percentage={
                            course.CourseParticipant.advancePercentage
                          }
                          minutes={course.tiempoDedicacion}
                          image={course.coverImage ?? ""}
                          started={
                            course.CourseParticipant.advancePercentage > 0
                          }
                          finished={
                            course.CourseParticipant.advancePercentage >= 100
                          }
                        />
                      </Col>
                    </Row>
                  ))}
                </TabPane>
                <TabPane tabId="2">
                  {coursesPending.map(course => (
                    <Row key={`course-pending-${course.idCurso}`}>
                      <Col lg={12}>
                        <CourseInfo
                          id={course.idCurso}
                          title={course.nombreCurso}
                          category={course.knowledgeCategories
                            .map(category => category.knowledgeCategory.trim())
                            .join(", ")}
                          percentage={
                            course.CourseParticipant.advancePercentage
                          }
                          minutes={course.tiempoDedicacion}
                          image={course.coverImage ?? ""}
                          started={
                            course.CourseParticipant.advancePercentage > 0
                          }
                          finished={
                            course.CourseParticipant.advancePercentage >= 100
                          }
                        />
                      </Col>
                    </Row>
                  ))}
                </TabPane>
                <TabPane tabId="3">
                  <Col lg={12}>
                    {coursesCompleted.map(course => (
                      <Row key={`course-pending-${course.idCurso}`}>
                        <Col lg={12}>
                          <CourseInfo
                            id={course.idCurso}
                            title={course.nombreCurso}
                            category={course.knowledgeCategories
                              .map(category =>
                                category.knowledgeCategory.trim()
                              )
                              .join(", ")}
                            percentage={
                              course.CourseParticipant.advancePercentage
                            }
                            minutes={course.tiempoDedicacion}
                            image={course.coverImage ?? ""}
                            started={
                              course.CourseParticipant.advancePercentage > 0
                            }
                            finished={
                              course.CourseParticipant.advancePercentage >= 100
                            }
                          />
                        </Col>
                      </Row>
                    ))}
                  </Col>
                </TabPane>
                <TabPane tabId="4">
                  {coursesFav.map(course => (
                    <Row key={`course-pending-${course.idCurso}`}>
                      <Col lg={12}>
                        <CourseInfo
                          id={course.idCurso}
                          title={course.nombreCurso}
                          category={course.knowledgeCategories
                            .map(category => category.knowledgeCategory.trim())
                            .join(", ")}
                          percentage={
                            course.CourseParticipant.advancePercentage
                          }
                          minutes={course.tiempoDedicacion}
                          image={course.coverImage ?? ""}
                          started={
                            course.CourseParticipant.advancePercentage > 0
                          }
                          finished={
                            course.CourseParticipant.advancePercentage >= 100
                          }
                        />
                      </Col>
                    </Row>
                  ))}
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Fragment>
  )
}

export default N8
