import {
  ADD_SUPPORT_MATERIAL,
  ADD_SUPPORT_MATERIAL_SUCCESS,
  ADD_SUPPORT_MATERIAL_CLEAN,
  ADD_SUPPORT_MATERIAL_ERROR,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  done: false,
  supportMaterial: {},
}

const SupportMaterialAdd = (state = initialState, action) => {
  switch (action.type) {
    case ADD_SUPPORT_MATERIAL:
      state = {
        ...state,
        loading: true,
        error: "",
      }
      break
    case ADD_SUPPORT_MATERIAL_SUCCESS:
      state = {
        ...state,
        supportMaterial: action.payload.supportMaterial,
        loading: false,
        done: true,
      }
      break
    case ADD_SUPPORT_MATERIAL_CLEAN:
      state = initialState
      break
    case ADD_SUPPORT_MATERIAL_ERROR:
      state = { ...state, error: action.payload, loading: false, done: false }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default SupportMaterialAdd
