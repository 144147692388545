import {
  GET_USERS,
  GET_USERS_SUCCESS,
  API_ERROR,
  GET_USERS_BY_ROLE,
  GET_USERS_BY_ROLE_SUCCESS,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_ERROR,
  CHANGE_PASSWORD_CLEAN,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  changePasswordLoading: false,
  changePasswordSuccess: false,
  changePasswordError: "",
  done: false,
  users: [],
  usersByRole: [],
}

const UsersList = (state = initialState, action) => {
  switch (action.type) {
    case GET_USERS:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_USERS_SUCCESS:
      state = {
        ...state,
        users: action.payload.users,
        loading: false,
        done: true,
      }
      break
    case GET_USERS_BY_ROLE:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_USERS_BY_ROLE_SUCCESS:
      state = {
        ...state,
        usersByRole: action.payload.users,
        loading: false,
        done: true,
      }
      break
    case CHANGE_PASSWORD:
      state = {
        ...state,
        changePasswordLoading: true,
      }
      break
    case CHANGE_PASSWORD_SUCCESS:
      state = {
        ...state,
        changePasswordError: "",
        changePasswordLoading: false,
        changePasswordSuccess: true,
      }
      break
    case API_ERROR:
      state = { ...state, error: action.payload, loading: false, done: true }
      break
    case CHANGE_PASSWORD_ERROR:
      state = {
        ...state,
        changePasswordError: action.payload,
        changePasswordLoading: false,
        changePasswordSuccess: false,
      }
      break
    case CHANGE_PASSWORD_CLEAN:
      state = {
        ...state,
        changePasswordLoading: false,
        changePasswordSuccess: false,
        changePasswordError: "",
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default UsersList
