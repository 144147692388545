import {
  ADD_FAV,
  ADD_FAV_SUCCESS,
  ADD_FAV_CLEAN,
  ADD_FAV_ERROR,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  done: false,
  fav: undefined,
}

const FavAdd = (state = initialState, action) => {
  switch (action.type) {
    case ADD_FAV:
      state = {
        ...state,
        loading: true,
      }
      break
    case ADD_FAV_SUCCESS:
      state = {
        ...state,
        fav: action.payload.fav,
        loading: false,
        done: true,
      }
      break
    case ADD_FAV_CLEAN:
      state = initialState
      break
    case ADD_FAV_ERROR:
      state = { ...state, error: action.payload, loading: false, done: false }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default FavAdd
