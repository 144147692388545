import PropTypes from "prop-types"
import { useState } from "react"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import DeleteQuestionModal from "./DeleteQuestionModal"

const CourseExamQuestions = props => {
  const [dropDown, setDropDown] = useState(0)
  const [questionToDelete, setQuestionToDelete] = useState(undefined)

  return (
    <table className="table">
      <thead>
        <tr>
          <th>No.</th>
          <th>Pregunta</th>
          <th>Tipo respuesta</th>
          <th>Referencia</th>
          <th colSpan={2}>Acciones</th>
        </tr>
      </thead>
      <tbody>
        {props.questions.length < 1 && (
          <tr>
            <td colSpan="6" className="text-center">
              {" "}
              -- No se han registrado preguntas --
            </td>
          </tr>
        )}
        {props.questions !== undefined &&
          props.questions
            .sort((a, b) => (a.idQuestion > b.idQuestion ? 1 : -1))
            .map((question, index) => {
              return (
                <tr key={`course-exam-question-${index}`}>
                  <td>{index + 1}</td>
                  <td>{question.question}</td>
                  <td>{question.questionType.QuestionType}</td>
                  <td>
                    {question.idLessonReference === null
                      ? "Sin referencia"
                      : props.lessons.find(
                          lesson =>
                            lesson.idLesson === question.idLessonReference
                        )?.title}
                  </td>
                  <td>
                    <Dropdown
                      key={`course-exam-dropdown-${question.idQuestion}`}
                      isOpen={question.idQuestion === dropDown}
                      toggle={() => {
                        setDropDown(
                          question.idQuestion === dropDown
                            ? 0
                            : question.idQuestion
                        )
                      }}
                    >
                      <DropdownToggle
                        color="primary"
                        className="btn btn-primary btn-md"
                      >
                        Acciones <i className="mdi mdi-chevron-down" />
                      </DropdownToggle>
                      <DropdownMenu className="white-celf-background">
                        <DropdownItem onClick={() => props.onEdit(question)}>
                          Editar
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => setQuestionToDelete(question)}
                        >
                          Eliminar
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </td>
                </tr>
              )
            })}
      </tbody>
      <DeleteQuestionModal
        show={questionToDelete !== undefined}
        onCancel={() => setQuestionToDelete(undefined)}
        onConfirm={() => {
          props.onDelete(questionToDelete)
          setQuestionToDelete(undefined)
        }}
        error={""}
      />
    </table>
  )
}

CourseExamQuestions.propTypes = {
  questions: PropTypes.array,
  lessons: PropTypes.array,
  exam: PropTypes.object,
  course: PropTypes.object,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
}

export default CourseExamQuestions
