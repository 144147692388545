import {
  LIST_SUPPORT_MATERIAL_TYPES,
  LIST_SUPPORT_MATERIAL_TYPES_SUCCESS,
  LIST_SUPPORT_MATERIAL_TYPES_ERROR,
  LIST_SUPPORT_MATERIAL_TYPES_CLEAN,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  done: false,
  supportMaterialTypes: [],
}

const SupportMaterialTypesList = (state = initialState, action) => {
  switch (action.type) {
    case LIST_SUPPORT_MATERIAL_TYPES:
      state = {
        ...state,
        loading: true,
      }
      break
    case LIST_SUPPORT_MATERIAL_TYPES_SUCCESS:
      state = {
        ...state,
        supportMaterialTypes: action.payload.supportMaterialTypes,
        loading: false,
        done: true,
      }
      break
    case LIST_SUPPORT_MATERIAL_TYPES_CLEAN:
      state = initialState
      break
    case LIST_SUPPORT_MATERIAL_TYPES_ERROR:
      state = { ...state, error: action.payload, loading: false, done: true }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default SupportMaterialTypesList
