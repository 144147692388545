/* eslint-disable no-undef */
import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import { ACTIVATE_DEACTIVATE_USER, EDIT_USER, GET_USER } from "./actionTypes"
import {
  apiError,
  editUserSuccess,
  getUserSuccess,
  activateDeactivateSuccess,
} from "./actions"

//Include Both Helper File with needed methods
import {
  updateUser,
  getUser,
  activateDeactivateUser,
} from "../../../helpers/backend_helper"

function* get(params) {
  try {
    const response = yield call(getUser, params.payload.id)
    yield put(getUserSuccess(response))
  } catch (error) {
    yield put(apiError(error))
  }
}

function* edit(action) {
  try {
    const response = yield call(updateUser, action.payload.form)
    if (response == undefined) throw new Error("API error")
    if (!response.success) throw new Error("Error al guardar al usuario")
    yield put(editUserSuccess(response))
  } catch (error) {
    yield put(apiError(error))
  }
}

function* activationDeactivation(action) {
  try {
    const response = yield call(activateDeactivateUser, {
      idUser: action.payload,
    })
    if (response == undefined) throw new Error("API error")
    if (!response.success)
      throw new Error("Error al guardar el estatus del usuario")
    yield put(activateDeactivateSuccess(response))
  } catch (error) {
    yield put(apiError(error))
  }
}

function* userEditSaga() {
  yield takeEvery(EDIT_USER, edit)
  yield takeEvery(GET_USER, get)
  yield takeEvery(ACTIVATE_DEACTIVATE_USER, activationDeactivation)
}

export default userEditSaga
