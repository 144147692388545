import {
  START_EXAM,
  START_EXAM_SUCCESS,
  START_EXAM_CLEAN,
  START_EXAM_ERROR,
  FINISH_EXAM,
  FINISH_EXAM_SUCCESS,
  FINISH_EXAM_CLEAN,
  FINISH_EXAM_ERROR,
  ANSWER_QUESTION_EXAM,
  ANSWER_QUESTION_EXAM_SUCCESS,
  ANSWER_QUESTION_EXAM_CLEAN,
  ANSWER_QUESTION_EXAM_ERROR,
} from "./actionTypes"

const initialState = {
  error: "",
  loadingStart: false,
  doneStart: false,
  start: undefined,
  loadingFinish: false,
  doneFinish: false,
  finish: undefined,
  loadingAnswerQuestion: false,
  doneAnswerQuestion: false,
  answerQuestion: undefined,
}

const ExamTry = (state = initialState, action) => {
  switch (action.type) {
    case START_EXAM:
      state = {
        ...state,
        loadingStart: true,
      }
      break
    case START_EXAM_SUCCESS:
      state = {
        ...state,
        start: action.payload.exam,
        loadingStart: false,
        doneStart: true,
      }
      break
    case START_EXAM_CLEAN:
      state = {
        ...state,
        error: "",
        loadingStart: false,
        doneStart: false,
        start: undefined,
      }
      break
    case START_EXAM_ERROR:
      state = {
        ...state,
        error: action.payload,
        loadingStart: false,
        doneStart: false,
      }
      break
    case FINISH_EXAM:
      state = {
        ...state,
        loadingFinish: true,
      }
      break
    case FINISH_EXAM_SUCCESS:
      state = {
        ...state,
        finish: action.payload.exam,
        loadingFinish: false,
        doneFinish: true,
      }
      break
    case FINISH_EXAM_CLEAN:
      state = {
        ...state,
        error: "",
        loadingFinish: false,
        doneFinish: false,
        finish: undefined,
      }
      break
    case FINISH_EXAM_ERROR:
      state = {
        ...state,
        error: action.payload,
        loadingFinish: false,
        doneFinish: false,
      }
      break
    case ANSWER_QUESTION_EXAM:
      state = {
        ...state,
        loadingAnswerQuestion: true,
      }
      break
    case ANSWER_QUESTION_EXAM_SUCCESS:
      state = {
        ...state,
        answerQuestion: action.payload.exam,
        loadingAnswerQuestion: false,
        doneAnswerQuestion: true,
      }
      break
    case ANSWER_QUESTION_EXAM_CLEAN:
      state = {
        ...state,
        error: "",
        loadingAnswerQuestion: false,
        doneAnswerQuestion: false,
        answerQuestion: undefined,
      }
      break
    case ANSWER_QUESTION_EXAM_ERROR:
      state = {
        ...state,
        error: action.payload,
        loadingAnswerQuestion: false,
        doneAnswerQuestion: false,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default ExamTry
